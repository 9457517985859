import React from "react";
import {
  Box,
  Typography,
  Button,
  Grow,
  Paper,
} from "@mui/material";
import Heading from "../../components/heading";
import TransactionCard from "../../components/transactionCard";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Skeleton from "@mui/material/Skeleton";

const TransactionSkeleton = () => (
  <Paper
    elevation={0}
    sx={{
      p: 2,
      mb: 2,
      bgcolor: (theme) => `${theme.palette.custom.mint}15`,
      borderRadius: 2,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
      <Box sx={{ flex: 1 }}>
        <Skeleton width="60%" height={24} sx={{ mb: 0.5 }} />
        <Skeleton width="40%" height={20} />
      </Box>
      <Skeleton width={80} height={24} />
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
      <Skeleton width={100} height={20} />
      <Skeleton width={80} height={20} />
    </Box>
  </Paper>
);

export const RecentTransactions = ({ transactions, navigate, isLoading }) => {
  return (
    <Box sx={{ mb: 4 }}>
      {isLoading ? (
        <>
          <Skeleton width={180} height={32} sx={{ mb: 2 }} />
          {[1, 2, 3].map((item) => (
            <TransactionSkeleton key={item} />
          ))}
        </>
      ) : (
        <>
          <Heading title="Recent transactions" sx={{ marginBottom: 2 }} />
          {transactions.length > 0 ? (
            <Box>
              {transactions.slice(0, 3).map((t, index) => (
                <Grow in={true} timeout={800 + index * 200} key={t.id}>
                  <Box>
                    <TransactionCard
                      id={t.id}
                      transMsg={t.trans_msg}
                      tranId={t.trans_id}
                      trans_id={t.trans_id}
                      receiveInBank={t.ReceiveInBank}
                      status={t.status}
                      exchangeRate={t.current_rate}
                      transactionalTime={t.transactional_time}
                      UtrNo={t.utr_no}
                      sourceAmount={t.base_sub_total}
                      receiverName={t.receiverName}
                      nickName={
                        t.receiverUnverifiedName
                          ? t.receiverUnverifiedName
                          : t.receiverName
                      }
                      onClick={() => {
                        navigate(`/transaction/details/${t.trans_id}`);
                      }}
                    />
                  </Box>
                </Grow>
              ))}
            </Box>
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.custom.purple,
                opacity: 0.7,
              }}
            >
              No recent transactions available.
            </Typography>
          )}
          {transactions.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                onClick={() => navigate("/transaction/history")}
                endIcon={<KeyboardDoubleArrowRightIcon />}
                sx={{ textTransform: "initial" }}
              >
                All Transactions
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
