import React from "react";
import { Button } from "@mui/material";

const NewDesignButton = ({ text = "Start Transfer", onClick = () => {}, sx = {}, fullWidth = false }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      fullWidth={fullWidth}
      sx={{
        py: 1,
        px: 2,
        borderRadius: "100px",
        backgroundColor: "brand.main",
        color: "brand.text",
        fontSize: { xs: "1rem", md: "1.25rem" },
        fontWeight: 600,
        textTransform: "none",
        border: "1.5px solid #000000",
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
        gap: 1,
        minHeight: "40px",
        "&:hover": {
          backgroundColor: "brand.dark",
        },
        ...sx
      }}
    >
      {text} <span style={{ marginLeft: "4px" }}>→</span>
    </Button>
  );
};

export default NewDesignButton;