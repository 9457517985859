import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TeamMemberCard from "../components/TeamMemberCard";
import { Container, Typography, Box, Grid, Link, useTheme } from "@mui/material";
import { clearMessage } from "../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import FullPageContainer from "../components/fullPageContainer";
import NewDesignButton from "../components/newDesignButton";
import madronaLogo from "../assets/madrona-logo.png";
import lightningVenturesLogo from "../assets/lightning-ventures-logo.png";
import axiomLogo from "../assets/axiom-logo.png";
import startupHavenLogo from "../assets/startup-haven-logo.png";
import croboGradientLogo from "../assets/crobo-gradient-logo.png";
import abhayAgarwal from "../assets/abhay-agarwal.png";
import ashishRaj from "../assets/ashish-raj.png";
import siddharthSinghal from "../assets/siddharth-singhal.png";
import ayushiAgrawal from "../assets/ayushi-agrawal.png";
import abhivardhanMisra from "../assets/abhivardhan-misra.png";
import samSheldin from "../assets/sam-sheldin.jpg";
import yatenChaudhary from "../assets/yaten-chaudhary.png";
import maniSundaram from "../assets/mani-sundaram.png";
import prateekKumar from "../assets/prateek-kumar.png";
import ujwalGupta from "../assets/ujwal-gupta.jpeg";

const teamMembers = [
  { 
    name: "Abhay Agarwal", 
    role: "Founder & CEO", 
    image: abhayAgarwal,
    linkedin: "https://www.linkedin.com/in/aabhay"
  },
  { 
    name: "Ashish Raj", 
    role: "Tech lead", 
    image: ashishRaj,
    linkedin: "https://www.linkedin.com/in/ashish-raj-1aa6201aa"
  },
  {
    name: "Ujwal Gupta",
    role: "Full-Stack Developer",
    image: ujwalGupta,
    linkedin: "https://www.linkedin.com/in/ujwal-gupta"
  },
  { 
    name: "Siddharth Singhal", 
    role: "Assoc. product manager", 
    image: siddharthSinghal,
    linkedin: "https://www.linkedin.com/in/siddharthsinghalpm"
  },
  { 
    name: "Ayushi Agrawal", 
    role: "Assoc. product manager", 
    image: ayushiAgrawal,
    linkedin: "https://www.linkedin.com/in/ayushi-agrawal-535b7821a"
  },
  { 
    name: "Abhivardhan Misra", 
    role: "Frontend Developer", 
    image: abhivardhanMisra,
    linkedin: "https://www.linkedin.com/in/abhivardhan-misra"
  },
  { 
    name: "Sam Sheldin", 
    role: "Flutter developer", 
    image: samSheldin,
    linkedin: "https://www.linkedin.com/in/sam-sheldin-07b508283"
  },
  { 
    name: "Yaten Chaudhary", 
    role: "QA engineer", 
    image: yatenChaudhary,
    linkedin: "https://www.linkedin.com/in/yaten-chaudhary-627907229"
  },
  
];

const investors = [
  {
    logo: madronaLogo,
    name: "Madrona",
    link: "https://www.madrona.com",
  },
  {
    logo: lightningVenturesLogo,
    name: "Lightning Ventures",
    link: "https://ltng.ventures",
  },
  { logo: axiomLogo, name: "Axiom", link: "https://axiomvc.com" },
  {
    logo: startupHavenLogo,
    name: "Startup Haven",
    link: "https://startuphaven.com",
  },
];

const advisors = [
  {
    name: "Mani Sundaram",
    role: "Director of AI - Amazon",
    image: maniSundaram,
    linkedin: "https://www.linkedin.com/in/masundaram",
  },
  {
    name: "Prateek Kumar",
    role: "0-1 Startup leader",
    image: prateekKumar,
    linkedin: "https://in.linkedin.com/in/pratik-kumar-071a4a9",
  },
];

const AboutUsV1 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customMessage, error } = useSelector((state) => state.userAuth);

  useEffect(() => {
    if (customMessage || error) {
      const timer = setTimeout(() => dispatch(clearMessage()), 30000);
      return () => clearTimeout(timer);
    }
  }, [customMessage, error, dispatch]);

  return (
    <FullPageContainer>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          mx: "auto",
          overflow: "hidden",
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            textAlign: "center",
            py: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 4 },
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 2, md: 4 },
            justifyContent: "space-between",
          }}
        >
          {/* Left content wrapper */}
          <Box
            sx={{
              flex: { md: "0 1 60%" },
              m: { xs: 1, sm: 2, md: 6 },
              maxWidth: "800px",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: { xs: "center", md: "left" },
                mb: { xs: 3, md: 6 },
                fontSize: { xs: "2rem", md: "2.75rem" },
                fontWeight: "700",
                color: "text.primary",
              }}
            >
              About us
            </Typography>

            <Typography
              sx={{
                color: "text.primary",
                fontSize: { xs: "1rem", sm: "1.1rem" },
                lineHeight: 1.8,
                mb: { xs: 3, md: 6 },
                textAlign: { xs: "center", md: "left" },
                maxWidth: "1000px",
              }}
            >
              Welcome to our platform, your go-to solution for the quickest and
              fee-free gifting and money transfer services from the USA to
              India. We're committed to providing you with a seamless and secure
              way to send gifts and money to your loved ones across borders,
              without any extra charges. Our customer-centric approach, combined
              with cutting-edge technology, ensures that your hard-earned money
              reaches its destination swiftly and without any hassle. Join us in
              making gifting & money transfers a breeze while saving you time
              and money. Your trust is our priority as we facilitate the fastest
              and fee-free money sending experience.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                mb: { xs: 3, md: 0 },
              }}
            >
              <NewDesignButton
                onClick={() => navigate("/pin")}
                text="Start transfer"
                to="/pin"
              />
            </Box>
          </Box>

          {/* Right SVG */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flex: { md: "0 1 auto" },
              mr: { md: 6 },
              mt: { xs: 0, md: 'inherit' },
            }}
          >
            <Box
              sx={{
                width: { xs: "140px", sm: "200px", md: "500px" },
                height: { xs: "140px", sm: "200px", md: "500px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 1, md: 4 },
              }}
            >
              <img
                src={croboGradientLogo}
                alt="Crobo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Team Section */}
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "background.paper",
            py: { xs: 4, sm: 6, md: 8 },
            px: { xs: 2, sm: 4 },
          }}
        >
          <Container maxWidth="xl">
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                mb: { xs: 3, sm: 4, md: 6 },
                fontSize: { xs: "2rem", md: "2.75rem" },
                fontWeight: 700,
                color: "text.primary",
              }}
            >
              Meet our team
            </Typography>

            <Grid
              container
              spacing={{ xs: 2, sm: 3, md: 4 }}
              px={{ md: 4 }}
              alignItems="stretch"
              justifyContent="center"
            >
              {teamMembers.map((member) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={member.name}
                  display="flex"
                  justifyContent="center"
                >
                  <TeamMemberCard 
                    name={member.name} 
                    role={member.role} 
                    imageUrl={member.image}
                    linkedin={member.linkedin} 
                    sx={{ width: '100%' }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Investors Section */}
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "background.dark",
            py: { xs: 4, sm: 6, md: 8 },
            px: { xs: 2, sm: 4 },
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              mb: { xs: 3, sm: 4, md: 6 },
              fontSize: { xs: "2rem", md: "2.75rem" },
              fontWeight: 700,
              color: "text.secondary",
            }}
          >
            We are backed by 🎉
          </Typography>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Grid
              container
              spacing={6}
              justifyContent="center"
              alignItems="center"
            >
              {investors.map(({ logo, name, link }) => (
                <Grid item xs={6} sm={3} key={logo}>
                  <Link href={link} target="_blank" rel="noopener noreferrer">
                    <Box
                      sx={{
                        height: { xs: 45, sm: 60 },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: 0.85,
                        transition: "all 0.4s ease",
                        "&:hover": {
                          opacity: 1,
                          transform: "translateY(-4px)",
                        },
                      }}
                    >
                      <img
                        src={logo}
                        alt={`${name} logo`}
                        width={150}
                        height={50}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "none" },
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                animation: "marquee 15s linear infinite",
                "@keyframes marquee": {
                  "0%": { transform: "translateX(0)" },
                  "100%": { transform: "translateX(-33.33%)" },
                },
                gap: 4,
                "& > *": {
                  flex: "0 0 auto",
                },
                width: "max-content",
              }}
            >
              {[
                ...investors,
                ...investors,
                ...investors,
                ...investors,
                ...investors,
                ...investors,
              ].map(({ logo, name, link }, index) => (
                <Link
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    minWidth: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      opacity: 0.85,
                    }}
                  >
                    <img
                      src={logo}
                      alt={`${name} logo`}
                      width={120}
                      height={40}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        </Box>

        {/* Advisors Section */}
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "background.default",
            py: { xs: 4, sm: 6, md: 8 },
            px: { xs: 2, sm: 4 },
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* Background Logo 1 */}
          <Box
            sx={{
              position: "absolute",
              top: { xs: "10%", md: "50%" },
              right: { xs: "-15%", md: "-15%" },
              transform: { 
                xs: "translate(0, 0) rotate(15deg)", 
                md: "translate(0, -50%) rotate(15deg)" 
              },
              width: { xs: "60%", md: "35%" },
              height: "auto",
              opacity: 0.1,
              zIndex: 0,
              pointerEvents: "none",
            }}
          >
            <img
              src={croboGradientLogo}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          {/* Background Logo 2 */}
          <Box
            sx={{
              position: "absolute",
              bottom: { xs: "10%", md: "auto" },
              top: { xs: "auto", md: "50%" },
              left: { xs: "-15%", md: "-15%" },
              transform: { 
                xs: "translate(-150%, -50%) rotate(15deg)", 
                md: "translate(0, -50%) rotate(15deg)" 
              },
              width: { xs: "60%", md: "35%" },
              height: "auto",
              opacity: 0.1,
              zIndex: 0,
              pointerEvents: "none",
            }}
          >
            <img
              src={croboGradientLogo}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          <Container
            maxWidth="lg"
            sx={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                mb: { xs: 3, sm: 4, md: 6 },
                fontSize: { xs: "2rem", md: "2.75rem" },
                fontWeight: 700,
                color: "text.primary",
              }}
            >
              Our advisors
            </Typography>

            <Grid
              container
              spacing={{ xs: 2, sm: 3, md: 4 }}
              justifyContent="center"
              alignItems="stretch"
            >
              {advisors.map((advisor) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={advisor.name}
                  display="flex"
                  justifyContent="center"
                >
                  <TeamMemberCard
                    name={advisor.name}
                    role={advisor.role}
                    imageUrl={advisor.image}
                    linkedin={advisor.linkedin}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Container>
    </FullPageContainer>
  );
};

export default AboutUsV1;