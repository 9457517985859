import React, { useEffect, useState } from "react";
import CroboContainer from "../components/croboContainer";
import {
  Box,
  Typography,
  Button,
  List,
  ListItemIcon,
  Modal,
  Container,
  TextField,
  FormControl,
  FormLabel,
  CircularProgress,
  Paper,
  ListItemButton,
} from "@mui/material";
import {
  AccountBalance as AccountBalanceIcon,
  HeadsetMic as HeadsetMicIcon,
  Note as NoteIcon,
  Policy as PolicyIcon,
  Gavel as GavelIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  apiRoutes,
  getProfile,
  remitStatusUpdate,
  setLoader,
  logout,
  getRiskScore,
} from "../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { runPersonaClient } from "../components/persona";
import BottomBranding from "../components/bottomBranding";
import Loader from "../components/loader";
import { remitStatusUpdateCall } from "../reducers/userReducer";
import { isWeb } from "../utils/platform";
import Skeleton from "@mui/material/Skeleton";
import { TransactionLimitLine } from "./AppHome/TransactionLimit";
import InfoIcon from "@mui/icons-material/Info";
import CampaignIcon from '@mui/icons-material/Campaign';
import UserProfile from "./AppHome/userProfile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const TransactionLimitsSkeleton = () => (
  <Box my={2}>
    <Skeleton width={180} height={32} sx={{ mb: 2 }} />
    <Box
      sx={{
        bgcolor: "background.default",
        border: "1px solid",
        borderColor: "grey.300",
        borderRadius: 2,
        p: 2,
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Skeleton variant="circular" width={100} height={100} sx={{ mb: 1 }} />
        <Skeleton width={120} height={24} />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Skeleton variant="circular" width={100} height={100} sx={{ mb: 1 }} />
        <Skeleton width={120} height={24} />
      </Box>
    </Box>
  </Box>
);

const ProfileActionsSkeleton = () => (
  <List sx={{ display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Box key={item}>
          <Skeleton
            variant="rectangular"
            height={50}
            sx={{ mt: 1, borderRadius: 2 }}
          />
        </Box>
      ))}
    </Box>
  </List>
);

const Profile = () => {
  return (
    <CroboContainer>
      <Container>
        <ProfileInternal />
      </Container>
    </CroboContainer>
  );
};

export const ProfileInternal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, profile, bank, bankVerification, riskScore ,isAuthenticated} = useSelector(
    (state) => state.userAuth
  );
  const phoneStore = localStorage.getItem("phone") || null;
  const [open, setOpen] = useState(false);
  const [SSNStatus, setSSNStatus] = useState(0);
  const [isLimitReq, setIsLimitReq] = useState(0);
  const [userName, setUserName] = useState(null);
  const [transactionLimit, setTransactionLimit] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      if(!isAuthenticated) return;
      const phone = localStorage.getItem("phone");
      const token = localStorage.getItem("token");
      const config = {
        method: "post",
        url: apiRoutes.fetchUsers,
        data: {
          client_id: "APIGetBitRemitWeb",
          country_code: "+1",
          phone_number: phone.substring(2),
          user_type: "sender",
        },
        headers: { "x-auth-token": token },
      };
      try{
      const payload = await axios.request(config);
      localStorage.setItem("fetchUser", JSON.stringify(payload.data));
      setIsLimitReq(payload.data.isLimitReq);
      const userInfo = payload.data;
      if (userInfo && userInfo.transaction_limits) {
        const usdLimit = userInfo.transaction_limits.Transaction_limit.find(
          (limit) => limit.currency === "USD"
        );
        setTransactionLimit(usdLimit);
      }
    } catch (error) {
      if (error.response.status !== 200) {
        dispatch(logout());
        navigate("/login");
      }
    }
    };
    dispatch(getRiskScore());

    fetchUser();
    const profileData = profile || JSON.parse(localStorage.getItem("profile"));
    if (profileData) {
      setUserName(profileData?.user_name);
      setSSNStatus(profileData.ssn_status);
    }
  }, []);

  useEffect(() => {
    const checkStatus = async () => {
      const remitStatusUpdateResponse = await remitStatusUpdateCall(
        token,
        true
      );
    };
    checkStatus();
  }, [dispatch, bank, bankVerification, navigate]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleReferAndEarnClick = () => navigate("/referrals");
  const handleManageBankAccount = () => navigate("/manage-bank-account");
  const handleLogout = () => {
    navigate("/login");
    dispatch(logout());
  };

  const onPersonaComplete = async () => {
    dispatch(setLoader(true));
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
    dispatch(remitStatusUpdate({ token, isRefresh: "false" }));
    dispatch(getProfile());
  };

  const handleLimitSubmit = async ({ amount, purpose, transferDate }) => {
    dispatch(setLoader(true));

    try {
      // Step 1: Call createLimitRequest API
      const createLimitConfig = {
        method: "post",
        url: apiRoutes.createLimitRequest,
        headers: { "x-auth-token": token },
        data: {
          purpose,
          amount,
          timeline: transferDate,
        },
      };

      const limitRequestResponse = await axios.request(createLimitConfig);

      setIsLimitReq(1);

      if (limitRequestResponse.status === 201 && SSNStatus === 0) {
        console.log(
          "Limit request created successfully:",
          limitRequestResponse.data
        );
        // Step 3: If SSN is not completed, call remitIdentityVerification API
        const remitIdentityConfig = {
          method: "post",
          url: apiRoutes.remitIdentityVerification,
          headers: { "x-auth-token": token },
          data: { type: "ssn" },
        };

        const remitIdentityResponse = await axios.request(remitIdentityConfig);

        // Run Persona client for SSN verification
        await runPersonaClient(
          remitIdentityResponse.data.inquiry_id,
          onPersonaComplete
        );
        console.log("SSN verification initiated successfully.");
      }
    } catch (error) {
      console.error("Error processing request:", error);
    } finally {
      handleClose();
      dispatch(setLoader(false));
    }
  };

  const handleSSNVerification = async () => {
    const remitIdentityConfig = {
      method: "post",
      url: apiRoutes.remitIdentityVerification,
      headers: { "x-auth-token": token },
      data: { type: "ssn" },
    };

    const remitIdentityResponse = await axios.request(remitIdentityConfig);

    // Run Persona client for SSN verification
    await runPersonaClient(
      remitIdentityResponse.data.inquiry_id,
      onPersonaComplete
    );
    console.log("SSN verification initiated successfully.");
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={"center"}
      width={"100%"}
    >
      <Box
        sx={{
          borderRadius: 2,
          alignContent: "center",
          width: "100%",
        }}
      >
        <UserProfile userName={userName} phone={phoneStore} message={""}/>
        <TransactionLimitLine
          transactionLimit={transactionLimit}
          isLimitReq={isLimitReq}
          setIsLimitReq={setIsLimitReq}
          handleLimitSubmit={handleLimitSubmit}
          SSNStatus={SSNStatus}
          showButton={false}
        />

      {transactionLimit && (
        <EnhancedLimitSection
          isLimitReq={isLimitReq}
          SSNStatus={SSNStatus}
          handleOpen={handleOpen}
          handleSSNVerification={handleSSNVerification}
        />
      )}

        <SSNVerificationModal
          open={open}
          handleClose={handleClose}
          transactionLimit={transactionLimit}
          ssnStatus={SSNStatus}
          onSubmit={handleLimitSubmit}
        />
        <ProfileActions
          handleManageBankAccount={handleManageBankAccount}
          handleReferAndEarnClick={handleReferAndEarnClick}
          handleLogout={handleLogout}
          bank={bank}
          navigate={navigate}
          riskScore={riskScore}
          loading={!profile}
        />
        <BottomBranding />
      </Box>
    </Box>
  );
};


const TransactionLimits = ({ transactionLimit }) => (
  <Box my={2}>
    <Typography variant="h6">Transaction limits</Typography>
    {!transactionLimit ? (
      <TransactionLimitsSkeleton />
    ) : (
      <Box
        sx={{
          bgcolor: "background.default",
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 2,
          p: 2,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              size={100}
              sx={{ color: "grey.200" }}
              variant="determinate"
              value={100}
            />
            <CircularProgress
              size={100}
              sx={{
                color: "brand.main",
                position: "absolute",
                left: 0,
              }}
              variant="determinate"
              value={
                (transactionLimit.Daily / transactionLimit.Max_daily) * 100
              }
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">
                Daily <br /> limit
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2">
            $ {transactionLimit.Daily} of $ {transactionLimit.Max_daily} left
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              size={100}
              sx={{ color: "grey.200" }}
              variant="determinate"
              value={100}
            />
            <CircularProgress
              size={100}
              sx={{
                color: "brand.main",
                position: "absolute",
                left: 0,
              }}
              variant="determinate"
              value={
                (transactionLimit.Weekly / transactionLimit.Max_weekly) * 100
              }
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" textAlign={"center"}>
                Weekly <br /> limit
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2">
            $ {transactionLimit.Weekly} of $ {transactionLimit.Max_weekly} left
          </Typography>
        </Box>
      </Box>
    )}
  </Box>
);


const EnhancedLimitSection = ({ isLimitReq, SSNStatus, handleOpen, handleSSNVerification }) => {
  if (!isLimitReq) {
    return (
      <Button
        onClick={handleOpen}
        variant="contained"
        color="brand"
        size="large"
        fullWidth
        sx={{ mb: 2, textTransform: 'none' }}
      >
        Click here to get enhanced limits
      </Button>
    );
  }

  if (isLimitReq && SSNStatus === 0) {
    return (
      <Button
        onClick={handleSSNVerification}
        variant="contained"
        color="brand"
        size="large"
        fullWidth
        sx={{ mb: 2 }}
      >
        Complete enhanced limit process
      </Button>
    );
  }

  return null;
};

export const SSNVerificationModal = ({ open, handleClose, transactionLimit, ssnStatus, onSubmit }) => {
  const [amount, setAmount] = useState(transactionLimit?.Max_weekly + 1 || 1);
  const [purpose, setPurpose] = useState("");
  const [transferDate, setTransferDate] = useState(new Date().toISOString().split("T")[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ amount, purpose, transferDate });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: "center"}}>
            Limit request form
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Weekly limit required"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              variant="filled"
              margin="normal"
              inputProps={{
                min: transactionLimit?.Max_weekly
              }}
              error={amount < transactionLimit?.Max_weekly}
              helperText={amount < transactionLimit?.Max_weekly ? "Amount is less than the minimum weekly limit" : ""}
            />
            <TextField
              fullWidth
              label="Purpose of transfer"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              variant="filled"
              margin="normal"
            />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Transfer date</FormLabel>
              <TextField
                id="transfer-date"
                label="Select date"
                type="date"
                variant="filled"
                value={transferDate}
                onChange={(event) => setTransferDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
                margin="normal"
                fullWidth
              />
              {ssnStatus === 0 && (
                <Typography variant="caption" sx={{textAlign: "center", color: "red"}} >
                  Note: Completing SSN verification is mandatory to process this request.
                </Typography>
              )}
            </FormControl>
            
            <Button
              variant="contained"
              color="brand"
              size="large"
              onClick={handleSubmit}
              fullWidth
              sx={{ textTransform: 'none' }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};

const ProfileActions = ({
  handleManageBankAccount,
  handleReferAndEarnClick,
  handleLogout,
  bank,
  riskScore,
  navigate,
  loading,
}) =>
  loading ? (
    <ProfileActionsSkeleton />
  ) : (
    <List sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {" "}
        {riskScore > 0 ? (
          <>
            {bank === 1  || bank === 6 ? (
              <ProfileActionButton
                onClick={handleManageBankAccount}
                icon={<AccountBalanceIcon sx={{ color: "black" }} />}
                text="Manage bank account"
              />
            ) : (
              <ProfileActionButton
                onClick={() => {
                  navigate("/onboarding");
                }}
                icon={<AccountBalanceIcon sx={{ color: "black" }} />}
                text="Add bank account"
              />
            )}
          </>
        ) : (
          <></>
        )}
        <ProfileActionButton
          onClick={handleReferAndEarnClick}
          icon={<CampaignIcon sx={{ color: "black" }} />}
          text="Refer and earn"
        />
        <ProfileActionButton
          onClick={() => navigate("/support")}
          icon={<HeadsetMicIcon sx={{ color: "black" }} />}
          text="Support"
        />
        <ProfileActionButton
          onClick={() => navigate("/about-us")}
          icon={<InfoIcon sx={{ color: "black" }} />}
          text="About Us"
        />
        <ProfileActionButton
          href="/privacy-policy"
          target={isWeb() ? "_blank" : ""}
          rel="noopener noreferrer"
          icon={<PolicyIcon sx={{ color: "black" }} />}
          text="Privacy policy"
        />
        <ProfileActionButton
          href="/tnc"
          target={isWeb() ? "_blank" : ""}
          rel="noopener noreferrer"
          icon={<GavelIcon sx={{ color: "black" }} />}
          text="Terms and conditions"
        />
        <ProfileActionButton
          onClick={handleLogout}
          icon={<ExitToAppIcon sx={{ color: "custom.error" }}/>}
          text="Logout"
          color="custom.error"
        />
      </Box>
    </List>
  );


const ProfileActionButton = ({
  onClick,
  href,
  icon,
  text,
  target,
  rel,
  color,
}) => (
  <Box>
    <Button
      variant="outlined"
      fullWidth
      onClick={onClick}
      href={href}
      target={isWeb() ? target : ""}
      rel={isWeb() ? rel : ""}
      sx={{
        width: "100%",
        height: 50,
        borderRadius: 2,
        mt: 1,
        backgroundColor: "white",
        color: "black",
        borderColor: "grey.300",
        display: "flex",
        justifyContent: "flex-start",
        "&:hover": {
          backgroundColor: "#d9dcdd",
          borderColor: "#d9dcdd",
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          fontWeight: "bold",
          color: color,
        }}
      >
        {text}
      </Typography>
    </Button>
  </Box>
);

export const EnhancedLimitManager = ({ isLimitReq, setIsLimitReq, handleLimitSubmit, SSNStatus, transactionLimit }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <Box>
     <EnhancedLimitSection isLimitReq={isLimitReq} setIsLimitReq={setIsLimitReq} handleLimitSubmit={handleLimitSubmit} SSNStatus={SSNStatus}/>
     <SSNVerificationModal open={open} handleClose={handleClose} transactionLimit={transactionLimit} ssnStatus={SSNStatus} onSubmit={handleLimitSubmit}/>
    </Box>
  );
};

export default Profile;
