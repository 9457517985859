import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { amountRoundOff, convertToDate } from "../helper/helper";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Paper } from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import { useTheme } from '@mui/material/styles';

const TransactionCard = ({
  id,
  status,
  exchangeRate,
  UtrNo,
  receiverName,
  receiveInBank,
  tranId,
  transactionalTime,
  sourceAmount,
  onClick,
  nickName,
}) => {
  const theme = useTheme();
  
  const getStatusConfig = (status, receiveInBank) => {
    if (status === 2) {
      return {
        color: theme.palette.custom.error,
        message: "Failed",
        icon: <CloseIcon />,
        bgColor: theme.palette.custom.errorLight,
        borderColor: theme.palette.custom.error,
      };
    } else if (status === 4) {
      return {
        color: theme.palette.custom.error,
        message: "Refunded",
        icon: <CloseIcon />,
        bgColor: theme.palette.custom.errorLight,
        borderColor: theme.palette.error.light,
      };
    } else if ((status === 1 && receiveInBank === 0) || status === 0) {
      return {
        color: theme.palette.custom.warning,
        message: "Pending",
        icon: <PauseIcon />,
        bgColor: theme.palette.custom.warning,
        borderColor: theme.palette.custom.warningLight,
      };
    }
    return {
      color: theme.palette.custom.success,
      message: "Success",
      icon: <CheckCircleIcon />,
      bgColor: theme.palette.custom.successLight,
      borderColor: theme.palette.success.light,
    };
  };

  const statusConfig = getStatusConfig(status, receiveInBank);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        borderRadius: 2,
        my: 1.5,
        border: 1,
        borderColor: theme.palette.brand.main,
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          boxShadow: 3,
        },
      }}
      onClick={onClick}
    >
      {/* Top Row - Status and Amount */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        {/* Status */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{ textTransform: "initial" }}
              >
                {nickName ? nickName : ""}
              </Typography>
            </Box>

            <Typography variant="body2" py={0.5} color="text.secondary">
              {convertToDate(transactionalTime)}
            </Typography>
          </Box>
        </Box>

        {/* Amount */}
        <Box
          sx={{
            py: 0.5,
            borderRadius: 1.5,
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            variant="h4"
            fontWeight={600}
            color={`${statusConfig.color}`}
          >
            ${amountRoundOff(sourceAmount)}
          </Typography>
        </Box>
      </Box>

      {/* Bottom Row - Transaction Details */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Stack spacing={0.5}>
          {/* Exchange Rate */}
          <Typography variant="body2" color="text.secondary">
            $1 = ₹{amountRoundOff(exchangeRate)}
          </Typography>

          {/* UTR Number if exists */}
          {UtrNo && (
            <Typography variant="body2" color="text.secondary">
              UTR: {UtrNo}
            </Typography>
          )}

          {/* Transaction ID */}

          <Typography variant="body2" color="text.secondary">
            Transaction ID: {tranId}
          </Typography>
        </Stack>
        <Typography
          variant="subtitle2"
          color={statusConfig.color}
          fontWeight={600}
        >
          {statusConfig.message}
        </Typography>
      </Box>
    </Paper>
  );
};

export default TransactionCard;
