// src/DocumentUploadInstructions.js
import React from 'react';
import { Typography } from '@mui/material';

const DocumentUploadInstructions = () => {
  return (
    <>
      <Typography>
        Please provide a bank statement dated within the last 90 days.
        <br />
        The document must clearly display the following:
      </Typography>
      <ul>
        <li>Bank name</li>
        <li>Account holder's name</li>
        <li>Account number</li>
        <li>Transaction history</li>
      </ul>
    </>
  );
};

export default DocumentUploadInstructions;
