import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HistoryIcon from "@mui/icons-material/History";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import useAuthStatus from "../../helper/useAuthStatus";

const navItems = [
  { label: "Home", icon: <HomeOutlinedIcon />, path: "/app-home"  },
  {
    label: "Recipients",
    icon: <PersonOutlineOutlinedIcon />,
    path: "/transaction",
  },
  { label: "Referrals", icon: <CampaignIcon />, path: "/referrals" }, // Spacer item
  { label: "History", icon: <HistoryIcon />, path: "/transaction/history" },
  { label: "Profile", icon: <PersonIcon />, path: "/profile" },
];

const hideBottomNav = [
 "/login",
 "/signup",
 '/pin',
 '/onboarding',
 '/new',
 '/link/phone',
];

export default function BottomNavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();
  // Find the index of the current path in navItems
  const currentIndex = React.useMemo(() => {
    // Exact match for home page
    if (location.pathname === "/app-home") {
      return 0;
    }

    // Special handling for history and referrals
    if (location.pathname === "/transaction/history") {
      return 3;
    }
    if (location.pathname === "/profile") {
      return 4;
    }

    // Handle recipients
    if (location.pathname === "/transaction") {
      return 1;
    }
    if(location.pathname === "/referrals") {
      return 2;
    }

    return -1; // No match found
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    // Skip if clicking on the current item or spacer
    if (newValue === currentIndex || !navItems[newValue].path) {
      return;
    }
    window.scrollTo(0, 0);
    navigate(navItems[newValue].path);
  };

  return (
    <>
      {!hideBottomNav.includes(location.pathname) && (
      <Paper sx={{ position: "relative" }} className="bottomNotch">
        <Box
          sx={{
            width: "100vw",
            boxShadow: "0px -10px 10px rgba(0, 0, 0, 0.10)",
            position: "relative",
            py: 1
           }}
        >
          <BottomNavigation
            showLabels
            value={currentIndex}
            onChange={handleChange}
            sx={{ height: "50px" }}
            
          >
            {navItems.map((item, index) =>
              item.icon ? (
                <BottomNavigationAction
                  key={index}
                  label={item.label}
                  icon={item.icon}
                  disabled={index === currentIndex}
                  sx={{
                    "&.Mui-selected": {
                      color: "brand.main",
                    },
                    "& .MuiBottomNavigationAction-label": {
                      fontSize: "0.85rem",
                      marginTop: "4px"
                    },
                    "&.MuiBottomNavigationAction-root": {
                      minWidth: "0px",
                      padding: "0px"
                    },
                  }}
                />
              ) : (
                <BottomNavigationAction
                  key={index}
                  sx={{ minWidth: "60px" }}
                  disabled
                />
              )
            )}
          </BottomNavigation>
          </Box>
        </Paper>
      )}
    </>
  );
}

