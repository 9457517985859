import { Box } from "@mui/material";
import PropTypes from "prop-types";

const NewStyledCard = ({ children, sx = {} }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: '16px',
        padding: 4,
        mx: 2,
        border: '2px solid black',
        boxShadow: '6px 6px 0 #8dffdd',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

NewStyledCard.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default NewStyledCard;