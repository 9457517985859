import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  Button,
  Grow,
} from "@mui/material";
import Heading from "../../components/heading";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from "@mui/material/Skeleton";

const RecipientShortcut = ({ name, initial, lastTransaction, onClick }) => (
  <Grow in={true} timeout={800}>
    <Paper
      elevation={0}
      sx={{
        py: 2,
        textAlign: "center",
        bgcolor: (theme) => `${theme.palette.custom.mint}15`,
        cursor: "pointer",
        transition: "all 0.3s ease",
        borderRadius: 2,
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          bgcolor: (theme) => `${theme.palette.custom.mint}25`,
        },
      }}
      onClick={onClick}
    >
      <Avatar
        sx={{
          width: 48,
          height: 48,
          bgcolor: "brand.main",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
          color:"text.invert",
          fontSize: "1.2rem",
          margin: "0 auto",
          mb: 1,
        }}
      >
        {initial}
      </Avatar>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "medium",
          color: (theme) => theme.palette.custom.purple,
        }}
      >
        {(() => {
          const firstWord = name.split(" ")[0];
          return firstWord.length > 8
            ? firstWord.substring(0, 8) + "..."
            : firstWord;
        })()}
      </Typography>
    </Paper>
  </Grow>
);

const RecipientSkeleton = () => (
  <Paper
    elevation={0}
    sx={{
      py: 2,
      textAlign: "center",
      bgcolor: (theme) => `${theme.palette.custom.mint}15`,
      borderRadius: 2,
    }}
  >
    <Skeleton
      variant="circular"
      width={48}
      height={48}
      sx={{ margin: "0 auto", mb: 1 }}
    />
    <Skeleton width={80} height={24} sx={{ margin: "0 auto" }} />
  </Paper>
);

export const FrequentRecipients = ({ frequentContacts, navigate, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Box sx={{ mb: 2 }}>
          <Skeleton width={120} height={32} sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((item) => (
              <Grid item xs={6} key={item}>
                <RecipientSkeleton />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : frequentContacts.length > 0 ? (
        <Box sx={{ mb: 2 }}>
          <Heading
            title="Recipients"
            sx={{ marginBottom: 2, fontWeight: "bold" }}
          />
          <Grid container spacing={2}>
            {frequentContacts.slice(0, 3).map((contact) => (
              <Grid item xs={6} key={contact.id}>
                <RecipientShortcut
                  name={contact.nickName || contact.user_name}
                  initial={(contact.nickName || contact.user_name)
                    .charAt(0)
                    .toUpperCase()}
                  onClick={() =>
                    navigate(`/transaction/create/${contact.phone}`)
                  }
                />
              </Grid>
            ))}
            <Grid item xs={6}>
              <Paper
                elevation={0}
                sx={{
                  py: 2,
                  textAlign: "center",
                  bgcolor: "brandLight.main",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
                onClick={() => navigate("/recipients")}
              >
                <Avatar
                  sx={{
                    width: 48,
                    height: 48,
                    bgcolor: "brand.main",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                    fontSize: "1.2rem",
                    margin: "0 auto",
                    mb: 1,
                  }}
                >
                  <AddIcon color="white" />
                </Avatar>
                <Typography variant="body2" sx={{ fontWeight: "medium", textTransform: "initial" }}>
                  Add recipient
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {frequentContacts.length >= 3 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                onClick={() => navigate("/transactions")}
                endIcon={<KeyboardDoubleArrowRightIcon />}
                sx={{ textTransform: "initial" }}
              >
                All recipients
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Heading title="No Frequent Recipients" sx={{ marginBottom: 2 }} />
      )}
    </>
  );
};
