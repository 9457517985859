import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  apiRoutes,
  callFetchUsers,
  frequentContact,
  getProfile,
  remitStatusUpdate,
  setLoader,
} from "../../reducers/userReducer";
import axios from "axios";
import CroboContainer from "../../components/croboContainer";
import { runPersonaClient } from "../../components/persona";
import { fetchTransactions } from "../../reducers/transactionReducer";
import { TransactionLimitLine } from "./TransactionLimit";
import { FrequentRecipients } from "./FrequentRecipients";
import { Banner } from "./Banner";
import { RecentTransactions } from "./RecentTransactions";
import useAuthStatus from "../../helper/useAuthStatus";
import UserProfile from "./userProfile";
import FooterV1 from "../../components/footerV1";
import Loader from "../../components/loader";

const AppHome = () => {
  const { loading } = useAuthStatus();
  const navigate = useNavigate();
  const { token, profile, frequentContacts, fetchUser, isAuthenticated } = useSelector(
    (state) => state.userAuth
  );
  const { transactions } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const [transactionLimit, setTransactionLimit] = useState(null);
  const [SSNStatus, setSSNStatus] = useState(0);
  const [isLimitReq, setIsLimitReq] = useState(0);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUser = async () => {
        const phone = localStorage.getItem("phone");
        const data = await callFetchUsers({
          client_id: "APIGetBitRemitWeb",
          country_code: "+1",
          phone_number: phone?.substring(2),
          user_type: "sender",
        });
        const userInfo = data.data;
        if (userInfo && userInfo.transaction_limits) {
          const usdLimit = userInfo.transaction_limits.Transaction_limit.find(
            (limit) => limit.currency === "USD"
          );
          setTransactionLimit(usdLimit);
        }
        setIsLimitReq(data.data.isLimitReq);
      };
      fetchUser();
      dispatch(frequentContact());
      dispatch(fetchTransactions({ page: 1, limit: 3 }));
      const profileData = JSON.parse(localStorage.getItem("profile"));
      if (profileData) {
        setSSNStatus(profileData.ssn_status);
        setUserName(profileData.user_name);
      }
    }else {
      navigate("/login");
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    dispatch(frequentContact());
  }, [dispatch]);

  const onPersonaComplete = async () => {
    dispatch(setLoader(true));
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
    dispatch(remitStatusUpdate({ token, isRefresh: "false" }));
    dispatch(getProfile());
  };

  const handleLimitSubmit = async ({ amount, purpose, transferDate }) => {
    dispatch(setLoader(true));

    try {
      // Step 1: Call createLimitRequest API
      const createLimitConfig = {
        method: "post",
        url: apiRoutes.createLimitRequest,
        headers: { "x-auth-token": token },
        data: {
          purpose,
          amount,
          timeline: transferDate,
        },
      };

      const limitRequestResponse = await axios.request(createLimitConfig);

      setIsLimitReq(1);

      if (limitRequestResponse.status === 201 && SSNStatus === 0) {
        console.log(
          "Limit request created successfully:",
          limitRequestResponse.data
        );
        // Step 3: If SSN is not completed, call remitIdentityVerification API
        const remitIdentityConfig = {
          method: "post",
          url: apiRoutes.remitIdentityVerification,
          headers: { "x-auth-token": token },
          data: { type: "ssn" },
        };

        const remitIdentityResponse = await axios.request(remitIdentityConfig);

        // Run Persona client for SSN verification
        await runPersonaClient(
          remitIdentityResponse.data.inquiry_id,
          onPersonaComplete
        );
        console.log("SSN verification initiated successfully.");
      }
    } catch (error) {
      console.error("Error processing request:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  return (
    <CroboContainer showSearchBar={false} hideNav={true}>
   {loading ? <Loader/> :    <Box>
        <Container>
          <UserProfile userName={userName} />

          <style>
            {`
              @keyframes pulse {
                0% { transform: scale(1); opacity: 1; }
                50% { transform: scale(1.2); opacity: 0.8; }
                100% { transform: scale(1); opacity: 1; }
              }
            `}
          </style>

          <Banner navigate={navigate} profile={profile} />

          <TransactionLimitLine
            transactionLimit={transactionLimit}
            isLimitReq={isLimitReq}
            setIsLimitReq={setIsLimitReq}
            handleLimitSubmit={handleLimitSubmit}
            SSNStatus={SSNStatus}
            showButton={false}
          />
          {/* Top Recipients */}
          <FrequentRecipients
            frequentContacts={frequentContacts}
            navigate={navigate}
          />

          <RecentTransactions transactions={transactions} navigate={navigate} />
        </Container>
        <FooterV1 hideLogo={true} hideDownload={true} />
      </Box>}
    </CroboContainer>
  );
};

export default AppHome;
