import {
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import copy from "./../assets/clipboard.svg";
import { getUTCTime } from "../helper/helper";

const RedeemHistoryCard = ({
  id,
  redeemStatus,
  giftCardCode,
  creationDate,
  statusOfGiftCard,
  amount,
  onClick
}) => {
  const handleClick = (id) => {
    // Handle click event here if needed
    if (onClick) {
      onClick(id);
    }
  };

  const notifyCopiedCode = () => toast.success("Referral code copied!");
  const notifyResendGC = () => toast.success("GC Sent via Email/SMS!");

  return (
    <Box
      sx={{
        p: 2,
        boxShadow: 1,
        borderRadius: 4,
        my: 2,
        bgcolor: "background.default",
        cursor: "default",
        "&:hover": {
          boxShadow: 5,
        },
      }}
    >
      <Box>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
            alignItems: "center",
          }}
        >
          <Typography variant="body1" lineHeight={1.2}>
            Redeem status:
          </Typography>
          <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
            {redeemStatus}
          </Typography>
        </Container>

        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
            alignItems: "center",
          }}
        >
          <Typography variant="body2" lineHeight={1}>
            Gift card code:
          </Typography>
          <Typography
            gap={0.5}
            sx={{
              display: giftCardCode ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
            }}
            color="text.secondary"
            variant="body2"
            lineHeight={1}
          >
            {giftCardCode}
            <img
              className="copy-icon"
              height={14}
              onClick={() => {
                navigator.clipboard.writeText(giftCardCode);
                notifyCopiedCode();
              }}
              src={copy}
              alt="Copy"
            />
          </Typography>
        </Container>

        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
            alignItems: "center",
          }}
        >
          <Typography variant="body2" lineHeight={1}>
            Gift card creation:
          </Typography>
          <Typography color="text.secondary" variant="body2" lineHeight={1}>
            {getUTCTime(creationDate)}
          </Typography>
        </Container>

        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
            alignItems: "center",
          }}
        >
          <Typography variant="body2" lineHeight={1}>
            Status of gift card:
          </Typography>
          <Typography color="text.secondary" variant="body2" lineHeight={1}>
            {statusOfGiftCard}
          </Typography>
        </Container>

        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
            alignItems: "center",
          }}
        >
          <Typography variant="body2" lineHeight={1}>
            Amount:
          </Typography>
          <Typography color="text.secondary" variant="body2" lineHeight={1}>
            {amount}
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          display: giftCardCode ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <Button
          onClick={() => {
            handleClick(id);
            notifyResendGC();
          }}
        >
          Resend gift card code
        </Button>
      </Box>
      <ToastContainer hideProgressBar position="top-center" />
    </Box>
  );
};

export default RedeemHistoryCard;
