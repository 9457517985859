import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { App } from "@capacitor/app";
import brandLogo from "../assets/crobo-white.png";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { processName } from "../helper/helper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import NavMenu from "./Nav/navMenu";
import HamburgerMenu from "./Nav/hamburgerMenu";
import ThemeToggle from "./ThemeToggle";
import useAuthStatus, { nonProtectedRoute } from "../helper/useAuthStatus";

const hideProfile = ["/login", "/signup", "/privacy-policy", "/tnc"];
const logoutPageList = [
  "/pin",
  "/onboarding",
  "/new",
  "/link/phone",
  "/condition",
  "/condition2",
];

const Nav = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallMobile = useMediaQuery("(max-width:400px)");
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = useState(null);

  // Use the auth hook
  const { isAuthenticated } = useAuthStatus();

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (profile?.user_name) {
      setUserName(processName(profile.user_name)?.letters);
    }
  }, []);

  const navigateBack = useCallback(() => {
    if (
      location.pathname === "/transaction/complete" ||
      location.pathname === "/transaction/history"
    ) {
      navigate("/transaction");
    } else {
      navigate(-1);
    }
    window.scrollTo(0, 0);
  }, [location.pathname, navigate]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const theme = useTheme();

  useEffect(() => {
    App.addListener("backButton", ({ canGoBack }) => {
      if (canGoBack) {
        navigateBack();
      } else {
        App.exitApp();
      }
    });

    return () => {
      App.removeAllListeners();
    };
  }, [navigateBack]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box
      bgcolor={"background.default"}
      sx={{
        position: "sticky",
        top: "0",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.divider}`,
        zIndex: 2
      }}
    >
      <Box className="notch"></Box>
      <Grid display={"flex"} sx={{ position: "relative", height: "55px" }}>
        <Grid flexGrow={1}>
          {!nonProtectedRoute.includes(location.pathname) && (
            <IconButton onClick={navigateBack} sx={{ p: 1, zIndex: 1 }}>
              <Avatar sx={{ bgcolor: "brandLight.main" }}>
                <ArrowBackIosNewIcon sx={{ color: "black" }} />
              </Avatar>
            </IconButton>
          )}
        </Grid>

        <Grid
          container
          justifyContent={
            isMobile && location.pathname === "/" && !isAuthenticated
              ? "flex-start"
              : "center"
          }
          alignContent={"center"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bgcolor: "background.default",
            height: "60px",
            padding: "4px",
          }}
        >
          <Link to={isMobile ? "/app-home" : "/"}>
            <img
              src={brandLogo}
              alt="brandLogo"
              height={isSmallMobile ? 33 : 45}
            />
          </Link>
        </Grid>
        {location.pathname === "/" && !isAuthenticated ? (
          <Grid alignItems={"center"} display={"flex"} paddingY={1}>
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button
                id="sign-in-button"
                variant="outlined"
                color="brand"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" color={"black"}>
                  Login
                </Typography>
              </Button>
            </Link>
            <Link to={"/signup"} style={{ textDecoration: "none" }}>
              <Button
                id="sign-in-button"
                variant="contained"
                color="brand"
                sx={{
                  marginX: 1,
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" color={"black"}>
                  SignUp
                </Typography>
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid alignItems={"center"} display={"flex"} padding={1}>
            <Box sx={{ flexGrow: 0 }}>
              {!hideProfile.includes(location.pathname) && (
                <Tooltip title="Open profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{ bgcolor: "brand.main", color: "text.invert" }}
                    >
                      {userName}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              )}
              <NavMenu
                anchorElUser={anchorElUser}
                setAnchorElUser={setAnchorElUser}
                logoutPageList={logoutPageList}
              />
            </Box>

            <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
              <HamburgerMenu toggleDrawer={toggleDrawer} />
            </Drawer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Nav;
