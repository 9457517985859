// Auth.js
import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import "../App.css";
import Nav1 from "./navV1";
import Nav from './nav'
import { useLocation } from "react-router-dom";
import { analytics } from "../helper/helper";
import BottomNavigation from "./Nav/bottomNavigation";
import SearchBar from "./Nav/searchBar";
import { useSelector } from "react-redux";
import { nonProtectedRoute } from "../helper/useAuthStatus";

const CroboContainer = ({ children, showSearchBar = false ,hideNav = false}) => {
  const isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);
  const location = useLocation();

  useEffect(() => {
    const pageName =
      location.pathname.replace(/\//g, "").toUpperCase() || "HOME";

    analytics.page(`REMIT ${pageName}`, {
      page_name: pageName,
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    });
  }, [location]);

  return (
    <Box
      className={hideNav ? "homeContentContainer" : ""}
      sx={{
        minHeight: "100dvh",
        position: "relative",
        backgroundColor: "background.default",
      }}
    >
      {!hideNav && 
      <>
      {nonProtectedRoute.includes(location.pathname) ? <Nav1 /> : <Nav />}
      </>
      }
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Grid
          xs={12}
          sm={8}
          md={5}
          xl={4}
          item={true}
          height={"100%"}
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Box
            flexGrow={1}
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              paddingBottom: "100px",
            }}
          >
            {children}
          </Box>
         {isAuthenticated &&  <Box
            position={"fixed"}
            sx={{
              zIndex: 100000,
              bottom: 0,
              width: "100%",
              display: { xs: "block", md: "none" }, // Hide on medium and larger screens
            }}
          >
            <BottomNavigation />
          </Box>}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CroboContainer;
