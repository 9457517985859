import { Box, IconButton, Typography ,Paper} from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import { analytics, processName } from "../helper/helper";
import { useNavigate } from "react-router-dom";
import Grow from '@mui/material/Grow';
import PushPinIcon from "@mui/icons-material/PushPin";

const FrequentContactCard = ({ id, name, phone, email, nickName,isPinned, onPin, accountNumber }) => {
  const navigate = useNavigate();

  const handleTransaction = (e) => {
    e.stopPropagation();
    navigate(`/transaction/create/${phone}`);
    analytics.track("Frequent Contact Selected", {
      name,
      phone,
    });
  };

  return (
    <Grow in={true} timeout={500}>
      <Paper
        component="article"
        role="button"
        tabIndex={0}
        elevation={0}
        sx={{
          p: 2,
          borderRadius: 2,
          mt: 1,
          border: 1,
          borderColor: isPinned ? "brand.dark" : "brand.main",
          cursor: "pointer",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            bgcolor: "grey.50",
            transform: "translateY(-2px)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
          display: "flex",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
        onClick={handleTransaction}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
            ml: 1,
          }}
        >
          <Typography
            color="text.primary"
            variant="h6"
            sx={{
              fontWeight: 400,
              textTransform: "none",
            }}
          >
            {processName(nickName).words}
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            sx={{
              fontWeight: 400,
            }}
          >
            {accountNumber}
          </Typography>
          {/* <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 0.5 
            }}
          >
            {phone.substr(phone.length - 10)}
          </Typography> */}
        </Box>

        {/* <IconButton
          onClick={handlePin}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: isPinned ? "brand.dark" : "grey.400",
            "&:hover": {
              color: isPinned ? "brand.main" : "brand.dark",
            },
          }}
        >
          <PushPinIcon
            sx={{
              transform: isPinned ? "rotate(45deg)" : "none",
              transition: "transform 0.2s ease-in-out",
            }}
          />
        </IconButton> */}

        <ToastContainer hideProgressBar position="top-center" />
      </Paper>
    </Grow>
  );
};

export default FrequentContactCard;
