import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { App } from "@capacitor/app";
import brandLogo from "../assets/crobo-white.png";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiRoutes, logout } from "../reducers/userReducer";
import { processName } from "../helper/helper";
import axios from "axios";
import NavMenu from "./Nav/navMenu";
import NewDesignButton from "./newDesignButton";
import { useTheme } from "@mui/material/styles";
import useAuthStatus from "../helper/useAuthStatus";

const hideProfile = ["/login", "/signup", "/privacy-policy", "/tnc"];
const logoutPageList = [
  "/pin",
  "/onboarding",
  "/new",
  "/link/phone",
  "/condition",
  "/condition2",
];
const nonProtectedRoute = [
  "/login",
  "/",
  "/signup",
  "/condition",
  "/condition2",
  "/support",
  "/about-us",
  "/privacy-policy",
  "/tnc",
  "/link/phone",
  "/.well-known/assetlinks.json",
];

const NavV1 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState();
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {isAuthenticated} = useSelector((state) => state.userAuth);
  const navigateBack = useCallback(() => {
    if (
      location.pathname === "/transaction/complete" ||
      location.pathname === "/transaction/history"
    ) {
      navigate("/transaction");
    } else {
      navigate(-1);
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const checkAuth = async () => {
      if(!isAuthenticated) return;
      const token = localStorage.getItem("token");
      try {
        let config = {
          method: "get",
          url: apiRoutes.profile,
          headers: { "x-auth-token": token },
        };
        const payload = await axios.request(config);
        localStorage.setItem(
          "profile",
          JSON.stringify(payload.data.data.user_profile)
        );
        return payload;
      } catch (error) {
        if (error.response.status !== 200) {
          dispatch(logout());
          if (!nonProtectedRoute.includes(location.pathname)) {
            navigate("/login");
          }
        }
      }
    };

    checkAuth();
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!nonProtectedRoute.includes(location.pathname)) {
      if (profile) {
        setUserName(processName(profile?.user_name)?.letters);
      } else {
        dispatch(logout());
        navigate("/login");
      }
    }
  }, [dispatch, navigate, location.pathname]);

  useEffect(() => {
    App.addListener("backButton", ({ canGoBack }) => {
      if (canGoBack) {
        navigateBack();
      } else {
        App.exitApp();
      }
    });

    return () => {
      App.removeAllListeners();
    };
  }, [navigateBack]);

  const handleScrollTo = (id) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: id } });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box
      bgcolor={"background.default"}
      sx={{
        position: "sticky",
        top: 0,
        width: "100%",
        borderBottom: `1px solid ${theme.palette.divider}`,
        zIndex: 1000,
      }}
    >
      <Box className="notch"></Box>
      <Grid
        container
        alignItems="center"
        sx={{
          padding: { xs: "12px 16px", md: "16px 48px" },
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        {/* Logo - positioned to the left */}
        <Grid item xs={isMobile ? 6 : 2}>
          <Link to={isMobile ? "/app-home" : "/"}>
            <img src={brandLogo} alt="Crobo" height="32" />
          </Link>
        </Grid>

        {/* Desktop Menu - centered */}
        {!isMobile && (
          <Grid item xs={8}>
            <Box
              sx={{
                display: "flex",
                gap: "32px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  color={
                    location.pathname === "/" ? "brand.main" : "text.primary"
                  }
                  sx={{
                    fontWeight: location.pathname === "/" ? "600" : "500",
                    "&:hover": {
                      color: "brand.dark",
                    },
                  }}
                >
                  Home
                </Typography>
              </Link>
              <Box
                onClick={() => handleScrollTo("how-it-works")}
                sx={{ cursor: "pointer" }}
              >
                <Typography color="text.primary" sx={{ fontWeight: "500" }}>
                  How it works
                </Typography>
              </Box>
              <Box
                onClick={() => handleScrollTo("faq")}
                sx={{ cursor: "pointer" }}
              >
                <Typography color="text.primary" sx={{ fontWeight: "500" }}>
                  FAQ
                </Typography>
              </Box>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <Typography
                  color={
                    location.pathname === "/about-us"
                      ? "brand.main"
                      : "text.primary"
                  }
                  sx={{
                    fontWeight: location.pathname === "/about-us" ? "600" : "500",
                  }}
                >
                  About us
                </Typography>
              </Link>
            </Box>
          </Grid>
        )}

        {/* Login Button/Avatar/Hamburger Section */}
        <Grid
          item
          xs={isMobile ? 6 : 2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            alignItems: "center",
          }}
        >
          {!isAuthenticated && location.pathname !== "/login" ? (
            <NewDesignButton
              text="Login"
              sx={{ py: 0 }}
              onClick={() => navigate("/login")}
            />
          ) : (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {!hideProfile.includes(location.pathname) && (
                <>
                  <Tooltip title="Open profile">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        sx={{
                          bgcolor: "brand.main",
                          color: "background.paper",
                        }}
                      >
                        {userName}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <NavMenu
                    logoutPageList={logoutPageList}
                    setAnchorElUser={setAnchorElUser}
                    anchorElUser={anchorElUser}
                  />
                </>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavV1;
