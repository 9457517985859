import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecipients } from "../../reducers/userReducer";
import CroboContainer from "../../components/croboContainer";
import { Box, Container, Typography, Pagination } from "@mui/material";
import RecipientsCard from "../../components/recipientsCard";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Grow from '@mui/material/Grow';
import Heading from "../../components/heading";
import Loader from "../../components/loader";

const ManageRecipients = () => {
    const store = useSelector((state) => {
        return state.userAuth
    });
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getRecipients({page : currentPage}));
    }, [dispatch, currentPage]);

    const onClickEdit = (props) => {
        navigate(`/edit-recipient`, {
            state: props,
        });
    };

    const recipientDetails = (recipient, index) => {
        try {
            const bankDetails = recipient.bank_details;
            return (
                <Grow 
                    in={true} 
                    timeout={500 + (index * 100)}
                    key={recipient.id}
                >
                    <div>
                        <RecipientsCard
                            id={recipient.id}
                            name={bankDetails.account_holder_name}
                            nickName={recipient.unverified_user_name}
                            phone={`${recipient.phone}`}
                            bank_account_number={bankDetails.bank_account_number}
                            bank_name={bankDetails.bank_name}
                            ifsc={bankDetails.ifsc_code}
                            onClickEdit={onClickEdit}
                            pan={recipient.pan_number}
                            email={recipient.unverified_email}
                            is_bank_added={recipient.is_bank_added}
                            relationship={recipient.recipient_details[0]?.relationship}
                        />
                    </div>
                </Grow>
            );
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <CroboContainer>
            <Box>
                {store.loading ? (
                    <Loader />
                ) : store.recipients?.length >= 1 ? (
                    <Container>
                        <Heading title="Manage recipient" sx={{ my: 2 }} />
                        {store.recipients?.map(recipientDetails)}
                        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
                            <Pagination
                                count={store.totalRecipients}
                                page={currentPage}
                                onChange={handleChangePage}
                                color="brand"
                            />
                        </Box>
                    </Container>
                ) : (
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ height: "200px" }}>
                        <Typography alignItems={"center"} variant="h5">
                            No recipients
                        </Typography>
                    </Box>
                )}
            </Box>
            <ToastContainer hideProgressBar position="top-center" />
        </CroboContainer>
    );
};

export default ManageRecipients;
