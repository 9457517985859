import React, { useState, useEffect, useCallback } from "react";
import {
  auth,
  signInWithPhoneNumber,
  googleProvider,
  firebaseErrorHandler,
  PhoneAuthProvider,
  signInWithPopup,
} from "../service/firebase";
import {
  Container,
  Button,
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../reducers/userReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ErrorComponent from "../components/errorComponent";
import Loader from "../components/loader";
import { analytics, handlePhoneNumberError, initializeRecaptcha, triggerHaptic } from "../helper/helper";
import CroboContainer from "../components/croboContainer";
import OTPInput from "react-otp-input";
import GoogleButton from "../components/googleButton";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { isWeb } from "../utils/platform";
import EditIcon from "@mui/icons-material/Edit";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, pin, isNewUser } = useSelector((state) => state.userAuth);

    const handleVerifyOtp = useCallback(async () => {
      setLoader(true);
      analytics.track("OTP Verification Attempt", { phone, otp });

      try {
        let result;
        let authToken;
        if (isWeb()) {
          // Web implementation
          result = await confirmationResult.confirm(otp);
        } else {
          // Native implementation
          result = await FirebaseAuthentication.confirmVerificationCode({
            verificationId: confirmationResult,
            verificationCode: otp,
          });

          authToken = await FirebaseAuthentication.getIdToken(true);
        }
        dispatch(
          login({
            phone: result.user.phoneNumber,
            authToken: isWeb() ? result.user.accessToken : authToken.token,
          })
        );
        analytics.track("Login Success", { phone });
      } catch (error) {
        handlePhoneNumberError(error, setError, setLoader, setCooldown, phone);
      }
    }, [confirmationResult, dispatch, otp, phone]);
    
  // Add effect to auto-verify OTP when length is 6
  useEffect(() => {
    if (otp.length === 6 && otpSent) {
      handleVerifyOtp();
    }
  }, [otp, handleVerifyOtp, otpSent]);

  useEffect(() => {
    const handleProfileNavigation = async() => {
      await triggerHaptic('heavy');
      if (isNewUser) {
        navigate("/new");
      } else if (!pin) {
        navigate("/pin/set");
      } else {
        navigate("/pin");
      }
    };
    resetState();
    if (profile) {
      handleProfileNavigation();
    }
  }, [navigate, profile, pin, isNewUser]);

  useEffect(() => {
    initializeRecaptcha();
  }, []);

  useEffect(() => {
    let interval;
    if (cooldown) {
      interval = setInterval(() => {
        setCooldownTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            setCooldown(false);
            setIsResendDisabled(false);
            return 60;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [cooldown]);

  const resetState = () => {
    setPhone("");
    setOtp("");
    setOtpSent(false);
    setConfirmationResult(null);
    setError("");
  };

  const handleSignInWithPhone = async () => {
    try {
      setLoader(true);
      analytics.track("OTP Requested", { phone: `+1${phone}` });

      if (cooldown) {
        setError("Please wait a moment before retrying.");
        setLoader(false);
        return;
      }

      if (isWeb()) {
        // Web implementation
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          `+1${phone}`,
          appVerifier
        );
        setOtpSent(true);
        setLoader(false);
        setIsResendDisabled(true);
        setCooldown(true);
        analytics.track("OTP Sent", { phone });
        setConfirmationResult(confirmationResult);
      } else {
        // Native implementation
        await FirebaseAuthentication.signInWithPhoneNumber({
          phoneNumber: `+1${phone}`,
        });

        // Add listener for phoneCodeSent event
        await FirebaseAuthentication.addListener("phoneCodeSent", (event) => {
          setConfirmationResult(event.verificationId);
          setOtpSent(true);
          setLoader(false);
          setIsResendDisabled(true);
          setCooldown(true);
          analytics.track("OTP Sent", { phone });
        });
      }
      
    } catch (error) {
      handlePhoneNumberError(error, setError, setLoader, setCooldown,phone);
    }
  };

  const handleResendOtp = () => {
    analytics.track("OTP Resent", { phone });
    setIsResendDisabled(true);
    handleSignInWithPhone();
  };

  const handleOAuthSignIn = async (provider, method) => {
    try {
      setLoader(true);
      let result;
      let authToken;
      if (isWeb()) {
        // Web implementation
        result = await signInWithPopup(auth, provider);
      } else {
        // Native implementation
        if (method === "Google") {
          result = await FirebaseAuthentication.signInWithGoogle({
            prompt: "select_account", // Force account selection
          });
          authToken = await FirebaseAuthentication.getIdToken(true);
        }
      }

      const user = result.user;
      const providerData = user.providerData;
      const isPhoneLinked = providerData.some(
        (provider) => provider.providerId === PhoneAuthProvider.PROVIDER_ID
      );

      if (isPhoneLinked) {
        dispatch(
          login({
            phone: result.user.phoneNumber,
            authToken: isWeb() ? result.user.accessToken : authToken.token,
          })
        );
        localStorage.setItem("phone", user.phoneNumber);
        localStorage.setItem("photoURL", user.photoURL);
          result && analytics.track("Login Success", { method });
      } else {
        navigate("/link/phone");
      }
    } catch (error) {
      const errorMessage = firebaseErrorHandler(error.code);
      setError(errorMessage);
      setLoader(false);
      analytics.track(`${method} Sign-In Failed`, { error: errorMessage });
    }
  };

  const handleGoogleSignIn = () => handleOAuthSignIn(googleProvider, "Google");
  // const handleAppleSignIn = () => handleOAuthSignIn(appleProvider, "Apple");

  const handleChangePhoneNumber = () => {
    analytics.track("Phone Number Change Initiated", { phone });
    setOtpSent(false);
    setConfirmationResult(null);
    setOtp("");
    setError("");
    setIsResendDisabled(false);
    setCooldown(false);
    setCooldownTime(60);
    setPhone("");
    setLoader(false);
  };

  const renderPhoneNumberInput = () => (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <OutlinedInput
          placeholder="(123) 456-7890"
          value={phone}
          sx={{
            bgcolor:"background.paper",
            borderRadius: 2,
            "& .MuiOutlinedInput-input": {
              py: 1.5,
              fontSize: "1.1rem",
            },
          }}
          type="tel"
          disabled={otpSent}
          onChange={(e) => {
            const p = e.target.value;
            setPhone(p.toString());
            if (p.toString().length > 10) {
              setError("Invalid Phone Number");
            } else {
              setError(null);
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRight: 1,
                  borderColor: "grey.300",
                  pr: 1,
                  mr: 1,
                }}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                  height={20}
                  width={30}
                  alt="+1"
                  style={{ borderRadius: 2 }}
                />
                <Typography sx={{ ml: 1, color: "text.secondary" }}>
                  +1
                </Typography>
              </Box>
            </InputAdornment>
          }
          endAdornment={
            otpSent && (
              <IconButton
                onClick={handleChangePhoneNumber}
                color="brand"
                variant="outlined"
              >
                <EditIcon />
              </IconButton>
            )
          }
          fullWidth
          inputProps={{
            maxLength: 10,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
      </Box>
    </Box>
  );

  const renderOtpInput = () => (
    <Box sx={{ py: 3 }}>
      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Enter verification code
      </Typography>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        shouldAutoFocus={true}
        inputType="number"
        renderSeparator={<span style={{ width: "4px" }} />}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
              fontSize: 18,
              textAlign: "center",
              fontFamily: "Arial",
            }}
          />
        )}
        containerStyle={{
          justifyContent: "center",
          gap: "2px",
        }}
      />

      <Box sx={{ mt: 3 }}>
        {loader ? (
          <Loader />
        ) : (
          <Button
            variant="contained"
            color="brand"
            onClick={handleVerifyOtp}
            fullWidth
            sx={{
              py: 1.5,
              borderRadius: 2,
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={otp.length !== 6}
          >
            Verify code
          </Button>
        )}
      </Box>

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Typography color="text.secondary" variant="body2">
          {cooldown
            ? `Resend code in ${cooldownTime}s`
            : "Didn't receive the code?"}
        </Typography>
        <Button
          onClick={() => handleResendOtp(phone, setIsResendDisabled, handleSignInWithPhone)}
          disabled={isResendDisabled}
          sx={{
            mt: 1,
            textTransform: "none",
            fontSize: "0.9rem",
          }}
        >
          Send again
        </Button>
      </Box>
    </Box>
  );

  const renderProceedButton = () => (
    <Box sx={{ mt: 2 }}>
      {loader ? (
        <Loader />
      ) : (
        <Button
          onClick={handleSignInWithPhone}
          id="sign-in-button"
          variant="contained"
          color="brand"
          fullWidth
          sx={{
            py: 1.5,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1rem",
          }}
          disabled={phone.length !== 10 || cooldown}
        >
          Continue
        </Button>
      )}
    </Box>
  );

  const renderTermsAndConditions = () => (
    <Grid marginTop={2}>
      {location.pathname === "/signup" && (
        <Typography variant="body2" textAlign={"center"}>
          By continuing, you agree to our{" "}
          <Link to={"/tnc"} viewTransition>Terms of service</Link> and <br /> confirm that you
          have read our <Link to={"/privacy-policy"} viewTransition>Privacy policy</Link> to
          learn <br /> how we collect, use, and share your data.
        </Typography>
      )}
    </Grid>
  );

  const renderFooterLinks = () => (
    <Box>
      {location.pathname === "/login" ? (
        <Typography textAlign={"center"} marginTop={4}>
          Don't have an account?
          <Link to={"/signup"} viewTransition>
            <Button variant="text" sx={{ textTransform: "none" }}>Sign up</Button>
          </Link>
        </Typography>
      ) : (
        <Typography textAlign={"center"} marginTop={4}>
          Already have an account?
          <Link to={"/login"} viewTransition>
            <Button variant="text" sx={{ textTransform: "none" }}>Login</Button>
          </Link>
        </Typography>
      )}
    </Box>
  );

  const renderSocialButtons = () => (
    <Box sx={{ mt: 3, mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 3,
        }}
      >
        <Divider sx={{ flex: 1 }} />
        <Typography sx={{ px: 2, color: "text.secondary" }} variant="body2">
          OR
        </Typography>
        <Divider sx={{ flex: 1 }} />
      </Box>
      <GoogleButton
        onClick={handleGoogleSignIn}
        sx={{
          borderRadius: 2,
          py: 1.5,
          border: 1,
          borderColor: "grey.300",
        }}
      />
    </Box>
  );

  return (
    <CroboContainer>
      <Container maxWidth="sm" sx={{ px: 2 }}>
        <Box
          sx={{
            pt: { xs: 6, sm: 10 },
            pb: 4,
          }}
        >
          <Box sx={{ mb: 4, textAlign: "center" }}>
            {location.pathname === "/login" ? (
              <>
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                  Welcome back
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Login to send money to your family in India
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                  Sign up
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sign up to send money to your family in India
                </Typography>
              </>
            )}
          </Box>

          {renderPhoneNumberInput()}
          {error && <ErrorComponent error={error} />}
          {!otpSent ? renderProceedButton() : renderOtpInput()}
          {!otpSent && !loader && renderSocialButtons()}

          <Box sx={{ mt: "auto", pt: 4 }}>
            {renderTermsAndConditions()}
            {renderFooterLinks()}
          </Box>
        </Box>
      </Container>
    </CroboContainer>
  );
};

export default Login;
