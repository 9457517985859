import React from "react";
import { Box } from "@mui/material";
import "../App.css";
import FullPageContainer from "../components/fullPageContainer";
import HomeContentV1 from "../components/homeContentV1";

const HomeV1 = () => {
  return (
    <Box> 
      <FullPageContainer>
        <Box sx={{ bgcolor: "brandLight.main" }}>
          <Box className="homeContentContainer">
          <HomeContentV1 />
        </Box>
      </Box>
      </FullPageContainer>
    </Box>
  );
};

export default HomeV1;