import React, { useState, useEffect } from "react";
import {
  auth,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  linkWithCredential,
} from "../../service/firebase";
import {
  Container,
  Button,
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { login } from "../../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../components/errorComponent";
import Loader from "../../components/loader";
import { analytics, handlePhoneNumberError, handleResendOtp, initializeRecaptcha } from "../../helper/helper";
import CroboContainer from "../../components/croboContainer";
import OTPInput from "react-otp-input";
import { apiRoutes, backend, login } from "../../reducers/userReducer";
import axios from "axios";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { isWeb } from "../../utils/platform";

const LINK_PHONE = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile, pin, isNewUser } = useSelector((state) => state.userAuth);
  useEffect(() => {
    const handleProfileNavigation = () => {
      if (isNewUser) {
        navigate("/new");
      } else if (!pin) {
        navigate("/pin/set");
      } else {
        navigate("/pin");
      }
    };
    resetState();
    if (profile) {
      handleProfileNavigation();
    }
  }, [navigate, profile, pin, isNewUser]);

  useEffect(() => {
    initializeRecaptcha();
  }, []);

  useEffect(() => {
    const handleCooldownTimer = () => {
      let interval;
      if (cooldown) {
        interval = setInterval(() => {
          setCooldownTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(interval);
              setCooldown(false);
              setIsResendDisabled(false);
              return 60;
            }
            return prevTime - 1;
          });
        }, 1000);
      }
      return () => clearInterval(interval);
    };
    handleCooldownTimer();
  }, [cooldown]);

  const resetState = () => {
    setPhone("");
    setOtp("");
    setOtpSent(false);
    setConfirmationResult(null);
    setError("");
  };

 

  const handleSignInWithPhone = async () => {
    try {
      setLoader(true);
      analytics.track("OTP Requested", { phone: `+1${phone}` });

      if (cooldown) {
        setError("Please wait a moment before retrying.");
        setLoader(false);
        return;
      }

      if (isWeb()) {
        // Web implementation
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          `+1${phone}`,
          appVerifier
        );
        setOtpSent(true);
        setLoader(false);
        setIsResendDisabled(true);
        setCooldown(true);
        analytics.track("OTP Sent", { phone });
        setConfirmationResult(confirmationResult);
      } else {
        // Native implementation
        await FirebaseAuthentication.linkWithPhoneNumber({
          phoneNumber: `+1${phone}`,
        });

        // Add listener for phoneCodeSent event
        await FirebaseAuthentication.addListener("phoneCodeSent", (event) => {
          console.log("Verification code sent:", JSON.stringify(event));
          setConfirmationResult(event.verificationId);
          setOtpSent(true);
          setLoader(false);
          setIsResendDisabled(true);
          setCooldown(true);
          analytics.track("OTP Sent", { phone });
        });
      }
    } catch (error) {
      handlePhoneNumberError(error, setError, setLoader, setCooldown,phone);
    }
  };

  const apiClient = axios.create({
    baseURL: `${backend}`, // Replace with your API base URL
    headers: {
      "Content-Type": "application/json",
    },
  });

  const linkAccount = async (phoneNumber) => {
    try {
      const response = await apiClient.post(`${apiRoutes.linkPhone}`, {
        phoneNumber,
      });
      analytics.track("Link Phone API", {
        phoneNumber,
        response: response.data,
      });
      return true;
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
        setLoader(false);
        setCooldown(false);
        setOtpSent(false);
        setConfirmationResult(null);

        analytics.track("Link Phone Error", {
          phoneNumber,
          error: error.response.data,
        });
        return false;
      } else if (error.request) {
        analytics.track("Link Phone Error", {
          phoneNumber,
          error: "No response received",
        });
        return false;
      } else {
        analytics.track("Link Phone Error", {
          phoneNumber,
          error: error.message,
        });
        return false;
      }
    }
  };

  const handleVerifyOtp = async () => {
    setLoader(true);
    analytics.track("OTP Verification Attempt", { phone: `+1${phone}`, otp });

    try {
      let result;
      let authToken;
      if (await linkAccount(`${phone}`)) {
        if (isWeb()) {
          const phoneCredential = PhoneAuthProvider.credential(
            confirmationResult.verificationId,
            otp
          );
          result = await linkWithCredential(auth.currentUser, phoneCredential);
        } else {
          result = await FirebaseAuthentication.confirmVerificationCode({
            verificationId: confirmationResult,
            verificationCode: otp,
          });
          authToken = await FirebaseAuthentication.getIdToken(true);
        }

        console.log(result);
        dispatch(
          login({
            phone: result.user.phoneNumber,
            authToken: isWeb() ? result.user.accessToken : authToken.token,
          })
        );
        analytics.track("Link Phone Success", { phone });
      }
    } catch (error) {
      handlePhoneNumberError(error, setError, setLoader, setCooldown, phone);
    } finally {
      setLoader(false);
    }
  };

  const renderPhoneNumberInput = () => (
    <Box>
      <OutlinedInput
        placeholder="Phone Number"
        value={phone}
        type="number"
        disabled={otpSent}
        onChange={(e) => {
          const p = e.target.value;
          setPhone(p.toString());
          if (p.toString().length > 10) {
            setError("Invalid Phone Number");
          } else {
            setError(null);
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
              height={30}
              width={40}
              alt="+1"
            />
            <Typography textAlign={"center"} paddingX={1}>
              (+1)
            </Typography>
          </InputAdornment>
        }
        fullWidth
        inputProps={{
          maxLength: 10,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
    </Box>
  );

  const renderOtpInput = () => (
    <Box paddingY={2}>
      <OTPInput
        value={otp}
        label="OTP"
        inputType="number"
        shouldAutoFocus="true"
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span style={{ margin: 5 }}></span>}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "100%",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
              height: "40px",
              fontSize: 18,
              textAlign: "center",
              fontFamily: "Arial",
            }}
          />
        )}
      />
      <Box paddingY={2}>
        {loader ? (
          <Loader />
        ) : (
          <Button
            variant="contained"
            color="brand"
            onClick={handleVerifyOtp}
            fullWidth
            disabled={otp.length !== 6}
            margin="normal"
            size="large"
          >
            Verify OTP
          </Button>
        )}
      </Box>
      <Typography textAlign="center">
        {cooldown
          ? `Resend OTP in ${cooldownTime} seconds`
          : "Didn't receive the OTP?"}
      </Typography>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          onClick={() => handleResendOtp(phone, setIsResendDisabled, handleSignInWithPhone)}
          variant="text"
          disabled={isResendDisabled}
        >
          Resend OTP
        </Button>
      </Box>
    </Box>
  );

  const renderProceedButton = () => (
    <Box paddingY={2}>
      {loader ? (
        <Loader />
      ) : (
        <Button
          onClick={handleSignInWithPhone}
          id="sign-in-button"
          variant="contained"
          color="brand"
          fullWidth
          size="large"
          disabled={phone.length !== 10 || cooldown}
        >
          <Typography variant="h6">Proceed</Typography>
        </Button>
      )}
    </Box>
  );

  return (
    <CroboContainer>
      <Container>
        <Box paddingTop={10} paddingBottom={4}>
          <>
            <Typography textAlign={"center"} variant="h5">
              Link your phone number
            </Typography>
            <Typography textAlign={"center"} variant="body2">
              Link your number to your Crobo account to securely <br /> send
              money to your family in India.
            </Typography>
          </>
        </Box>

        {renderPhoneNumberInput()}
        {error && <ErrorComponent error={error} />}
        {!otpSent ? renderProceedButton() : renderOtpInput()}
      </Container>
    </CroboContainer>
  );
};

export default LINK_PHONE;
