import React, { useEffect, useState } from "react";
import "./App.css";
import router from "./route";
import { Box, createTheme, ThemeProvider, Typography, CssBaseline } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bannerData, fetchEnv, appVersion } from "./reducers/resourceReducer";
import RouteChangeTracker from "./components/RouteChangeTracker";
import { analytics, errorAnalytics } from "./helper/helper";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { VersionInfo } from "./version";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { isWeb } from "./utils/platform";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import LoadingAnimation from "./assets/loading-animation.gif";
import { ENVIRONMENT } from "./reducers/userReducer";
import { ErrorBoundary } from "./components/ErrorBoundary";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          text: {
            primary: "#000000",
            secondary: "#666666",
            invert: "#ffffff",
          },
          brand: {
            main: "#15ad87",
            dark: "#0e8567",
            background: "#ffffff",
            gradient: "linear-gradient(120deg, #77E4C8 0%, #001f4d 100%)",
            text: "white",
          },
          brandDark: {
            main: "#478CCF",
            text: "white",
          },
          brandLight: {
            main: "#EBF4F6",
            text: "black",
          },
          background: {
            default: "#F8FFF6",
            paper: "#FFFFFF",
            dark: "#1E1E1E",
          },
          custom: {
            mint: "#77E4C8",
            teal: "#36C2CE",
            blue: "#478CCF",
            purple: "#001f4d",
            oxfordBlue: "#001f4d",
            charcoal: "#36454F",
            success: "#15ad87",
            successDark: "#128d6f",
            lightGray: "#E0E0E0",
            lighterGray: "#f0f2f0",
            lightBackground: "#ebf4f6",
            altBlue: "#005BB5",
            error: "#b71c1c",
            errorLight: "#ffcdd2",
            warning: "#ffb300",
            warningLight: "#fdd835",
            success: "#2e7d32",
            successLight: "#ccff90",
            gradients: {
              primary: "linear-gradient(135deg, #77E4C8 0%, #001f4d 100%)",
              secondary: "linear-gradient(135deg, #36C2CE 0%, #001f4d 100%)",
            },
            loading: {
              animation: LoadingAnimation
            }
          },
        }
      : {
          text: {
            primary: "#000000",
            secondary: "#666666",
            invert: "#ffffff",
          },
          brand: {
            main: "#77E4C8",
            dark: "#15ad87",
            background: "#ffffff",
            gradient: "linear-gradient(120deg, #77E4C8 0%, #001f4d 100%)",
            text: "white",
          },
          brandDark: {
            main: "#478CCF",
            text: "white",
          },
          brandLight: {
            main: "#EBF4F6",
            text: "black",
          },
          background: {
            default: "#F8FFF6",
            paper: "#FFFFFF",
            dark: "#1E1E1E",
          },
          custom: {
            mint: "#77E4C8",
            teal: "#36C2CE",
            blue: "#478CCF",
            purple: "#001f4d",
            oxfordBlue: "#001f4d",
            charcoal: "#36454F",
            success: "#15ad87",
            successDark: "#128d6f",
            lightGray: "#E0E0E0",
            lighterGray: "#f0f2f0",
            lightBackground: "#ebf4f6",
            altBlue: "#005BB5",
            error: "#b71c1c",
            errorLight: "#ffcdd2",
            warning: "#ffb300",
            warningLight: "#fdd835",
            success: "#2e7d32",
            successLight: "#ccff90",
            gradients: {
              primary: "linear-gradient(135deg, #77E4C8 0%, #001f4d 100%)",
              secondary: "linear-gradient(135deg, #36C2CE 0%, #001f4d 100%)",
            },
            loading: {
              animation: LoadingAnimation
            }
          },
        }),
        typography: {
          fontFamily: '"Kanit", "Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          textTransform: "none",
        },
  },
});

const Auth = () => {
  const dispatch = useDispatch();
  const _appVersion = useSelector((state) => state.resource.versionData);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [version, setVersion] = useState();
  const [mode, setMode] = useState('light');

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    // Load saved theme preference from localStorage
    const savedTheme = localStorage.getItem('theme-mode');
    // if (savedTheme) {
    //   setMode(savedTheme);
    // } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   // Use system preference as fallback
    //   setMode('dark');
    // }

    // Add event listener for theme toggle
    const handleThemeToggle = () => {
      toggleColorMode();
    };
    window.addEventListener('toggle-theme', handleThemeToggle);

    return () => {
      window.removeEventListener('toggle-theme', handleThemeToggle);
    };
  }, []);

  useEffect(() => {
    // Save theme preference to localStorage
    localStorage.setItem('theme-mode', mode);
  }, [mode]);

  useEffect(() => {
    if (!isWeb()) {
      // App.getInfo()
      //   .then((info) => {
      //     setVersion(info.version);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    }
    const initializeApp = async () => {
      try {
        dispatch(fetchEnv());
        dispatch(bannerData());
        dispatch(appVersion({ platform: Capacitor.getPlatform() }));
        if (parseInt(version) < parseFloat(_appVersion.appVersion)) {
          setIsUpdateAvailable(false);
        }
        if (isWeb()) {
          await ScreenOrientation.lock({ orientation: "portrait" });
        }
      } catch (error) {
        errorAnalytics("Error initializing app", { error });
      }
    };

    initializeApp();

    // Cleanup function to unlock orientation when component unmounts
    return () => {
      const unlockOrientation = async () => {
        try {
          await ScreenOrientation.unlock();
        } catch (error) {
          console.error("Error unlocking screen orientation:", error);
        }
      };
      unlockOrientation();
    };
  }, [dispatch]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "90%",
    bgcolor: theme.palette.brand.background,
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        {ENVIRONMENT() && (
          <Box
            position={"fixed"}
            top={"0%"}
            left={"50%"}
            zIndex={99999999}
            sx={{
              backgroundColor: "custom.error",
              boxShadow: 1,
              color: "text.invert",
              textAlign: "center",
              transform: "translate(-50%, 0%)",
            }}
          >
            {ENVIRONMENT()}
          </Box>
        )}
        <CssBaseline />
        <Modal open={isUpdateAvailable} aria-labelledby="update-app">
          <Box
            sx={style}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              id="update-app"
              variant="h6"
              fontWeight={600}
              textAlign="center"
            >
              Please update the app to continue
            </Typography>
            <Button
              variant="contained"
              size="large"
              color="brand"
              sx={{ mt: 2 }}
              onClick={() => {
                switch (Capacitor.getPlatform()) {
                  case "ios":
                    window.open(
                      "https://apps.apple.com/us/app/crobo/id6474628320",
                      "_blank"
                    );
                    analytics.track("App Store Button Clicked", {
                      category: "Update App",
                      label: "App Store Link",
                      url: "https://apps.apple.com/us/app/crobo/id6474628320",
                    });
                    break;
                  case "android":
                    window.open(
                      "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                      "_blank"
                    );
                    analytics.track("Google Play Button Clicked", {
                      category: "Update App",
                      label: "Google Play Link",
                      url: "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                    });
                    break;
                  default:
                    break;
                }
              }}
            >
              <Typography color={"white"}>UPDATE</Typography>
            </Button>
          </Box>
        </Modal>
        <RouterProvider
          router={router}
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          <RouteChangeTracker />
        </RouterProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Auth;
