import { TextField, Button, Box, Typography, InputAdornment, CircularProgress, Select, MenuItem } from '@mui/material';
import { Container } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Code from '@mui/icons-material/Code';
import { useLocation, useNavigate } from "react-router-dom";
import CroboContainer from "../../components/croboContainer";
import { useState } from 'react';
import { updateRecipient } from '../../reducers/userReducer';
import PeopleIcon from '@mui/icons-material/People';
import { ToastContainer, toast } from 'react-toastify';

async function sleep() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, 2000);
    });
}

const EditRecipient = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [name, setName] = useState(state.nickName || '');
    const [email, setEmail] = useState(state.email || '');
    const [ifsc, setIfsc] = useState(state.ifsc || '');
    const [accountNo, setAccountNo] = useState(state.bank_account_number || '');
    const [relationship, setRelationship] = useState(state.relationship || '');
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({}); // Track validation errors

    const notify = () => toast.success("Successful");

    // Validation function
    const validateFields = () => {
        const errors = {};
    
        // Name validation (no special characters except spaces, hyphens, and apostrophes)
        if (!name.trim()) {
            errors.name = 'Account holder name is required.';
        } else if (!/^[A-Za-z\s'-]+$/.test(name)) {
            errors.name = 'Name cannot contain special characters.';
        }
    
        // IFSC code validation
        if (!/^[A-Za-z0-9]{4}[A-Za-z0-9]{6,7}$/.test(ifsc)) {
            errors.ifsc = 'Invalid IFSC code.';
        }
    
        // Bank account number validation
        if (!/^\d{9,18}$/.test(accountNo)) {
            errors.accountNo = 'Bank account number must be 9-18 digits.';
        }
    
        // Relationship validation
        if (!relationship) {
            errors.relationship = 'Relationship is required.';
        }
    
        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFields()) {
            return; // Prevent submission if validation fails
        }

        setLoading(true);
        await updateRecipient({
            id: state.id,
            bank_details: {
                account_holder_name: name,
                ifsc_code: ifsc,
                bank_account_number: accountNo,
            },
            relationship,
        });
        notify();
        await sleep();
        navigate('/manage-recipients');
    };

    return (
        <CroboContainer>
            <Box position={'relative'}>
                <Container  sx={{
                    marginTop: 4,
                    width: '100%',
                    minWidth: '320px'
                }}>
                    <Typography variant="h6" align="left" gutterBottom>
                        Enter recipient details
                    </Typography>
                    <Typography sx={{ color: 'grey.600' }} variant='body2' gutterBottom>
                        Note: NRE accounts are not supported currently.
                    </Typography>
                    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            disabled
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            value={email}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: "background.paper" }}
                        />
                        <TextField
                            label="Nickname"
                            variant="outlined"
                            value={name}
                            required
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={(e) => setName(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: "background.paper" }}
                        />
                        <TextField
                            label="Bank account number"
                            variant="outlined"
                            type="number"
                            value={accountNo}
                            required
                            error={!!errors.accountNo}
                            helperText={errors.accountNo}
                            onChange={(e) => setAccountNo(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalance />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: "background.paper" }}
                        />
                        <TextField
                            label="IFSC code"
                            variant="outlined"
                            value={ifsc}
                            required
                            error={!!errors.ifsc}
                            helperText={errors.ifsc}
                            onChange={(e) => setIfsc(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Code />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: "background.paper" }}
                        />
                        <Select
                            id="relationship"
                            value={relationship}
                            sx={{ width: "100%", bgcolor: "background.paper" }}
                            displayEmpty
                            error={!!errors.relationship}
                            startAdornment={
                                <InputAdornment position="start">
                                    <PeopleIcon />
                                </InputAdornment>
                            }
                            onChange={(e) => setRelationship(e.target.value)}
                            renderValue={(selected) => {
                                if (selected === "") {
                                    return <>Select relationship</>; // Placeholder text
                                }
                                return selected;
                            }}
                        >
                            <MenuItem value="Myself">Myself</MenuItem>
                            <MenuItem value="My Family">My family</MenuItem>
                            <MenuItem value="Someone else">Someone else</MenuItem>
                        </Select>
                        {errors.relationship && (
                            <Typography variant="caption" color="error">
                                {errors.relationship}
                            </Typography>
                        )}
                        <Box display={'flex'} justifyContent={'flex-end'} gap={2}>
                            <Button variant="text" type="button" sx={{ color: 'grey.600', textTransform: 'none' }}
                                onClick={() => navigate('/manage-recipients')}>
                                Cancel
                            </Button>
                            <Button color='brand' disabled={loading} variant="contained" onClick={handleSubmit} sx={{ textTransform: 'none' }}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <Box position={'absolute'} top={'50%'} left={'50%'} sx={{
                    height: '100%',
                    width: '100%',
                    transform: 'translate(-50%, -50%)',
                    display: loading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignContent: 'center',
                }}>
                    <Box margin={'auto'}>
                        <CircularProgress color='brand' />
                    </Box>
                </Box>
            </Box>
            <ToastContainer hideProgressBar position="top-center" />
        </CroboContainer>
    );
};

export default EditRecipient;
