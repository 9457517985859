import {
    Box,
    Container,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { ToastContainer } from 'react-toastify';

  const ReferralHistoryCard = ({
    id,
    userName,
    referee_id,
    isSignedUp,
    referralDate,
    transactionStatus,
    referralStatus,
  }) => {
  
    return (
      <Box
        sx={{
          p: 2,
          boxShadow: 1,
          borderRadius: 4,
          my: 2,
          bgcolor: "background.default",
          cursor: "default",
          "&:hover": {
            boxShadow: 5,
          }
        }}
      >
        <Box>
            <Container sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 0,
              alignItems: 'center',
            }}>
              <Typography variant="body1" lineHeight={1.2}>
                You referred:
              </Typography>
              <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
                {userName}
              </Typography>
            </Container>
  
            <Container sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 0,
              alignItems: 'center'
            }}>
              <Typography variant="body2" lineHeight={1}>
                Sign up:
              </Typography>
              <Typography color="text.secondary" variant="body2" lineHeight={1}>
                {isSignedUp}
              </Typography>
            </Container>
  
            <Container sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 0,
              alignItems: 'center'
            }}>
              <Typography variant="body2" lineHeight={1}>
                Referral date:
              </Typography>
              <Typography color="text.secondary" variant="body2" lineHeight={1}>
                {new Date(referralDate).toLocaleString()}
              </Typography>
            </Container>
  
            <Container sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 0,
              alignItems: 'center'
            }}>
              <Typography variant="body2" lineHeight={1}>
                Transaction status:
              </Typography>
              <Typography color="text.secondary" variant="body2" lineHeight={1}>
                {transactionStatus}
              </Typography>
            </Container>
  
            <Container sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 0,
              alignItems: 'center'
            }}>
              <Typography variant="body2" lineHeight={1}>
                Referral status:
              </Typography>
              <Typography color="text.secondary" variant="body2" lineHeight={1}>
                {referralStatus}
              </Typography>
            </Container>
          </Box>
        <ToastContainer
            hideProgressBar
            position="top-center"
        />
      </Box>
    );
  };
  
  export default ReferralHistoryCard;
  