import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Divider,
  Button,
  Modal,
  IconButton
} from "@mui/material";
import { keyframes } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import usFlag from "../assets/us.png";
import indiaFlag from "../assets/in.png";
import {
  LockOutlined,
  ShieldOutlined,
  ExpandMore,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../reducers/exchangeRateReducer";
import { Link, useNavigate } from "react-router-dom";
import APP_STORE_ICON from "../assets/app_store.png";
import PLAY_STORE_ICON from "../assets/google_play.png";
import NewStyledCard from "./newStyledCard";
import NewDesignButton from "./newDesignButton";
import TransferMoneyImage from "../assets/transfer-money.png";
import SecurityBadge from "../assets/security-badge.png";
import walmartLogo from "../assets/walmart-logo.png";
import googleLogo from "../assets/google-logo.png";
import amazonLogo from "../assets/amazon-logo.png";
import metaLogo from "../assets/meta-logo.png";
import appleLogo from "../assets/apple-logo.png";
import TestimonialsCarouselV1 from "./TestimonialsCarouselV1";
import DollarCardIcon from "../assets/DollarCardIcon.png";
import PersonCardIcon from "../assets/PersonCardIcon.png";
import ProfileCardIcon from "../assets/ProfileCardIcon.png";
import BestRatesGuaranteedIcon from "../assets/BestRatesGuaranteed.png";
import CustomerSupportIcon from "../assets/CustomerSupport.png";
import ZeroFeesIcon from "../assets/ZeroFees.png";
import SecureTransactionsIcon from "../assets/SecureTransactions.png";
import MoneyRefundGuaranteeIcon from "../assets/MoneyRefundGuarantee.png";
import EasyOnboardingIcon from "../assets/EasyOnboarding.png";
import { useTheme } from '@mui/material/styles';
import { fetchCampaigns } from "../reducers/campaignsReducer";
import Carousel from 'react-material-ui-carousel';
import CelebrationIcon from "@mui/icons-material/Celebration";


const faqData = [
  {
    question: "How long will the money transfer take?",
    answer: (
      <>
        We have two transfer speeds
        <br />
        1) Crobo max - Transfer takes up to 2 business days to be credited
        <br />
        2) Crobo instant - Transfer takes a few seconds to be credited
      </>
    ),
  },
  {
    question: "How much does it cost to transfer USD to INR?",
    answer: "It costs nothing ($0)",
  },
  {
    question: "Why do I need to complete KYC verification?",
    answer: "KYC (Know Your Customer) verification is required to comply with financial regulations and ensure the security of all transactions. It helps us prevent fraud and unauthorized transfers.",
  },
  // Add more FAQs as needed
];

const HomeContentV1 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.exchangeRate);
  const [sourceAmount, setSourceAmount] = useState("1000.00");
  const [destinationAmount, setDestinationAmount] = useState("");
  const { campaigns, loading: loadingCampaigns } = useSelector((state) => state.campaigns);
  const theme = useTheme();
  const [openTnC, setOpenTnC] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleSignUpClick = () => {
    navigate("/login");
  };

  const handleOpenTnC = (campaign) => {
    setSelectedCampaign(campaign);
    setOpenTnC(true);
  };

  const handleCloseTnC = () => {
    setOpenTnC(false);
    setSelectedCampaign(null);
  };

  useEffect(() => {
    const profile = localStorage.getItem("profile");
    const userId = JSON.parse(profile) ? JSON.parse(profile).user_id : null;
    dispatch(fetchExchangeRate({userId}));
    dispatch(fetchCampaigns());

    if (store.exchangeRate) {
      const initialDestinationAmount = (
        parseFloat("1000.00") * store.exchangeRate
      ).toFixed(2);
      setDestinationAmount(initialDestinationAmount);
    }
  }, [dispatch, store.exchangeRate]);

  const trustedUsers = [
    {
      logo: walmartLogo,
      name: "Walmart",
      link: "https://www.walmart.com",
    },
    {
      logo: googleLogo,
      name: "Google",
      link: "https://www.google.com",
    },
    {
      logo: amazonLogo,
      name: "Amazon",
      link: "https://www.amazon.com",
    },
    {
      logo: metaLogo,
      name: "Meta",
      link: "https://www.meta.ai",
    },
    {
      logo: appleLogo,
      name: "Apple",
      link: "https://www.apple.com",
      customWidth: { xs: 20, md: 50 },
      height: { xs: 20, md: 50 },
    },
  ];

  const renderCampaignCarousel = () => {
    if (loadingCampaigns) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (!campaigns || campaigns.length === 0) {
      return (
       <></>
      );
    }

    return (
      <Carousel
        animation="slide"
        interval={5000}
        sx={{
          minHeight: '200px',
          '& .MuiIconButton-root': {
            color: 'brand.main',
          }
        }}
      >
        {campaigns.map((campaign) => (
          <Box 
            key={campaign.id}
            sx={{
              bgcolor: "rgba(21, 173, 135, 0.1)",
              py: { xs: 2, md: 3 },
              px: { xs: 2, md: 3 },
              borderRadius: 2,
              position: "relative",
              overflow: "hidden",
              mx: 2,
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(45deg, rgba(21, 173, 135, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%)",
                zIndex: 0,
              },
            }}
          >
            <Typography
              variant={isMobile ? "body1" : "h6"}
              textAlign="center"
              sx={{
                color: "text.primary",
                "& a": { color: "brand.main", textDecoration: "none" },
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  justifyContent: "center",
                  gap: { xs: 1, md: 1 },
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CelebrationIcon
                    sx={{
                      color: "brand.main",
                      fontSize: { xs: 24, md: 32 },
                    }}
                  />
                  <Typography
                    variant={isMobile ? "body1" : "h6"}
                    fontWeight="bold"
                    color="brand.main"
                    sx={{
                      textAlign: "center",
                      whiteSpace: { xs: "normal", md: "nowrap" },
                    }}
                  >
                    Offer till {new Date(campaign.endDate).toLocaleDateString()}
                  </Typography>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {campaign.banner && (
                    <Box 
                      dangerouslySetInnerHTML={{ __html: campaign.banner.bannerContent }}
                      sx={{ mb: 1 }}
                    />
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <LocalOfferIcon
                      sx={{
                        color: "brand.main",
                        fontSize: { xs: 24, md: 32 },
                        mr: 1
                      }}
                    /> */}
                    {/* <Typography
                      variant={isMobile ? "body1" : "h6"}
                      fontWeight="bold"
                      color="brand.main"
                      sx={{
                        textAlign: "center",
                        whiteSpace: { xs: "normal", md: "nowrap" },
                      }}
                    >
                      {campaign.campaignCode}
                    </Typography> */}
                  </Box>
                </Box>

                {/* {campaign?.description && <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    variant={isMobile ? "body1" : "h6"}
                    sx={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    {campaign.description}
                  </Typography>
                </Box>} */}
              </Box>

              <Box
                sx={{
                  mt: { xs: 1, md: 1.5 },
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  justifyContent: "center",
                  gap: { xs: 0.5, md: 2 },
                }}
              >
                {campaign?.banner?.termsAndConditions && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleOpenTnC(campaign)}
                    sx={{ 
                      textTransform: 'none',
                      textDecoration: "underline",
                      "&:hover": {
                        color: "brand.dark",
                      },
                    }}
                  >
                    View T&C
                  </Button>
                )}
              </Box>
            </Typography>
          </Box>
        ))}
      </Carousel>
    );
  };

  return (
    <Box sx={{ width: "100%" }} bgcolor={"background.default"}>
       <Box sx={{ mb: 4, mt: 2 }}>
        {renderCampaignCarousel()}

        {/* Terms and Conditions Modal */}
        <Modal
          open={openTnC}
          onClose={handleCloseTnC}
          aria-labelledby="terms-modal-title"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '60%' },
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: 'auto'
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography id="terms-modal-title" variant="h6" component="h2">
                Terms & Conditions - {selectedCampaign?.campaignCode}
              </Typography>
              <IconButton onClick={handleCloseTnC} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
            {selectedCampaign?.banner?.termsAndConditions && (
              <Box dangerouslySetInnerHTML={{ __html: selectedCampaign.banner.termsAndConditions }} />
            )}
          </Box>
        </Modal>
      </Box>
      <Container maxWidth="lg" sx={{ py: { xs: 2, md: 6 } }}>
        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "600px",
                pr: { md: 6 },
                ml: { xs: 0, md: 2 },
                mb: { xs: 1, md: 0 },
              }}
            >
              <Typography
                variant="h3"
                align={isMobile ? "center" : "left"}
                sx={{
                  fontWeight: "800",
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "2.5rem" },
                  lineHeight: 1.2,
                  mb: { xs: 1, md: 4 },
                }}
              >
                Transfer money to India at{" "}
                <Box component="span" sx={{ color: "brand.main" }}>
                  Google rates
                </Box>
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "1rem", md: "1.25rem" },
                  color: "text.primary",
                  textAlign: { xs: "center", md: "left" },
                  mb: { xs: 1, md: 5 },
                }}
              >
                Instant transfer, zero fees, best exchange rate
              </Typography>

              {false && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "1rem", md: "1.125rem" },
                      textAlign: { xs: "center", md: "left" },
                      mb: 1,
                    }}
                  >
                    Download the App now!
                  </Typography>

                  <Box
                    display="flex"
                    sx={{
                      gap: 3,
                      flexDirection: "row",
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                  >
                    <Link
                      href="https://play.google.com/store/apps/details?id=io.ionic.CROBO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={PLAY_STORE_ICON}
                        alt="Play Store"
                        height="40px"
                      />
                    </Link>

                    <Link
                      href="https://apps.apple.com/us/app/crobo/id6474628320"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={APP_STORE_ICON} alt="App Store" height="40px" />
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <NewStyledCard
              sx={{
                p: { xs: 3, md: 4 },
              }}
            >
              <form>
                <Box mb={2}>
                  <FormLabel htmlFor="sourceAmount">
                    <Typography
                      variant="h6"
                      color={"brandLight.text"}
                      id="sourceAmountLabel"
                      sx={{ fontSize: { xs: "1rem", sm: "1.25rem" }, fontWeight: "600" }}
                    >
                      You send
                    </Typography>
                  </FormLabel>

                  <TextField
                    id="sourceAmount"
                    aria-labelledby="sourceAmountLabel"
                    hiddenLabel
                    type="text"
                    fullWidth
                    sx={{
                      backgroundColor: "#F9FAFB",
                      borderRadius: 2,
                      "& .MuiOutlinedInput-root": {
                        height: "48px",
                        "& input": {
                          fontSize: "20px",
                          fontWeight: 500,
                        },
                        "& fieldset": {
                          borderColor: "#E5E7EB",
                        },
                        "&:hover fieldset": {
                          borderColor: "#E5E7EB",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E5E7EB",
                        },
                      },
                    }}
                    value={sourceAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) || value === ".") {
                        setSourceAmount(value);
                        if (value !== "") {
                          setDestinationAmount(
                            (parseFloat(value) * store.exchangeRate).toFixed(2)
                          );
                        } else {
                          setDestinationAmount("");
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{ fontSize: "20px", fontWeight: 500 }}
                            color="text.secondary"
                          >
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            height: "100%",
                            padding: "0 16px",
                            marginRight: "-14px",
                          }}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <img
                              src={usFlag}
                              alt="USA Flag"
                              style={{ width: "24px" }}
                            />
                            <Typography sx={{ fontWeight: "600", color: "text.primary" }}>USD</Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  mb={1}
                  py={1}
                  borderTop={1}
                  borderBottom={1}
                  borderColor="grey.500"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" fontWeight="450">Exchange rate</Typography>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" fontWeight="450">$1 =</Typography>
                      {store.loading ? (
                        <CircularProgress
                          size={20}
                          sx={{ color: "brand.main" }}
                        />
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          marginLeft={0.5}
                        >
                          {store.campaignApplied && (
                            <Typography
                              variant="body1"
                              sx={{
                                textDecoration: "line-through",
                                color: "gray",
                                fontWeight: "450",
                              }}
                            >
                              ₹{store.oldExchangeRate}
                            </Typography>
                          )}
                          <Typography
                            variant="body1"
                            marginLeft={store.campaignApplied ? 1 : 0}
                            fontWeight="450"
                          >
                            ₹{store.exchangeRate}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" fontWeight="450">Transfer fee</Typography>
                    <Typography variant="body1" fontWeight="450">$0.00</Typography>
                  </Box>
                </Box>
                <Box mb={2}>
                  <FormLabel htmlFor="destinationAmount">
                    <Typography
                      variant="h6"
                      color={"brandLight.text"}
                      id="destinationAmountLabel"
                      sx={{ fontSize: { xs: "1rem", sm: "1.25rem" }, fontWeight: "600" }}
                    >
                      They receive
                    </Typography>
                  </FormLabel>
                  <TextField
                    id="destinationAmount"
                    aria-labelledby="destinationAmountLabel"
                    type="text"
                    fullWidth
                    value={destinationAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) || value === ".") {
                        setDestinationAmount(value);
                        if (value !== "") {
                          setSourceAmount(
                            (parseFloat(value) / store.exchangeRate).toFixed(2)
                          );
                        } else {
                          setSourceAmount("");
                        }
                      }
                    }}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      borderRadius: 2,
                      "& .MuiOutlinedInput-root": {
                        height: "48px",
                        "& input": {
                          fontSize: "20px",
                          fontWeight: 500,
                        },
                        "& fieldset": {
                          borderColor: "#E5E7EB",
                        },
                        "&:hover fieldset": {
                          borderColor: "#E5E7EB",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E5E7EB",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon sx={{ fontSize: "20px" }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box display="flex" alignItems="center" gap={1}>
                            <img
                              src={indiaFlag}
                              alt="India Flag"
                              style={{ width: "25px" }}
                            />
                            <Typography sx={{ fontWeight: "600", color: "text.primary" }}>INR</Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mt={4}>
                  <NewDesignButton
                    text="Start transfer"
                    onClick={handleSignUpClick}
                    fullWidth
                    sx={{
                      px: { xs: 4, md: 6 },
                      py: { xs: 1, md: 1.5 },
                    }}
                  />
                </Box>
              </form>
            </NewStyledCard>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "background.paper",
          py: { xs: 6, md: 8 },
        }}
        id="how-it-works"
      >
        <Grid container spacing={6} justifyContent="center">
          {/* Stats Section */}
          <Grid item xs={12} id="how-it-works">
            <Box
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: "column", md: "row" }}
              gap={{ xs: 6, md: 12 }}
              textAlign="center"
              mb={{ xs: 6, md: 10 }}
              position="relative"
              sx={{
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-40px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: { xs: "90%", md: "80%" },
                  height: "1px",
                  backgroundColor: "divider",
                },
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  gutterBottom
                  sx={{ fontSize: { xs: "2.5rem", md: "3rem" } }}
                >
                  10,000+
                </Typography>
                <Typography
                  variant="h5"
                  color="text.primary"
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" }, fontWeight: "400" }}
                >
                  Customers served
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  borderLeft: "2px solid #E5E7EB",
                  height: "100px",
                }}
              />
              <Box>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  gutterBottom
                  sx={{ fontSize: { xs: "2.5rem", md: "3rem" } }}
                >
                  100,000 +
                </Typography>
                <Typography
                  variant="h5"
                  color="text.primary"
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" }, fontWeight: "400" }}
                >
                  Transactions completed
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Steps Section */}
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: "center",
                mb: { xs: 8, md: 10 },
              }}
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  mb: { xs: 4, md: 6 },
                }}
              >
                Send money in 3 steps
              </Typography>
            </Box>
            <Grid
              container
              justifyContent="center"
              sx={{
                px: { xs: 2, sm: 2, md: 4 },
                width: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  pointerEvents: "none",
                  display: { xs: "none", md: "block" },
                }}
              >
                {/* First curved arrow - top */}
                <svg
                  style={{
                    position: "absolute",
                    left: "30%",
                    top: "-10%",
                    width: "200px",
                    height: "60px",
                  }}
                  viewBox="0 0 200 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 40 C 50 40, 150 0, 200 40"
                    stroke="#20DDAE"
                    strokeWidth="2"
                    fill="none"
                    markerEnd="url(#arrowhead1)"
                  />
                  <defs>
                    <marker
                      id="arrowhead1"
                      markerWidth="10"
                      markerHeight="7"
                      refX="9"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon points="0 0, 10 3.5, 0 7" fill="#20DDAE" />
                    </marker>
                  </defs>
                </svg>

                {/* Second curved arrow - bottom */}
                <svg
                  style={{
                    position: "absolute",
                    left: "55%",
                    bottom: "-20%",
                    width: "200px",
                    height: "60px",
                  }}
                  viewBox="0 0 200 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 20 C 50 20, 150 60, 200 20"
                    stroke="#20DDAE"
                    strokeWidth="2"
                    fill="none"
                    markerEnd="url(#arrowhead2)"
                  />
                  <defs>
                    <marker
                      id="arrowhead2"
                      markerWidth="10"
                      markerHeight="7"
                      refX="9"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon points="0 0, 10 3.5, 0 7" fill="#20DDAE" />
                    </marker>
                  </defs>
                </svg>
              </Box>

              {/* Mobile arrows */}
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  pointerEvents: "none",
                  display: { xs: "block", md: "none" },
                }}
              >
                {/* First curved arrow - top */}
                <svg
                  style={{
                    position: "absolute",
                    left: "6%",
                    top: "27%",
                    width: "60px",
                    height: "120px",
                    transform: "translateX(-50%)",
                  }}
                  viewBox="0 0 60 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40 0 C 40 30, 0 90, 40 120"
                    stroke="#20DDAE"
                    strokeWidth="2"
                    fill="none"
                    markerEnd="url(#arrowhead3)"
                  />
                  <defs>
                    <marker
                      id="arrowhead3"
                      markerWidth="10"
                      markerHeight="7"
                      refX="9"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon points="0 0, 10 3.5, 0 7" fill="#20DDAE" />
                    </marker>
                  </defs>
                </svg>

                {/* Second curved arrow - bottom */}
                <svg
                  style={{
                    position: "absolute",
                    right: "-8%",
                    bottom: "27%",
                    width: "60px",
                    height: "120px",
                    transform: "translateX(-50%)",
                  }}
                  viewBox="0 0 60 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 0 C 20 30, 60 90, 20 120"
                    stroke="#20DDAE"
                    strokeWidth="2"
                    fill="none"
                    markerEnd="url(#arrowhead4)"
                  />
                  <defs>
                    <marker
                      id="arrowhead4"
                      markerWidth="10"
                      markerHeight="7"
                      refX="9"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon points="0 0, 10 3.5, 0 7" fill="#20DDAE" />
                    </marker>
                  </defs>
                </svg>
              </Box>

              {[
                {
                  icon: <img src={ProfileCardIcon} alt="Profile Icon" />,
                  title: "1. Sign up & complete KYC",
                  description:
                    "Begin your money transfer journey by signing up and completing your KYC",
                },
                {
                  icon: <img src={PersonCardIcon} alt="Person Icon" />,
                  title: "2. Add recipient details",
                  description:
                    "Add the bank details of your loved ones whom you want to send money to",
                },
                {
                  icon: <img src={DollarCardIcon} alt="Dollar Icon" />,
                  title: "3. Confirm payment",
                  description:
                    "Confirm the payment and your funds will be delivered immediately & securely to the recipient",
                },
              ].map((step, index) => (
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={3}
                  key={index}
                  sx={{
                    position: "relative",
                    display: "flex",
                    pt: 4,
                    px: 2,
                  }}
                >
                  <NewStyledCard>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          backgroundColor: "#8DFFDD",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexShrink: 0,
                        }}
                      >
                        {step.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: "600",
                          color: "text.primary",
                          flexShrink: 0,
                        }}
                      >
                        {step.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "text.secondary",
                          fontWeight: "500",
                          lineHeight: 1.5,
                          flex: 1,
                        }}
                      >
                        {step.description}
                      </Typography>
                    </Box>
                  </NewStyledCard>
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={{ xs: 4, md: 10 }}>
              <NewDesignButton
                text="Start transfer"
                onClick={handleSignUpClick}
                sx={{
                  px: { xs: 4, md: 6 },
                  py: { xs: 1, md: 1.5 },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          position: "relative",
          width: "90%",
          mt: 8,
          mb: 4,
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: { xs: 2, md: 4 },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: { xs: 2, md: 4 },
            px: { xs: 2, md: 4 },
          }}
        >
          {/* Left Content */}
          <Box
            sx={{
              flex: 1,
              textAlign: { xs: "center", md: "right" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: "800",
                mb: 2,
                lineHeight: 1.2,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Transfer money from <br />
              USA 🇺🇸 to India 🇮🇳
            </Typography>

            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                fontWeight: "600",
                mb: 4,
                lineHeight: 1.5,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Best value, zero fees, secure, instant transfer directly to any
              bank account in India
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              {/* <NewDesignButton
                text="Watch demo"
                onClick={() => {
                  console.log("Watch Demo");
                }}
              /> */}
            </Box>
          </Box>

          {/* Right Content - Image */}
          <Box
            sx={{
              flex: 1,
              maxWidth: { xs: "100%", md: "50%" },
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={TransferMoneyImage}
              alt="Money Transfer Illustration"
              sx={{
                width: "100%",
                height: "auto",
                maxWidth: { xs: "300px", sm: "400px", md: "500px" },
                objectFit: "contain",
              }}
            />
          </Box>
        </Container>
      </Box>

      <Box
        bgcolor={"background.paper"}
        sx={{
          width: "100%",

          py: { xs: 6, md: 10 },
        }}
        id="features"
      >
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            textAlign="center"
            fontWeight="bold"
            sx={{
              mb: { xs: 3, sm: 4, md: 6 },
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            }}
          >
            Why choose CROBO
          </Typography>

          <Grid
            container
            sx={{
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            {[
              {
                icon: <img src={BestRatesGuaranteedIcon} style={{ width: 48, height: 48 }} alt="Best Rates Guaranteed Icon" />,
                title: "Best rates guaranteed",
                description:
                  "Enjoy unbeatable exchange rates every time, no marketing gimmicks, ensuring maximum value for your hard earned money.",
              },
              {
                icon: <img src={CustomerSupportIcon} style={{ width: 48, height: 48 }} alt="Customer Support Icon" />,
                title: "24 x 7 customer support",
                description:
                  "Get assistance whenever you need it with our round-the-clock dedicated support team.",
              },
              {
                icon: <img src={ZeroFeesIcon} style={{ width: 48, height: 48 }} alt="Zero Fees Icon" />,
                title: "Zero fees",
                description:
                  "What you see is what you get—no surprises, no extra charges.",
              },
              {
                icon: <img src={SecureTransactionsIcon} style={{ width: 48, height: 48 }} alt="Secure Transactions Icon" />,
                title: "Secure transactions",
                description:
                  "Your safety is our priority. We ensure top-notch encryption and protection for all your transactions.",
              },
              {
                icon: <img src={MoneyRefundGuaranteeIcon} style={{ width: 48, height: 48 }} alt="Money Refund Guarantee Icon" />,
                title: "Money refund guarantee",
                description:
                  "Your satisfaction matters. We promise 100% hassle-free refund if needed",
              },
              {
                icon: <img src={EasyOnboardingIcon} style={{ width: 48, height: 48 }} alt="Easy Onboarding Icon" />,
                title: "Easy onboarding",
                description:
                  "Get started quickly with our simple and user-friendly setup process.",
              },
            ].map((feature, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={(theme) => ({
                  borderRight: {
                    md:
                      index % 3 !== 2
                        ? `1px solid ${theme.palette.custom.lightGray}`
                        : "none",
                    sm:
                      index % 2 !== 1
                        ? `1px solid ${theme.palette.custom.lightGray}`
                        : "none",
                    xs: "none",
                  },
                  borderBottom: {
                    md:
                      index < 3
                        ? `1px solid ${theme.palette.custom.lightGray}`
                        : "none",
                    sm:
                      index < 4
                        ? `1px solid ${theme.palette.custom.lightGray}`
                        : "none",
                    xs:
                      index < 5
                        ? `1px solid ${theme.palette.custom.lightGray}`
                        : "none",
                  },
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    p: 4,
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: "50%",
                      backgroundColor: "#8DFFDD",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 2,
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "800",
                      mb: 1.5,
                      fontSize: "1.25rem",
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: "1rem",
                      lineHeight: 1.6,
                      maxWidth: "300px",
                      fontWeight: "500",
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Box display="flex" justifyContent="center" mt={6}>
          <NewDesignButton
            text="Start transfer"
            onClick={handleSignUpClick}
            sx={{
              px: { xs: 4, md: 6 },
              py: { xs: 1, md: 1.5 },
            }}
          />
        </Box>
      </Box>

      {/* Security Features Section */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "background.paper",
          py: { xs: 6, md: 10 },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 4, md: 8 }} alignItems="center">
            {/* Left side - Gradient Box */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background:
                    "linear-gradient(180deg, #20DDAE 0%, rgba(141, 255, 221, 0) 100%)",
                  borderRadius: "24px",
                  height: "500px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={theme.palette.custom.loading.animation} alt="Loading Animation" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                {/* Circular Logo */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0px",
                    right: { xs: "0px", md: "-45px" },
                    width: "90px",
                    height: "90px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={SecurityBadge}
                    alt="Security Badge"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Right side - Content */}
            <Grid item xs={12} md={6}>
              <Box sx={{ pl: { md: 4 } }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}
                >
                  <Typography
                    variant="h3"
                    fontWeight="800"
                    sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }}
                  >
                    Highly secured 🛡️
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    mb: 4,
                    fontWeight: "500",
                    fontSize: "1.1rem",
                    lineHeight: 1.6,
                  }}
                >
                  Crobo ensures secure transfers with advanced encryption and
                  multi-factor authentication, complying with global financial
                  regulations. Real-time monitoring helps prevent fraud,
                  protecting user data and transactions for a seamless and safe
                  experience.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 4,
                  }}
                >
                  {/* Security Feature 1 */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", md: "column" },
                      alignItems: { xs: "center", md: "flex-start" },
                      gap: { xs: 3, md: 0 },
                    }}
                  >
                    <Box
                      sx={{
                        width: 56,
                        height: 56,
                        backgroundColor: "#F3F4F6",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mb: { xs: 0, md: 2 },
                        flexShrink: 0,
                      }}
                    >
                      <LockOutlined sx={{ fontSize: 32, color: "#111827" }} />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#6B7280",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Two-factor authentication (2FA) ensures only you can
                      access your account.
                    </Typography>
                  </Box>

                  {/* Security Feature 2 */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", md: "column" },
                      alignItems: { xs: "center", md: "flex-start" },
                      gap: { xs: 3, md: 0 },
                    }}
                  >
                    <Box
                      sx={{
                        width: 56,
                        height: 56,
                        backgroundColor: "#F3F4F6",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mb: { xs: 0, md: 2 },
                        flexShrink: 0,
                      }}
                    >
                      <ShieldOutlined sx={{ fontSize: 32, color: "#111827" }} />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#6B7280",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Your money is securely held with trusted financial
                      institutions, giving you complete peace of mind.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box
        color={"background.default"}
        sx={{
          width: "100%",
          py: { xs: 6, md: 10 },
        }}
      >
        <Container maxWidth="lg">
          <TestimonialsCarouselV1 />
        </Container>
      </Box>

      {/* Trusted by Section */}
      <Box
        sx={{
          width: "100%",
          background: "#1e1e1e",
          py: { xs: 6, md: 8 },
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              mb: { xs: 3, sm: 4, md: 6 },
              fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" },
              fontWeight: 500,
              color: "text.secondary",
            }}
          >
            Trusted by users at
          </Typography>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ maxWidth: 1200, mx: "auto" }}
            >
              {trustedUsers.map(({ logo, name, link, customWidth }) => (
                <Grid
                  item
                  xs={2.4}
                  key={logo}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Link href={link} target="_blank" rel="noopener noreferrer">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: 0.85,
                        transition: "all 0.4s ease",
                        "&:hover": {
                          opacity: 1,
                          transform: "translateY(-4px)",
                        },
                      }}
                    >
                      <img
                        src={logo}
                        alt={`${name} logo`}
                        width={customWidth || 150}
                        style={{
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "none" },
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                animation: "marquee 15s linear infinite",
                "@keyframes marquee": {
                  "0%": { transform: "translateX(0)" },
                  "100%": { transform: "translateX(-20%)" },
                },
                gap: 4,
                "& > *": {
                  flex: "0 0 auto",
                },
                width: "max-content",
              }}
            >
              {[
                ...trustedUsers,
                ...trustedUsers,
                ...trustedUsers,
                ...trustedUsers,
                ...trustedUsers,
              ].map(({ logo, name, link, customWidth }, index) => (
                <Link
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    minWidth: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                  height="100%"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      opacity: 0.85,
                    }}
                  >
                    <img
                      src={logo}
                      alt={`${name} logo`}
                      width={customWidth || 120}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>

      {/* FAQ Section */}
      <Box
        id="faq"
        sx={{
          py: { xs: 6, md: 10 },
          backgroundColor: "background.paper",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 4, md: 8 }}>
            {/* FAQ Content - Left side on desktop */}
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 2, md: 1 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "500px", 
                  mx: "auto",
                  px: { xs: 0, md: 2 }, 
                }}
              >
                {faqData.map((faq, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      mb: 2,
                      boxShadow: "none",
                      "&:before": {
                        display: "none",
                      },
                      "& .MuiAccordionSummary-root": {
                        borderBottom: "1px solid grey.300",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMore
                          sx={{
                            color: "brand.main",
                            transform: "rotate(0deg)",
                            transition: "transform 0.3s",
                            "&.Mui-expanded": {
                              transform: "rotate(-180deg)",
                            },
                          }}
                        />
                      }
                      sx={{
                        py: 2,
                        "& .MuiAccordionSummary-content": {
                          margin: 0,
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "600",
                          color: "text.primary",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ pt: 2, pb: 3 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "text.secondary",
                          lineHeight: 1.6,
                          fontWeight: "500",
                        }}
                      >
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Grid>

            {/* Gradient Box - Right side on desktop */}
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  background:
                    "linear-gradient(180deg, #20DDAE 0%, rgba(141, 255, 221, 0) 100%)",
                  borderRadius: "24px",
                  height: { xs: "300px", md: "500px" },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "2rem", md: "3rem" },
                    fontWeight: "800",
                    mb: { xs: 3, md: 6 },
                    textAlign: "center",
                  }}
                >
                  Frequently asked questions
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default HomeContentV1;