import React, { useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";

const testimonials = [
  {
    name: "Yashwanth",
    testimonial:
      "Crobo has a user-friendly interface, robust security measures, and real-time exchange rate updates. Very responsive customer support. The app's efficiency and security make it a promising choice for hassle-free financial transactions.",
  },
  {
    name: "Satvik",
    testimonial:
      "I just tried to send money using Crobo! It was instant! This is amazing! Love this service! Will definitely spread the word! Keep up the great work!",
  },
  {
    name: "Karthik",
    testimonial: "Will recommend the app to my friends as well, all the best!",
  },
  {
    name: "Keerthika",
    testimonial:
      "I just sent money using this app. The onboarding process was seamless and was done in minutes. Transfer was super quick and they give you a better rate than google. Love it!!! Would definitely recommend it.",
  },
  {
    name: "Madhu",
    testimonial:
      "I'm really impressed by the transfer speeds. It takes more time for a direct deposit to different accounts in the US. This is equivalent to Zelle/UPI for an international transfer.",
  },
  {
    name: "Sanjay",
    testimonial:
      "The process was incredibly user-friendly, making it easy for me to send money to my family in India. What really stood out was the speed of the transaction - it was almost instantaneous.",
  },
];

const TestimonialsCarouselV1 = () => {
  const scrollContainerRef = useRef(null);
  const carouselWidth = 300;

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
      
      // If at the beginning, jump to the last item without animation
      if (scrollLeft === 0) {
        scrollContainerRef.current.scrollLeft = scrollWidth - clientWidth;
      } else {
        scrollContainerRef.current.scrollBy({
          left: -carouselWidth,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
      
      // If at the end, jump to the first item without animation
      if (scrollLeft + clientWidth >= scrollWidth - 1) {
        scrollContainerRef.current.scrollLeft = 0;
      } else {
        scrollContainerRef.current.scrollBy({
          left: carouselWidth,
          behavior: "smooth",
        });
      }
    }
  };
  

  return (
    <Box sx={{ position: "relative", py: 4 }}>
      <Typography 
        variant="h2" 
        textAlign="center" 
        sx={{ 
          fontSize: { xs: '2rem', md: '3rem' },
          fontWeight: '800',
          color: 'text.primary',
          mb: 4
        }}
      >
        Customers are loving CROBO
      </Typography>

      <Box sx={{ position: 'relative', maxWidth: '1200px', mx: 'auto' }}>
        {/* Testimonials Container */}
        <Box
          ref={scrollContainerRef}
          sx={{
            display: "flex",
            overflow: "hidden",
            scrollSnapType: "x mandatory",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
            gap: { xs: 2, md: 4 },
            px: { xs: 2, md: 4 }
          }}
        >
          {testimonials.map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                minWidth: { xs: "calc(100% - 32px)", md: "500px" },
                scrollSnapAlign: "center",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                borderRight: index !== testimonials.length - 1 ? '1px solid' : 'none',
                borderColor: (theme) => theme.palette.custom.lightGray,
                pr: { xs: 2, md: 4 },
                margin: '0 auto',
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '1rem', md: '1.25rem' },
                  color: 'text.secondary',
                  mb: 4,
                  lineHeight: 1.6,
                  maxWidth: { xs: '280px', md: '360px' },
                  margin: '0 auto',
                  fontWeight: "500",
                }}
              >
                "{testimonial.testimonial}"
              </Typography>

              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                width: '100%',
                mt: 2
              }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    fontWeight: 600,
                    color: 'text.primary',
                    textAlign: 'center'
                  }}
                >
                  {testimonial.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Navigation Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
          <IconButton
            onClick={scrollLeft}
            sx={{
              width: 40,
              height: 40,
              border: '1px solid',
              borderColor: 'brand.main',
              color: 'brand.main',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'white',
                borderColor: 'brand.dark',
              },
              mx: 2
            }}
          >
            <WestOutlinedIcon sx={{ fontSize: 20 }} />
          </IconButton>

          <IconButton
            onClick={scrollRight}
            sx={{
              width: 40,
              height: 40,
              border: '1px solid',
              borderColor: 'brand.main',
              color: 'brand.main',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'white',
                borderColor: 'brand.dark',
              },
              mx: 2
            }}
          >
            <EastOutlinedIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialsCarouselV1;