import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import NewStyledCard from "./newStyledCard";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';

const TeamMemberCard = ({ name, role, imageUrl, linkedin, sx = {} }) => {
  return (
    <NewStyledCard
      sx={{
        width: '100%',
        maxWidth: { xs: '280px', sm: '345px' },
        height: '100%',
        padding: { xs: 2, sm: 3 }, // Reduced padding
        mx: 0, // Remove horizontal margin
        ...sx
      }}
    >
      <Box
        sx={{
          width: { xs: '140px', sm: '160px' },
          height: { xs: '140px', sm: '160px' },
          borderRadius: '50%',
          overflow: 'hidden',
          mb: 3,
          mx: 'auto',
          border: '2px solid black',
          position: 'relative'
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={`${name}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: '#999', fontSize: '24px' }}>?</Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          mb: 1,
          flexWrap: 'wrap', // Allows name to wrap if too long
          textAlign: 'center',
          minHeight: { sm: '64px' }, // Add fixed height for name container on larger screens
          alignItems: 'center' // Center content vertically
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "1.2rem", sm: "1.1rem" },
            fontWeight: "650",
            color: "#1a1a1a",
            lineHeight: 1.2,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
        >
          {name}
        </Typography>
        {linkedin && (
          <IconButton
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#0077b5',
              padding: '4px',
              flexShrink: 0, // Prevent LinkedIn icon from shrinking
              '&:hover': {
                backgroundColor: 'rgba(0, 119, 181, 0.1)',
              },
            }}
          >
            <LinkedInIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Typography
        variant="body2"
        sx={{
          color: "#666",
          fontSize: { xs: "1rem", sm: "0.9rem" },
          fontWeight: "500",
          textAlign: 'center',
        }}
      >
        {role}
      </Typography>
    </NewStyledCard>
  );
};

TeamMemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  linkedin: PropTypes.string,
  sx: PropTypes.object,
};

export default TeamMemberCard;