import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Link,
  IconButton,
  useTheme,
  Button,
} from "@mui/material";
import brandLogo from "../assets/Crobo_Full.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import { useLocation } from "react-router-dom";
import { isWeb } from "../utils/platform";
import { App } from "@capacitor/app";
import { getUTMParams } from "../helper/helper";

const FooterV1 = ({ hideDownload = false, hideLogo = false }) => {
  const location = useLocation();
  const theme = useTheme();
  const [version, setVersion] = useState();

  useEffect(() => {
    if (!isWeb()) {
      App.getInfo()
        .then((info) => {
          setVersion(info.version);
        })
      .catch((e) => {
        console.log(e);
      });
    }
    const utmParams = getUTMParams();
    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem("utmParams", JSON.stringify(utmParams));
    }
  }, []);

  const navItems = [
    { label: "Home", path: "/" },
    { label: "Start transfer", path: "/pin" },
    { 
      label: "How it works", 
      path: "/", 
      onClick: (e) => {
        e.preventDefault();
        const element = document.getElementById('how-it-works');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.location.href = '/#how-it-works';
        }
      }
    },
    { label: "About us", path: "/about-us" },
  ];

  const handleOpenChat = () => {
    // Add chat functionality here
    console.log("Open chat");
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.paper
            : theme.palette.background.dark,
      }}
    >
      {/* Desktop Layout */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {/* Top Navigation Bar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "1200px",
            margin: "0 auto",
            px: 5,
            py: 3,
          }}
        >
          {!hideLogo && (
            <img src={brandLogo} alt="CROBO Logo" style={{ height: "30px" }} />
          )}

          <Box sx={{ display: "flex", gap: 4 }}>
            {navItems.map((item, index) => (
              <Link
                key={index}
                href={item.path}
                onClick={item.onClick}
                sx={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    color:
                      location.pathname === item.path
                        ? theme.palette.brand.main
                        : theme.palette.text.invert,
                    fontSize: "1rem",
                    fontWeight: location.pathname === item.path ? 600 : 400,
                    "&:hover": { color: theme.palette.brand.dark },
                    transition: "color 0.2s ease",
                  }}
                >
                  {item.label}
                </Typography>
              </Link>
            ))}
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            {[
              {
                icon: LinkedInIcon,
                url: "https://www.linkedin.com/company/crobo-money",
              },
              { icon: TwitterIcon, url: "https://www.twitter.com/crobo_money" },
              {
                icon: InstagramIcon,
                url: "https://www.instagram.com/crobo_money",
              },
            ].map((social, index) => (
              <IconButton
                key={index}
                href={social.url}
                target="_blank"
                sx={{
                  color: "white",
                  backgroundColor: "#1e293b",
                  "&:hover": { backgroundColor: "secondary.light" },
                }}
              >
                <social.icon />
              </IconButton>
            ))}
          </Box>
        </Box>

        {/* Customer Care Section - Desktop */}
        <Box
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            px: 5,
            py: 4,
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              justifyContent: "center",
            }}
          >
            {[
              {
                icon: HeadsetMicIcon,
                text: "Contact Support",
                action: handleOpenChat,
                isButton: true,
              },
              {
                icon: EmailIcon,
                text: "care@crobo.money",
                href: "mailto:care@crobo.money",
              },
              {
                icon: HelpIcon,
                text: "Support Center",
                href: "/support",
              },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <item.icon
                  sx={{
                    color: theme.palette.text.invert,
                    opacity: 0.7,
                    fontSize: 20,
                  }}
                />
                {item.isButton ? (
                  <Button
                    onClick={item.action}
                    sx={{
                      color: theme.palette.text.invert,
                      opacity: 0.7,
                      p: 0,
                      minWidth: "auto",
                      textTransform: "none",
                      fontSize: "0.95rem",
                      fontWeight: 400,
                      "&:hover": {
                        color: theme.palette.text.invert,
                        opacity: 1,
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    {item.text}
                  </Button>
                ) : (
                  <Link
                    href={item.href}
                    sx={{
                      color: theme.palette.text.invert,
                      opacity: 0.7,
                      textDecoration: "none",
                      fontSize: "0.95rem",
                      "&:hover": {
                        opacity: 1,
                        textDecoration: "none",
                      },
                    }}
                  >
                    {item.text}
                  </Link>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Mobile Layout */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          maxWidth: "100%",
          margin: "0 auto",
          px: 2,
          py: 3,
        }}
      >
        {/* Logo Section */}
        <Box sx={{ mb: 4, textAlign: "center" }}>
          {!hideLogo && (
            <img
              src={brandLogo}
              alt="CROBO Logo"
              style={{ height: "24px", marginBottom: "20px" }}
            />
          )}
        </Box>

        {/* Customer Care Section - Mobile */}
        <Box
          sx={{
            width: "100%",
            mb: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {[
            {
              icon: HeadsetMicIcon,
              text: "Contact Support",
              action: handleOpenChat,
              isButton: true,
            },
            {
              icon: EmailIcon,
              text: "care@crobo.money",
              href: "mailto:care@crobo.money",
            },
            {
              icon: HelpIcon,
              text: "Support Center",
              href: "/support",
            },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
              }}
            >
              <item.icon
                sx={{
                  color: theme.palette.text.invert,
                  opacity: 0.7,
                  fontSize: 20,
                }}
              />
              {item.isButton ? (
                <Button
                  onClick={item.action}
                  sx={{
                    color: theme.palette.text.invert,
                    opacity: 0.7,
                    p: 0,
                    minWidth: "auto",
                    textTransform: "none",
                    fontSize: "0.95rem",
                    fontWeight: 400,
                    "&:hover": {
                      color: theme.palette.text.invert,
                      opacity: 1,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {item.text}
                </Button>
              ) : (
                <Link
                  href={item.href}
                  sx={{
                    color: theme.palette.text.invert,
                    opacity: 0.7,
                    textDecoration: "none",
                    fontSize: "0.95rem",
                    "&:hover": {
                      opacity: 1,
                      textDecoration: "none",
                    },
                  }}
                >
                  {item.text}
                </Link>
              )}
            </Box>
          ))}
        </Box>

        {/* Navigation Links */}
        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
              width: "100%",
            }}
          >
            {navItems.map((item, index) => (
              <Link
                key={index}
                href={item.path}
                onClick={item.onClick}
                sx={{
                  textDecoration: "none",
                  textAlign: "left",
                }}
              >
                <Typography
                  sx={{
                    color:
                      location.pathname === item.path
                        ? theme.palette.brand.main
                        : theme.palette.text.invert,
                    fontSize: "0.9rem",
                    fontWeight: location.pathname === item.path ? 600 : 400,
                    "&:hover": { color: theme.palette.brand.dark },
                  }}
                >
                  {item.label}
                </Typography>
              </Link>
            ))}
          </Box>
        </Box>

        {/* Social Icons */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 3, mb: 3 }}>
          {[
            {
              icon: LinkedInIcon,
              url: "https://www.linkedin.com/company/crobo-money",
            },
            { icon: TwitterIcon, url: "https://www.twitter.com/crobo_money" },
            {
              icon: InstagramIcon,
              url: "https://www.instagram.com/crobo_money",
            },
          ].map((social, index) => (
            <IconButton
              key={index}
              href={social.url}
              target="_blank"
              size="small"
              sx={{
                color: theme.palette.text.invert,
                backgroundColor: "#1e293b",
                "&:hover": {
                  backgroundColor: theme.palette.custom.charcoal,
                },
                padding: 1,
              }}
            >
              <social.icon fontSize="small" />
            </IconButton>
          ))}
        </Box>
      </Box>

      {/* Copyright - Shows on both layouts */}
      <Box
        sx={{
          borderTop: "1px solid rgba(255,255,255,0.1)",
          py: 2,
          px: 2,
          textAlign: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ 
            mb: 1,
            fontSize: { xs: "0.75rem", md: "0.875rem" },
            color: theme.palette.text.invert
          }}
        >
          © Copyright {new Date().getFullYear()}, All rights reserved by Crobo
        </Typography>
        <Typography textAlign={"center"} variant="body2" color={theme.palette.text.invert}>
          Built by Ex-Amazonians v{version ? version : "1.0.20"}
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterV1;
