import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./reducers/userReducer";
import transactionReducer from "./reducers/transactionReducer";
import exchangeRateReducer from "./reducers/exchangeRateReducer";
import resourceReducer from "./reducers/resourceReducer";
import preRegisterReducer from "./reducers/preRegisterReducer";
import campaignsReducer from "./reducers/campaignsReducer";

const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    transaction: transactionReducer,
    exchangeRate: exchangeRateReducer,
    resource: resourceReducer,
    preRegister: preRegisterReducer,
    campaigns: campaignsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production", 
});

export default store;
