import { Box, Typography, Button } from '@mui/material';
import React, {} from 'react';
import { errorAnalytics } from '../helper/helper';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    // Track error in analytics
    errorAnalytics('React Runtime Error', {
      error: error.toString(),
      componentStack: errorInfo?.componentStack,
      location: window.location.href
    });
  }

  render() {
    if(this.state.hasError){
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ p: 3, textAlign: 'center', minHeight: '50vh' }}
        >
          <Typography variant="h5" gutterBottom color="error">
            Oops! Something went wrong.
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {this.state.error?.message || 'An unexpected error occurred'}
          </Typography>

          <Button
            variant="contained"
            color="brand"
            onClick={() => {
              window.location.reload();
            }}
            sx={{ mt: 2 }}
          >
            <Typography color="white">Reload Page</Typography>
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}
