import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../helper/helper";
import { backend } from "./userReducer";

export const fetchTransactions = createAsyncThunk(
  "history/fetchTransactions",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "get",
        url: `${backend}/transaction/history?singleHistory=true&page=${params.page}&limit=${params.limit}`,
        headers: {
          "x-auth-token": token,
        },
      };
      const { data } = await axios.request(config);
      return data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const transactionById = createAsyncThunk(
  "details/transactionById",
  async (transid, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "get",
        url: `${backend}/transaction/history/${transid}`,
        headers: {
          "x-auth-token": token,
        },
      };
      const { data } = await axios.request(config);
      return data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const sendPayment = createAsyncThunk(
  "remit",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: `${backend}/transaction/sendPayment`,
        data: {
          countryCode: "+91",
          destinationCurrency: params.destinationCurrency,
          phoneNumber: params.phoneNumber,
          sourceCurrency: params.sourceCurrency,
          source_fiat_amount: params.amount,
          purpose: params.purpose,
          is_instant: params.is_instant
        },
        headers: {
          "x-auth-token": token,
        },
      };
      const { data } = await axios.request(config);
      return data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transactions: [],
    page: 1,
    perPage: 0,
    totalPage: 0,
    loading: false,
    transaction: null,
    sendPayment: null,
    isTransactionComplete: false,
    error: null,
  },
  reducers: {
    resetTransaction: (state) => {
      state.isTransactionComplete = false;
      state.error = null
      state.sendPayment = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.data;
        state.page = action.payload.page;
        state.perPage = action.payload.perPage;
        state.error = action.payload.message;
        state.totalPage = action.payload.totalPage;
        state.loading = false;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(transactionById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(transactionById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.transaction = action.payload.data;
      })
      .addCase(transactionById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(sendPayment.pending, (state) => {
        state.loading = true;
        state.isTransactionComplete = false;
        state.error = null;
        state.sendPayment = null
      })
      .addCase(sendPayment.rejected, (state, action) => {
        state.isTransactionComplete = true;
        state.loading = false;
        state.error = action.payload.message;
        state.sendPayment = { message: action.payload.message };
      })
      .addCase(sendPayment.fulfilled, (state, action) => {
        state.isTransactionComplete = true;
        state.loading = false;
        state.error = null;
        state.sendPayment = action.payload;
      });
  },
});

export const { resetTransaction } = transactionSlice.actions;
export default transactionSlice.reducer;
