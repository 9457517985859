import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const Banner = ({ navigate, profile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        overflowX: "auto",
        pb: 1,
        mx: { xs: -1, md: -2 }, // Smaller margin on mobile
        px: { xs: 1, md: 2 }, // Smaller padding on mobile
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        width: { xs: "calc(100% + 8px)", md: "calc(100% + 16px)" }, // Smaller width compensation on mobile
      }}
    >
      {/* Promo Card */}
      {/* <Card
        sx={{
          background: (theme) => theme.palette.custom.gradients.secondary,
          color: "text.invert",
          position: "relative",
          overflow: "hidden",
          minWidth: { xs: "90%", md: "90%" },
          flex: { xs: "0 0 90%", md: "0 0 90%" },
          "&::before": {
            content: '""',
            position: "absolute",
            right: "-10%",
            top: "-50%",
            width: "300px",
            height: "300px",
            background:
              "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
            borderRadius: "50%",
            transform: "rotate(-45deg)",
            pointerEvents: "none",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            left: "60%",
            bottom: "-30%",
            width: "200px",
            height: "200px",
            background:
              "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
            transform: "rotate(30deg)",
            pointerEvents: "none",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: "5%",
            top: "20%",
            width: "120px",
            height: "120px",
            border: "2px solid rgba(255,255,255,0.1)",
            borderRadius: "20px",
            transform: "rotate(45deg)",
            pointerEvents: "none",
          }}
        />
        <CardContent sx={{ position: "relative", zIndex: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={7}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  mb: 0.5,
                }}
              >
                Republic Day Special! <br /> Get 50 paise more on every transaction, Hurry up!
              </Typography>
              <Typography variant="body1" sx={{ opacity: 0.9 }}>
                Extended till 31st January
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              href="/condition2"
              target="_blank"
              size="small"
              sx={{
                color: (theme) => theme.palette.custom.mint,
                fontSize: "0.75rem",
                textDecoration: "underline",
                "&:hover": {
                  bgcolor: "rgba(255,215,0,0.1)",
                },
              }}
            >
              View Terms & Conditions
            </Button>
          </Box>
        </CardContent>
      </Card> */}

      {/* Referral Card */}
      <Card
        sx={{
          background: (theme) => theme.palette.custom.gradients.primary,
          color: "text.invert",
          position: "relative",
          overflow: "hidden",
          minWidth: { xs: "100%", md: "100%" },
          flex: { xs: "0 0 100%", md: "0 0 100%" },
          cursor: "pointer",
          transition: "transform 0.3s ease",
          "&::before": {
            content: '""',
            position: "absolute",
            right: "-20%",
            top: "-30%",
            width: "200px",
            height: "200px",
            background: `url('data:image/svg+xml,${encodeURIComponent(
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgba(255,255,255,0.1)" d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z"/></svg>'
            )}')`,
            backgroundRepeat: "no-repeat",
            transform: "rotate(-45deg)",
            opacity: 0.1,
            pointerEvents: "none",
            zIndex: 0,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            left: "10%",
            bottom: "-20%",
            width: "150px",
            height: "150px",
            background: `url('data:image/svg+xml,${encodeURIComponent(
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgba(255,255,255,0.1)" d="M15 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zM3 12c0-2.61 1.67-4.83 4-5.65V4.26C3.55 5.15 1 8.27 1 12s2.55 6.85 6 7.74v-2.09c-2.33-.82-4-3.04-4-5.65z"/></svg>'
            )}')`,
            backgroundRepeat: "no-repeat",
            transform: "rotate(30deg)",
            opacity: 0.1,
            pointerEvents: "none",
            zIndex: 0,
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: "15%",
            top: "15%",
            width: "60px",
            height: "60px",
            border: "2px solid rgba(255,255,255,0.1)",
            borderRadius: "12px",
            transform: "rotate(45deg)",
            pointerEvents: "none",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            left: "10%",
            bottom: "20%",
            width: "40px",
            height: "40px",
            border: "2px solid rgba(255,255,255,0.1)",
            borderRadius: "8px",
            transform: "rotate(-15deg)",
            pointerEvents: "none",
          }}
        />
        <CardContent
          sx={{
            position: "relative",
            zIndex: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  mb: 0.5,
                }}
              >
                Share & Get Rewarded!
              </Typography>
              <Typography variant="body1" sx={{ opacity: 0.9, mb: 2 }}>
                Invite friends to Crobo and earn rewards
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                  p: 1.5,
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" sx={{ flex: 1 }}>
                  {profile?.referral_code || "Loading..."}
                </Typography>
                <Button
                  startIcon={<ContentCopyIcon />}
                  variant="contained"
                  onClick={() => {
                    navigator.clipboard.writeText(profile?.referral_code);
                  }}
                  sx={{
                    bgcolor: "text.invert",
                    color: "brand.main",
                    "&:hover": {
                      bgcolor: "rgba(255, 255, 255, 0.9)",
                    },
                  }}
                >
                  Copy
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              onClick={() => navigate("/referrals")}
              endIcon={<KeyboardDoubleArrowRightIcon />}
              sx={{
                color: "text.invert",
                fontSize: "0.75rem",
                textDecoration: "underline",
                "&:hover": {
                  bgcolor: "rgba(255,215,0,0.1)",
                },
              }}
            >
              Referrals
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
