// Auth.js
import React, { useCallback, useEffect, useState } from "react";
import { Container, Button, Box, Typography, Grid } from "@mui/material";
import {
  clearMessage,
  markPinDisable,
  verifyPin,
} from "../../reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import ResetPin from "./resetPin";
import { auth, signInWithPhoneNumber } from "../../service/firebase";
import CroboContainer from "../../components/croboContainer";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import OTPInput from "react-otp-input";
import { isWeb } from "../../utils/platform";
import {
  analytics,
  handlePhoneNumberError,
  initializeRecaptcha,
  triggerHaptic
} from "../../helper/helper";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import Heading from "../../components/heading";

const VerifyPin = ({ sendOtpAndResetPin }) => {
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { error, loading, pinVerified } = useSelector(
    (state) => state.userAuth
  );

  const handlePin = useCallback(async () => {
    dispatch(verifyPin(pin));
  }, [pin, dispatch]);
  
  useEffect(() => {
    if (pin.length === 4) {
      setLoader(true);
      handlePin();
    }
  }, [pin, handlePin]);

  useEffect(() => {
    if (pinVerified) {
      const handleSuccess = async () => {
        await triggerHaptic('heavy');
        if(isWeb()){
          navigate("/transaction");
        }else{
          navigate("/app-home");
        }
        dispatch(markPinDisable());
      };
      dispatch(clearMessage());
      handleSuccess();
    }
    if (error) {
      setLoader(false);
    }
  }, [navigate, pinVerified, dispatch, error]);
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Heading title="Enter Pin" />
      </Box>

      <Box>
        <OTPInput
          value={pin}
          label="PIN"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setPin}
          numInputs={4}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                height: "40px",
                fontSize: 18,
                borderRadius: "8px",
                border: "1px solid #E0E0E0",
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        {error && (
          <Typography textAlign={"center"} sx={{ color: "red" }}>
            {error}
          </Typography>
        )}
      </Box>
      <Box paddingY={2}>
        {loading || loader ? (
          <Loader />
        ) : (
          <Button
            color="brand"
            onClick={handlePin}
            variant="contained"
            fullWidth
            size="large"
            sx={{
              py: 1.5,
              borderRadius: 2,
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={pin.length !== 4 ? true : false}
          >
            <Typography variant="h6">Proceed</Typography>
          </Button>
        )}
      </Box>

      <Grid display={"flex"} justifyContent={"end"}>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            sendOtpAndResetPin();
          }}
          sx={{
            textTransform: "none",
          }}
        >
          Reset pin
        </Button>
      </Grid>
    </Container>
  );
};

const Pin = () => {
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    const phone = localStorage.getItem("phone");
    setPhone(phone);
    initializeRecaptcha();
  }, []);

  const sendOtp = async () => {
    try {
      setLoader(true);
      analytics.track("OTP Requested", { phone: `+1${phone}` });

      if (cooldown) {
        setError("Please wait a moment before retrying.");
        return;
      }
      if (isWeb()) {
        // Web implementation
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          `${phone}`,
          appVerifier
        );
        setOtpSent(true);
        setLoader(false);
        setIsResendDisabled(true);
        setCooldown(true);
        analytics.track("OTP Sent", { phone });
        setConfirmationResult(confirmationResult);
      } else {
        // Native implementation
        await FirebaseAuthentication.signInWithPhoneNumber({
          phoneNumber: `${phone}`,
        });

        // Add listener for phoneCodeSent event
        await FirebaseAuthentication.addListener("phoneCodeSent", (event) => {
          setConfirmationResult(event.verificationId);
          setOtpSent(true);
          setLoader(false);
          setIsResendDisabled(true);
          setCooldown(true);
          analytics.track("OTP Sent", { phone });
        });
      }
    } catch (error) {
      handlePhoneNumberError(error, setError, setLoader, setCooldown, phone);
    }
  };

  const sendOtpAndResetPin = async () => {
    await sendOtp();
  };
  return (
    <CroboContainer>
      {otpSent ? (
        <ResetPin
          confirmationResult={confirmationResult}
          error={error}
          phone={phone}
          setCooldown={setCooldown}
          setError={setError}
          setLoader={setLoader}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          loader={loader}
        />
      ) : (
        <VerifyPin sendOtpAndResetPin={sendOtpAndResetPin} />
      )}
    </CroboContainer>
  );
};

export default Pin;
