import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, setAuth } from '../reducers/userReducer';
import { handleError } from './helper';


export const nonProtectedRoute = [
  "/login",
  "/",
  "/signup",
  "/condition",
  "/condition2",
  "/support",
  "/about-us",
  "/privacy-policy",
  "/tnc",
  "/link/phone",
  "/.well-known/assetlinks.json",
];

const useAuthStatus = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(setAuth(false));
        setLoading(false);
        dispatch(logout());
        if (!nonProtectedRoute.includes(window.location.pathname)) {
          navigate('/login');
        }
        return;
      }

      try {
        await axios.get('https://lunar.getbitmoneyapp.com/users/riskScore', {
          headers: {
            'x-auth-token': token
          }
        });
        
        dispatch(setAuth(true));
        setError(null);
      } catch (err) {
        handleError(err);
        navigate("/login");
        dispatch(setAuth(false));
        if (err.response?.status === 401) {
          dispatch(setAuth(false));
          setError('Unauthorized');
          dispatch(logout());
          if (!nonProtectedRoute.includes(window.location.pathname)) {
            navigate('/login');
          }
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };
    checkAuthStatus();
  }, [dispatch, navigate]);

  return { loading, error };
};

export default useAuthStatus; 