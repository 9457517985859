// Auth.js
import React, { useState } from "react";
import { Container, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { isWeb } from "../../utils/platform";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { analytics, handlePhoneNumberError } from "../../helper/helper";
import Heading from "../../components/heading";

const ResetPin = ({confirmationResult,error, phone, setCooldown, setError, setLoader}) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate()
  const handleVerifyOtp = async () => {
     setLoader(true);
     analytics.track("OTP Verification Attempt", { phone, otp });
   
    try {
      if (isWeb()) {
        await confirmationResult.confirm(otp);
      } else {
        // Native implementation
       await FirebaseAuthentication.confirmVerificationCode({
          verificationId: confirmationResult,
          verificationCode: otp,
        });
      }
      navigate("/pin/set");
      analytics.track("OTP Verification reset pin Success", { phone });
    } catch (error) {
      handlePhoneNumberError(error, setError, setLoader, setCooldown, phone);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Heading title="ENTER OTP" sx={{ mt: 2 }} />
      </Box>

      <Box paddingY={2}>
        <OTPInput
          value={otp}
          label="OTP"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "1px solid #E0E0E0",
                height: "40px",
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
        {error && (
          <Typography textAlign={"center"} sx={{ color: "red" }}>
            {error}
          </Typography>
        )}
        <Box paddingY={2}>
          <Button
            variant="contained"
            color="brand"
            onClick={handleVerifyOtp}
            fullWidth
            margin="normal"
            size="large"
          >
            Verify OTP
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPin;
