import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import { Avatar, Box, Container, IconButton, Typography, Alert, Collapse, Grid, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { frequentContact, remitStatusUpdate } from "../../reducers/userReducer";
import { analytics } from "../../helper/helper"; // Import analytics
import { useNavigate } from "react-router-dom";
import FrequentContactCard from "../../components/frequentContactsCard";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CampaignIcon from "@mui/icons-material/Campaign";
import HistoryIcon from "@mui/icons-material/History";
import Heading from "../../components/heading";
import PushPinIcon from "@mui/icons-material/PushPin";
import { isWeb } from "../../utils/platform";
import Skeleton from "@mui/material/Skeleton";
import Loader from "../../components/loader";

function Transactions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pinnedContacts, setPinnedContacts] = useState([]);
  const [error, setError] = useState("");
  
  const { frequentContacts, loading, bank, bankVerification, kyc, token, userReady } =
    useSelector((state) => {
      const data = state.userAuth;
      return data;
    });

  useEffect(() => {
    // Load pinned contacts from localStorage
    const savedPinnedContacts = localStorage.getItem('pinnedContacts');
    if (savedPinnedContacts) {
      setPinnedContacts(JSON.parse(savedPinnedContacts));
    }
  }, []);

  useEffect(() => {
    dispatch(remitStatusUpdate({ token, isRefresh: true }));

    if (bank != 1 || bankVerification != 1 || (kyc != 1 && kyc != 5)) {
      navigate("/onboarding");
      analytics.track(
        "Redirected to Onboarding due to Invalid Bank/Verification/KYC"
      ); 
    } else {
      dispatch(frequentContact());
      analytics.track("Frequent Contacts Loaded"); 
    }
  }, [dispatch, bank, bankVerification, kyc, navigate, token]);

  const handlePinContact = (contact) => {
    const newPinnedContacts = [...pinnedContacts];
    const contactIndex = newPinnedContacts.findIndex(p => p.user_id === contact.user_id);
    
    if (contactIndex === -1) {
      // Pin the contact if not already pinned and less than 4 pins
      if (newPinnedContacts.length < 4) {
        newPinnedContacts.push(contact);
        analytics.track("Contact Pinned", { contactId: contact.user_id });
        setError(""); // Clear any existing error
      } else {
        setError("You can only pin up to 4 contacts. Please unpin a contact before adding a new one.");
        return; // Don't proceed with pinning
      }
    } else {
      // Unpin the contact
      newPinnedContacts.splice(contactIndex, 1);
      analytics.track("Contact Unpinned", { contactId: contact.user_id });
      setError(""); // Clear any existing error
    }
    
    setPinnedContacts(newPinnedContacts);
    localStorage.setItem('pinnedContacts', JSON.stringify(newPinnedContacts));
  };

  const isPinned = (contact) => {
    return pinnedContacts.some(p => p.user_id === contact.user_id);
  };

  return (
    <CroboContainer>
    {userReady ? (
       <Container>
        <Collapse in={!!error}>
          <Alert
            severity="error"
            sx={{
              mt: 2,
              mb: 1,
            }}
            onClose={() => setError("")}
          >
            {error}
          </Alert>
        </Collapse>
        <Box
          mt={2}
          sx={{
           display: { xs: "none", md: "flex" },
            justifyContent: "space-around",
          }}
        >
          {loading ? (
            <>
              {[1, 2, 3, 4].map((item) => (
                <ActionButtonSkeleton key={item} />
              ))}
            </>
          ) : (
            <>
              <RoundedButton
                icon={<AddIcon sx={{ fontSize: 30 }} />}
                buttonName="Add new recipient"
                onClick={() => {
                  navigate(`/transaction/create/new`);
                }}
              />
              <RoundedButton
                icon={<EditIcon sx={{ fontSize: 30 }} />}
                buttonName="Manage recipient"
                onClick={() => {
                  navigate("/manage-recipients");
                }}
              />
              <RoundedButton
                icon={<CampaignIcon sx={{ fontSize: 30 }} />}
                buttonName={`Refer and earn`}
                onClick={() => {
                  navigate("/referrals");
                }}
              />
              <RoundedButton
                icon={<HistoryIcon sx={{ fontSize: 30 }} />}
                buttonName="Transaction history"
                onClick={() => {
                  navigate("/transaction/history");
                }}
              />
            </>
          )}
        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          {loading ? (
            <MobileActionSkeleton />
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <Box
                  elevation={0}
                  sx={{
                    py: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                  }}
                  onClick={() => navigate(`/transaction/create/new`)}
                >
                  <Avatar
                    sx={{
                      bgcolor: "brand.main",
                      width: 48,
                      height: 48,
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                      fontSize: "1.2rem",
                      margin: "0 auto",
                      mb: 1,
                    }}
                  >
                    <AddIcon color="white" />
                  </Avatar>
                  <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                    Add Recipient
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  elevation={0}
                  sx={{
                    py: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                  }}
                  onClick={() => navigate("/manage-recipients")}
                >
                  <Avatar
                    sx={{
                      bgcolor: "brand.main",
                      width: 48,
                      height: 48,
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                      fontSize: "1.2rem",
                      margin: "0 auto",
                      mb: 1,
                    }}
                  >
                    <EditIcon color="white" />
                  </Avatar>
                  <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                    Manage recipient
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>

        <Box sx={{ paddingTop: 0 }}>
          <Heading title="Recipients" sx={{ mt: 2 }} />
          {loading ? (
            <Box sx={{ py: "10px", pb: "20px" }}>
              {[1, 2, 3].map((item) => (
                <RecipientCardSkeleton key={item} />
              ))}
            </Box>
          ) : (
            <>
              {frequentContacts.length > 0 ? (
                <Box
                  display={"flex"}
                  overflow={"scroll"}
                  flexDirection={"column"}
                  sx={{
                    py: "10px",
                    pb: "20px",
                    overflowY: "auto",
                  }}
                >
                  {/* Show pinned contacts first */}
                  {frequentContacts
                    .filter((contact) => isPinned(contact))
                    .map((contact) => (
                      <Box
                        key={contact.user_id}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        <FrequentContactCard
                          id={contact.user_id}
                          phone={contact.phone}
                          nickName={
                            contact.unverified_user_name || contact.user_name
                          }
                          name={contact.user_name}
                          email={contact.email}
                          accountNumber={
                            contact?.bank_details?.bankAccountNumber
                          }
                          isPinned={true}
                          onPin={() => handlePinContact(contact)}
                        />
                      </Box>
                    ))}

                  {/* Show non-pinned contacts */}
                  {frequentContacts
                    .filter((contact) => !isPinned(contact))
                    .map((contact) => (
                      <Box
                        key={contact.user_id}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        <FrequentContactCard
                          id={contact.user_id}
                          phone={contact.phone}
                          nickName={
                            contact.unverified_user_name || contact.user_name
                          }
                          name={contact.user_name}
                          email={contact.email}
                          isPinned={false}
                          onPin={() => handlePinContact(contact)}
                        />
                      </Box>
                    ))}
                </Box>
              ) : (
                <Alert
                  severity="info"
                  sx={{
                    bgcolor: "brandLight.main",
                    color: "brand.main",
                    "& .MuiAlert-icon": {
                      color: "brand.main",
                    },
                  }}
                >
                  No recipients found. Add new recipients to get started.
                </Alert>
              )}
            </>
          )}
        </Box>
      </Container>
    ) : (
     <Loader/>
    )}
    </CroboContainer>
  );
}


export const RoundedButton = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
      }}
    >
      <IconButton
        onClick={props.onClick}
        sx={{ padding: 0, width: "50px", height: "50px" }}
      >
        <Avatar
          variant="circular"
          sx={{
            height: "50px",
            width: "50px",
            bgcolor: "brand.main",
            ":hover": {
              bgcolor: "brand.dark",
            },
            fontSize: "18px",
            mb: 1,
          }}
          size="lg"
        >
          {props.icon}
        </Avatar>
      </IconButton>

      <Typography
        color={"black"}
        variant="body2"
        textAlign={"center"}
        onClick={props.onClick}
        sx={{
          width: "70px",
          ":hover": {
            cursor: "pointer",
          },
        }}
      >
        {props.buttonName}
      </Typography>
    </Box>
  );
};

const ActionButtonSkeleton = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
    }}
  >
    <Skeleton variant="circular" width={50} height={50} />
    <Skeleton width={70} height={24} sx={{ mt: 1 }} />
  </Box>
);

const RecipientCardSkeleton = () => (
  <Paper
    elevation={0}
    sx={{
      p: 2,
      mb: 2,
      bgcolor: (theme) => `${theme.palette.custom.mint}15`,
      borderRadius: 2,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ flex: 1 }}>
        <Skeleton width="60%" height={24} sx={{ mb: 0.5 }} />
        <Skeleton width="40%" height={20} />
      </Box>
    </Box>
  </Paper>
);

const MobileActionSkeleton = () => (
  <Grid container spacing={2}>
    {[1, 2].map((item) => (
      <Grid item xs={6} key={item}>
        <Paper
          elevation={0}
          sx={{
            py: 2,
            textAlign: "center",
            bgcolor: "brandLight.main",
          }}
        >
          <Skeleton
            variant="circular"
            width={48}
            height={48}
            sx={{ margin: "0 auto", mb: 1 }}
          />
          <Skeleton width={100} height={24} sx={{ margin: "0 auto" }} />
        </Paper>
      </Grid>
    ))}
  </Grid>
);

export default Transactions;
