// RouteChangeTracker.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../helper/helper";

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName =
      location.pathname.replace(/\//g, "").toUpperCase() || "HOME";
    // Track the page view
    analytics.page({
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    });

  }, [location]);

  return null;
};

export default RouteChangeTracker;
