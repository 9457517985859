import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Container,
  Modal,
  Typography,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { fetchUsers, remitStatusUpdateCall } from "../../reducers/userReducer";
import { amountRoundOff, analytics } from "../../helper/helper"; // Import analytics
import {
  resetTransaction,
  sendPayment,
} from "../../reducers/transactionReducer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EastIcon from "@mui/icons-material/East";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Heading from "../../components/heading";

const isValidKycState = (bank, bankVerification, kyc) => {
  return bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5);
};

const Confirm = () => {
  const { id, amount } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    exchangeRate,
    loading,
    instantExchangeRate,
    campaignApplied,
    oldExchangeRate,
    oldInstantExchangeRate,
  } = useSelector((state) => state.exchangeRate);
  const transaction = useSelector((state) => state.transaction);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userAuth);
  const { bank, bankVerification, kyc } = user;
  const { fetchUser, bankDetails, token } = user;
  const location = useLocation();
  const { purpose, is_instant } = location.state || {}; // Get the passed state

  useEffect(() => {
    dispatch(resetTransaction());
    analytics.track("Transaction Reset"); // Track transaction reset
  }, [dispatch]);

  useEffect(() => {
    if (!isValidKycState(bank, bankVerification, kyc)) {
      // Invalid state
      const checkStatus = async () => {
        const remitStatusUpdateResponse = await remitStatusUpdateCall(
          token,
          true
        );
        if (
          !isValidKycState(
            remitStatusUpdateResponse.bank,
            remitStatusUpdateResponse.bank_verification,
            remitStatusUpdateResponse.kyc
          )
        ) {
          navigate("/onboarding");
          analytics.track(
            "Redirected to onboarding due to invalid bank/verification/kyc"
          ); // Track redirection to onboarding
        }
      };
      checkStatus();
    }
  }, [dispatch, bank, bankVerification, kyc, navigate, token]);

  useEffect(() => {
    if (!id || !amount) {
      navigate("/transaction");
      analytics.track("Redirected to transaction due to missing ID/amount"); // Track missing ID or amount
    }

    if (transaction.isTransactionComplete) {
      analytics.track("Transaction complete, redirect to complete page"); // Track when transaction is complete
      navigate("/transaction/complete");
    }

    dispatch(
      fetchUsers({
        client_id: "APIGetBitRemitWeb",
        country_code: "+91",
        phone_number: id,
      })
    );
    const profile = localStorage.getItem("profile");
    const userId = JSON.parse(profile || {}).user_id;
    dispatch(fetchExchangeRate({ userId }));
    analytics.track("Exchange rate fetched"); // Track exchange rate fetched
  }, [dispatch, id, transaction.isTransactionComplete, amount, navigate]);

  const handleConfirm = () => {
    setOpen(true);
    analytics.track("Payment Confirmation Started", { amount, id }); // Track payment confirmation start
    dispatch(
      sendPayment({
        destinationCurrency: "INR",
        phoneNumber: id,
        sourceCurrency: "USD",
        amount: parseFloat(amount),
        purpose,
        is_instant,
      })
    );
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "90%",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  return (
    <CroboContainer>
      {loading ? (
        <Box py={3}>
          <Loader />
        </Box>
      ) : (
        <Container maxWidth="md" sx={{ mt: 3, mb: 5 }}>
          {/* Transfer Speed Banner */}

          <Heading
            title={is_instant ? "Crobo Instant" : "Crobo Max"}
            sx={{ my: 2 }}
          />

          {/* Transaction Summary Card */}
          <Paper>
            <Box
              sx={{
                p: { xs: 2, md: 4 },
                mb: 3,
                borderRadius: 2,
                boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
              }}
            >
              {/* Transfer Type Banner */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                  gap: 1,
                }}
              >
                <AccountBalanceWalletIcon color="brand" />
                <Typography variant="h6" fontWeight={600}>
                  Transfer summary
                </Typography>
              </Box>

              {/* Exchange Rate */}
              <Box
                sx={{
                  p: 2,
                  borderRadius: 1,
                  mb: 2,
                }}
              >
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Exchange rate
                </Typography>
                <Typography variant="h5" fontWeight={600}>
                  $1 ={" "}
                  {campaignApplied && (
                    <Typography
                      component="span"
                      variant="h6"
                      sx={{
                        textDecoration: "line-through",
                        color: "gray",
                        marginRight: 1,
                      }}
                    >
                      ₹{is_instant ? oldInstantExchangeRate : oldExchangeRate}
                    </Typography>
                  )}
                  <Typography
                    component="span"
                    variant="h5"
                    fontWeight={600}
                    sx={{
                      color: campaignApplied ? "green" : "brand.main",
                    }}
                  >
                    ₹{is_instant ? instantExchangeRate : exchangeRate}
                  </Typography>
                </Typography>
              </Box>

              {/* Amount Details */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: { xs: 2, sm: 4 },
                  p: 2,
                  borderRadius: 1,
                }}
              >
                {/* Source Amount */}
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    You send
                  </Typography>
                  <Typography variant="h5" fontWeight={600} color="brand.main">
                    ${amount}
                  </Typography>
                </Box>

                {/* Arrow Icon */}
                <Box sx={{ color: "text.secondary" }}>
                  <EastIcon sx={{ fontSize: 28 }} />
                </Box>

                {/* Destination Amount */}
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    They receive
                  </Typography>
                  <Typography variant="h5" fontWeight={600} color="brand.main">
                    ₹
                    {is_instant
                      ? amountRoundOff(instantExchangeRate * amount)
                      : amountRoundOff(exchangeRate * amount)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          {/* Details Accordion */}
          <Accordion
            elevation={0}
            sx={{
              borderRadius: 2,
              boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
              "&:before": { display: "none" },
              overflow: "hidden",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                px: { xs: 2, md: 4 },
                py: 2,
                "&.Mui-expanded": {
                  borderColor: "grey.100",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ReceiptIcon color="brand" />
                <Typography variant="h6" fontWeight={600}>
                  Transaction details
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: { xs: 2, md: 4 } }}>
              {user.loading || loading ? (
                <Loader />
              ) : (
                <>
                  {/* Source Bank Section */}
                  {bankDetails?.plaid_institution_name && (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ mb: 2, color: "text.primary" }}
                      >
                        Your debit bank details
                      </Typography>
                      <Box
                        sx={{
                          "& > div": {
                            borderBottom: "1px solid rgba(0,0,0,0.06)",
                            "&:last-child": { borderBottom: "none" },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1.5,
                          }}
                        >
                          <Typography color="text.secondary" variant="body2">
                            Source bank
                          </Typography>
                          <Typography variant="body2" fontWeight={500}>
                            {bankDetails.plaid_institution_name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1.5,
                          }}
                        >
                          <Typography color="text.secondary" variant="body2">
                            Account number
                          </Typography>
                          <Typography variant="body2" fontWeight={500}>
                            {bankDetails.plaid_account_mask}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {/* Recipient Details Section */}
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ mb: 2, color: "text.primary" }}
                    >
                      Recipient details
                    </Typography>
                    <Box
                      sx={{
                        "& > div": {
                          borderBottom: "1px solid rgba(0,0,0,0.06)",
                          "&:last-child": { borderBottom: "none" },
                        },
                      }}
                    >
                      {fetchUser?.name && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1.5,
                          }}
                        >
                          <Typography color="text.secondary" variant="body2">
                            Name
                          </Typography>
                          <Typography variant="body2" fontWeight={500}>
                            {fetchUser.name}
                          </Typography>
                        </Box>
                      )}
                      {fetchUser?.email && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1.5,
                          }}
                        >
                          <Typography color="text.secondary" variant="body2">
                            Email
                          </Typography>
                          <Typography variant="body2" fontWeight={500}>
                            {fetchUser.email}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {/* Recipient Bank Details Section */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ mb: 2, color: "text.primary" }}
                    >
                      Recipient bank details
                    </Typography>
                    <Box
                      sx={{
                        "& > div": {
                          borderBottom: "1px solid rgba(0,0,0,0.06)",
                          "&:last-child": { borderBottom: "none" },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          py: 1.5,
                        }}
                      >
                        <Typography color="text.secondary" variant="body2" textTransform="none">
                          Bank name
                        </Typography>
                        <Typography variant="body2" fontWeight={500}>
                          {fetchUser?.bankDetails?.bankName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          py: 1.5,
                        }}
                      >
                        <Typography color="text.secondary" variant="body2">
                          Account number
                        </Typography>
                        <Typography variant="body2" fontWeight={500}>
                          {fetchUser?.bankDetails?.bankAccountNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          py: 1.5,
                        }}
                      >
                        <Typography color="text.secondary" variant="body2">
                          IFSC code
                        </Typography>
                        <Typography variant="body2" fontWeight={500}>
                          {fetchUser?.bankDetails?.ifsc}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          {/* Confirm Button */}
          <Button
            variant="contained"
            fullWidth
            onClick={handleConfirm}
            size="large"
            disabled={loading || user.loading}
            sx={{
              mt: 2,
              py: 2,
              bgcolor: "brand.main",
              "&:hover": {
                bgcolor: "brand.dark",
              },
              "&:disabled": {
                bgcolor: "grey.300",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                color: "text.invert",
              }}
            >
              Confirm
            </Typography>
          </Button>
        </Container>
      )}

      {/* Processing Modal */}
      <Modal
        open={open}
        aria-labelledby="processing-modal-title"
        aria-describedby="processing-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={modalStyle}>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              fontWeight={600}
            >
              Processing your transfer
            </Typography>
            <Typography color="text.secondary">
              Please don't close the browser or tabs while we process your
              transaction
            </Typography>
          </Box>
          <Loader />
        </Box>
      </Modal>
    </CroboContainer>
  );
};

export default Confirm;
