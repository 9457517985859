import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Alert,
  Paper,
  TextField,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import { fetchSenderUserProfile } from "../../service/api";
import { analytics, errorAnalytics } from "../../helper/helper";
import { resetTransaction } from "../../reducers/transactionReducer";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import MovingIcon from "@mui/icons-material/Moving";

export const CreateTransaction = () => {
  const [sourceAmount, setSourceAmount] = useState("");
  const [destinationAmount, setDestinationAmount] = useState("");
  const [destinationAmountInstant, setDestinationAmountInstant] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { exchangeRate, instantExchangeRate, campaignApplied, oldExchangeRate, oldInstantExchangeRate } = useSelector(
    (state) => state.exchangeRate
  );

  const [error, setError] = useState();
  //set true to stop instant transfer
  const [instantLimitError, setInstantLimitError] = useState(false);
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(0);
  const [maxInstant, setMaxInstant] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(0);
  // const [weeklyLimit, setWeeklyLimit] = useState(0);
  const [purpose, setPurpose] = useState("");
  const [freeTextPurpose, setFreeTextPurpose] = useState("");
  const [value, setValue] = useState("option1");
  const [transferMethod, setTransferMethod] = useState("ACH");

  const handleChangeInstant = (event) => {
    setValue(event.target.value);
  };

  const selectPurposeOptions = [
    { name: "Own account transfer", value: "Own account transfer", icon: <AccountBalanceIcon /> },
    { name: "Family maintainance", value: "Family maintainance", icon: <FamilyRestroomIcon /> },
    { name: "Gift", value: "Gift", icon: <CardGiftcardIcon /> },
    { name: "Payment for services", value: "Payment for services", icon: <HandshakeIcon /> },
    { name: "Others", value: "Others", icon: <MoreHorizIcon /> },
  ];

  useEffect(() => {
    const profile = localStorage.getItem('profile');
    const userId = JSON.parse(profile || {}).user_id;
    dispatch(fetchExchangeRate({userId: userId}));
    setTransferMethod("ACH");
    fetchSenderUserProfile().then((r) => {
      setMinLimit(r.Per_transaction_min);
      setMaxLimit(r.Per_transaction_max);
      setMaxInstant(r.Instant);
      setDailyLimit(r.Daily);
      // setWeeklyLimit(r.Weekly);
    });
    dispatch(resetTransaction());
    // dispatch(
    //   fetchUsers({
    //     client_id: "APIGetBitRemitWeb",
    //     country_code: "+91",
    //     phone_number: id,
    //   })
    // );
  }, [dispatch, id]);

  const getFinalPurpose = (purpose) => {
    if (purpose === "Others") return freeTextPurpose;
    return purpose;
  };

  const handleStartTransfer = () => {
    if (!value) {
      setError("Please select a transfer speed");
      errorAnalytics("TRANSFER_SPEED_NOT_SELECTED", {
        error: "Transfer speed not selected",
      });
      return;
    }

    if (!transferMethod) {
      setError("Please select a transfer method");
      errorAnalytics("TRANSFER_METHOD_NOT_SELECTED", {
        error: "Transfer method not selected",
      });
      return;
    }

    if (sourceAmount < minLimit) {
      setError(`Min amount should be greater than $${minLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Min limit error`,
        amount: sourceAmount,
      });
    } else if (sourceAmount > maxLimit) {
      setError(`Max amount should not be greater than $${maxLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Max limit error`,
        amount: sourceAmount,
      });
    } else if (!getFinalPurpose(purpose)) {
      setError(`Purpose is mandatory`);
      errorAnalytics("PURPOSE_NOT_ENTERED", {
        error: `Purpose is mandatory`,
      });
    } else {
      analytics.track("Transfer started", {
        sourceAmount,
        destinationAmount,
        transferType: value === "option1" ? "High value" : "Instant",
      });
      navigate(`/transaction/confirm/${id}/${sourceAmount}`, {
        state: {
          purpose: getFinalPurpose(purpose),
          is_instant: value === "option1" ? false : true,
          transfer_method: transferMethod,
        },
      });
    }
  };
 
  const checkInstantError = (sourceAmount) => {
    if (sourceAmount > maxInstant) {
      instantLimitError !== true && setInstantLimitError(true);
      setValue("option1");
    } else {
      instantLimitError === true && setInstantLimitError(false);
    }
  };

  // const freePurposeTextBox = () => {
  //   if (purpose === "Others") {
  //     return (
  //       <OutlinedInput
  //         placeholder="Please enter purpose..."
  //         value={freeTextPurpose}
  //         type="number"
  //         sx={{ marginTop: 2, width: "100%" }}
  //         multiline
  //         minRows={4}
  //         onChange={(e) => {
  //           setFreeTextPurpose(e.target.value);
  //         }}
  //         inputProps={{ maxLength: 100 }}
  //       />
  //     );
  //   }
  // };

  return (
    <CroboContainer>
      <Container>
        {/* Main Form Section */}
        <Box
          sx={{
            borderRadius: 2,
            py: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "start",
              mb: 2,
              gap: { xs: 1, sm: 0 },
            }}
          >
            <Tooltip
              title={`Crobo max offers the best exchange rates. The maximum transfer limit for Crobo max is $${dailyLimit} per transaction.`}
              enterTouchDelay={0}
              arrow
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <IconButton size="small" sx={{ p: 0 }}>
                  <InfoIcon sx={{ color: "brand.main" }} fontSize="small" />
                </IconButton>
                <Typography
                  sx={{ fontSize: { xs: "0.7em", sm: "1em" } }}
                  color="text.secondary"
                >
                  Crobo max: $
                  {dailyLimit !== undefined && maxLimit !== null ? (
                    <>{dailyLimit}</>
                  ) : (
                    <CircularProgress size="14px" color="brand" />
                  )}
                </Typography>
              </Box>
            </Tooltip>
            <Divider
              orientation="vertical"
              flexItem
              color="brand.main"
              sx={{ display: { xs: "block", sm: "block" } }}
            />
            <Tooltip
              title={`Crobo instant delivers money within seconds. The maximum transfer limit for Crobo instant is $${maxInstant} per transaction.`}
              enterTouchDelay={0}
              arrow
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <IconButton size="small" sx={{ p: 0 }}>
                  <InfoIcon sx={{ color: "brand.main" }} fontSize="small" />
                </IconButton>
                <Typography
                  sx={{ fontSize: { xs: "0.7em", sm: "1em" } }}
                  color="text.secondary"
                >
                  Crobo instant: $
                  {maxInstant !== undefined && maxLimit !== null ? (
                    <>{maxInstant}</>
                  ) : (
                    <CircularProgress size="14px" color="brand" />
                  )}
                </Typography>
              </Box>
            </Tooltip>
          </Box>

          {/* Amount Input */}
          <Box>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              Amount
            </Typography>

            <OutlinedInput
              placeholder="Enter amount in USD"
              value={sourceAmount}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component="img"
                    src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                    sx={{
                      height: 24,
                      width: 32,
                      mr: 1,
                      borderRadius: 0.5,
                    }}
                    alt="USD"
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography color="text.secondary">USD</Typography>
                </InputAdornment>
              }
              sx={{
                bgcolor: "background.paper",
                "& .MuiOutlinedInput-input": {
                  py: 1.5,
                  fontSize: "1.1rem",
                  fontWeight: 500,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "brand.main",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "brand.main",
                },
              }}
              type="text"
              inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*[.,]?[0-9]*",
                onWheel: (e) => e.target.blur(),
              }}
              onChange={(e) => {
                const value = e.target.value;
                // Remove leading zeros and allow only numbers and one decimal point
                const sanitizedValue = value
                  .replace(/^0+(?=\d)/, "")
                  .replace(/[^\d.]/g, "");
                // Ensure only one decimal point
                const decimalCount = (sanitizedValue.match(/\./g) || []).length;
                if (decimalCount <= 1) {
                  if (/^\d*\.?\d*$/.test(sanitizedValue)) {
                    setSourceAmount(sanitizedValue);
                    setDestinationAmount(
                      (sanitizedValue * exchangeRate).toFixed(2)
                    );
                    setDestinationAmountInstant(
                      (sanitizedValue * instantExchangeRate).toFixed(2)
                    );
                    checkInstantError(sanitizedValue);
                    analytics.track("Source Amount Entered", {
                      sourceAmount: sanitizedValue,
                    });
                  }
                }
              }}
              fullWidth
            />
          </Box>

          {/* Transfer Method Selection */}
          {/* <Box sx={{ mt: 1.5 }}>
            <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 1 }}>
              Select transfer method
            </Typography>
            <RadioGroup
              value={transferMethod}
              onChange={(e) => {
                setTransferMethod(e.target.value);
                analytics.track("Transfer Method Selected", {
                  method: e.target.value,
                });
              }}
              sx={{ gap: 1 }}
            >
              <Paper
                elevation={transferMethod === "ACH" ? 2 : 0}
                sx={{
                  py: 0.5,
                  px: 1,
                  border: 1,
                  borderColor: transferMethod === "ACH" ? "brand.main" : "grey.200",
                  borderRadius: 2,
                  transition: "all 0.2s",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value="ACH"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "brand.main",
                          },
                          padding: 0.5,
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccountBalanceIcon sx={{ color: 'text.secondary', fontSize: '1.2rem' }} />
                        <Typography variant="subtitle2" fontWeight={500}>
                          ACH
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Paper>

              <Paper
                elevation={transferMethod === "WIRE" ? 2 : 0}
                sx={{
                  py: 0.5,
                  px: 1,
                  border: 1,
                  borderColor: transferMethod === "WIRE" ? "brand.main" : "grey.200",
                  borderRadius: 2,
                  transition: "all 0.2s",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value="WIRE"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "brand.main",
                          },
                          padding: 0.5,
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BoltIcon sx={{ color: 'text.secondary', fontSize: '1.2rem' }} />
                        <Typography variant="subtitle2" fontWeight={500}>
                          Wire/FTP/FEDNOW
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Paper>
            </RadioGroup>
          </Box> */}

          {/* Transfer Options */}
          <FormControl
            component="fieldset"
            error={!!error && !value}
            sx={{
              mt: 2,
              width: "100%",
            }}
          >
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              Select transfer speed
            </Typography>

            <RadioGroup
              value={value}
              onChange={handleChangeInstant}
              sx={{ gap: 1 }}
            >
              {/* Standard Transfer Option */}
              <Paper
                elevation={value === "option1" ? 2 : 0}
                sx={{
                  py: 1,
                  px: 1.5,
                  border: 1,
                  borderColor: value === "option1" ? "brand.main" : "grey.200",
                  borderRadius: 2,
                  transition: "all 0.2s",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControlLabel
                    value="option1"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "brand.main",
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 0.5,
                          }}
                        >
                          <Typography variant="subtitle1" fontWeight={500}>
                            Crobo max
                          </Typography>
                          <MovingIcon
                            sx={{ fontSize: "1.1rem", color: "brand.main" }}
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          (up to 2 business days)
                        </Typography>
                      </Box>
                    }
                  />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    sx={{
                      bgcolor: "grey.50",
                      p: 0.75,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    $1 =
                    {campaignApplied && exchangeRate != oldExchangeRate && (
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ textDecoration: "line-through", color: "gray" }}
                      >
                        ₹{oldExchangeRate}
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: campaignApplied ? "green" : "inherit",
                        fontSize: "1.1rem",
                        fontWeight: 700,
                      }}
                    >
                      ₹{exchangeRate}
                    </Typography>
                  </Typography>
                </Box>

                {value === "option1" && (
                  <Box sx={{ mt: 2 }}>
                    <OutlinedInput
                      placeholder="Enter amount in INR"
                      value={destinationAmount}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            sx={{
                              height: 24,
                              width: 32,
                              mr: 1,
                              borderRadius: 0.5,
                            }}
                            alt="INR"
                          />
                          {/* ₹ */}
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">INR</InputAdornment>
                      }
                      type="text"
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "[0-9]*[.,]?[0-9]*",
                        onWheel: (e) => e.target.blur(),
                      }}
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        // Remove leading zeros and allow only numbers and one decimal point
                        const sanitizedValue = e.target.value
                          .replace(/^0+(?=\d)/, "")
                          .replace(/[^\d.]/g, "");
                        // Ensure only one decimal point
                        const decimalCount = (sanitizedValue.match(/\./g) || [])
                          .length;
                        if (decimalCount <= 1) {
                          if (/^\d*\.?\d*$/.test(sanitizedValue)) {
                            const calculatedSourceAmount = (
                              sanitizedValue / exchangeRate
                            ).toFixed(2);
                            setSourceAmount(calculatedSourceAmount);
                            setDestinationAmount(sanitizedValue);
                            checkInstantError(calculatedSourceAmount);
                            analytics.track("Destination Amount Entered", {
                              destinationAmount: sanitizedValue,
                            });
                          }
                        }
                      }}
                      fullWidth
                      sx={{
                        bgcolor: "background.paper",
                        mb: 1,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "brand.main",
                        },
                      }}
                    />
                  </Box>
                )}
              </Paper>

              {/* Instant Transfer Option */}
              <Paper
                elevation={value === "option2" ? 2 : 0}
                sx={{
                  py: 1,
                  px: 1.5,
                  border: 1,
                  borderColor: value === "option2" ? "brand.main" : "grey.200",
                  borderRadius: 2,
                  transition: "all 0.2s",
                  opacity: instantLimitError ? 0.7 : 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControlLabel
                    disabled={instantLimitError}
                    value="option2"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "brand.main",
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 0.5,
                          }}
                        >
                          <Typography variant="subtitle1" fontWeight={500}>
                            Crobo instant
                          </Typography>
                          <FlashOnIcon
                            sx={{ fontSize: "1.1rem", color: "brand.main" }}
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          (delivered within seconds)
                        </Typography>
                      </Box>
                    }
                  />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    sx={{
                      bgcolor: "grey.50",
                      p: 0.75,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    $1 =
                    {campaignApplied &&
                      oldInstantExchangeRate != instantExchangeRate && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ textDecoration: "line-through", color: "gray" }}
                        >
                          ₹{oldInstantExchangeRate}
                        </Typography>
                      )}
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: campaignApplied ? "green" : "inherit",
                        fontSize: "1.1rem",
                        fontWeight: 700,
                      }}
                    >
                      ₹{instantExchangeRate}
                    </Typography>
                  </Typography>
                </Box>

                {value === "option2" && !instantLimitError && (
                  <Box sx={{ mt: 2 }}>
                    <OutlinedInput
                      placeholder="Enter amount in INR"
                      value={destinationAmountInstant}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            sx={{
                              height: 24,
                              width: 32,
                              mr: 1,
                              borderRadius: 0.5,
                            }}
                            alt="INR"
                          />
                          {/* ₹ */}
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">INR</InputAdornment>
                      }
                      type="text"
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "[0-9]*[.,]?[0-9]*",
                        onWheel: (e) => e.target.blur(),
                      }}
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        // Remove leading zeros and allow only numbers and one decimal point
                        const sanitizedValue = e.target.value
                          .replace(/^0+(?=\d)/, "")
                          .replace(/[^\d.]/g, "");
                        // Ensure only one decimal point
                        const decimalCount = (sanitizedValue.match(/\./g) || [])
                          .length;
                        if (decimalCount <= 1) {
                          if (/^\d*\.?\d*$/.test(sanitizedValue)) {
                            const calculatedSourceAmount = (
                              sanitizedValue / instantExchangeRate
                            ).toFixed(2);
                            setSourceAmount(calculatedSourceAmount);
                            setDestinationAmountInstant(sanitizedValue);
                            checkInstantError(calculatedSourceAmount);
                            analytics.track("Destination Amount Entered", {
                              destinationAmount: sanitizedValue,
                            });
                          }
                        }
                      }}
                      fullWidth
                      sx={{
                        bgcolor: "background.paper",
                        mb: 1,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "brand.main",
                        },
                      }}
                    />
                  </Box>
                )}
              </Paper>
            </RadioGroup>

            {/* Error message for transfer speed */}
            {error && !value && (
              <Typography
                color="error"
                variant="body2"
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <ErrorOutlineIcon fontSize="small" />
                Please select a transfer speed
              </Typography>
            )}
          </FormControl>

          {instantLimitError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {/* Instant transfer is currently unavailable. We are working on it.
              Apologies for the inconvenience */}
              Not eligible for instant transfer. Maximum limit is ${maxInstant}
            </Alert>
          )}

          {/* Purpose Selection */}
          <Box sx={{ mt: 1.5 }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              Purpose of transfer
            </Typography>

            <Select
              value={purpose}
              displayEmpty
              onChange={(e) => {
                setPurpose(e.target.value);
                analytics.track("Purpose Selected", {
                  purpose: e.target.value,
                });
              }}
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                "& .MuiSelect-select": {
                  py: 1.5,
                },
              }}
            >
              <MenuItem disabled value="">
                <Typography color="text.secondary">Select purpose</Typography>
              </MenuItem>
              {selectPurposeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {React.cloneElement(option.icon, {
                        sx: { fontSize: "1.1rem", color: "brand.main" },
                      })}
                    </Box>
                    <Typography variant="subtitle1">{option.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>

            {purpose === "Others" && (
              <TextField
                multiline
                rows={4}
                placeholder="Please specify the purpose..."
                value={freeTextPurpose}
                onChange={(e) => setFreeTextPurpose(e.target.value)}
                sx={{ mt: 2, width: "100%", bgcolor: "background.paper" }}
                inputProps={{ maxLength: 100 }}
              />
            )}
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {/* Add validation message when no option is selected */}
          {sourceAmount && !value && (
            <Typography
              color="error"
              variant="body2"
              sx={{ mt: 1, display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <ErrorOutlineIcon fontSize="small" />
              Please select a transfer speed
            </Typography>
          )}

          {/* Submit Button */}
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleStartTransfer}
            disabled={
              !sourceAmount ||
              sourceAmount === 0 ||
              !value ||
              !transferMethod ||
              (value === "option1" &&
                (!destinationAmount || destinationAmount === 0)) ||
              (value === "option2" &&
                (!destinationAmountInstant || destinationAmountInstant === 0))
            }
            sx={{
              mt: 3,
              py: 2,
              bgcolor: "brand.main",
              "&:hover": {
                bgcolor: "brand.dark",
              },
              "&:disabled": {
                bgcolor: "grey.300",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                color: "text.invert",
              }}
            >
              Continue
            </Typography>
          </Button>
        </Box>
      </Container>
    </CroboContainer>
  );
};
