import React from "react";
import CroboContainer from "../components/croboContainer";
import {
  Box,
  Typography,
  Button,
  Container,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useSelector } from "react-redux";
import {
  cybridRemitUrl,
  officialWebsiteUrl,
  sdkRedirectToRoute,
} from "../constants/apiConstants";

const ManageBankAccount = () => {
  const { guid, token } = useSelector((state) => state.userAuth);

  const handleManageBankAccount = () => {
    const environment = {
      cybridRemitUrl: cybridRemitUrl,
      // officialWebsiteUrl: window.location.origin,
      officialWebsiteUrl: officialWebsiteUrl,
    };
    const redirectToRoute = sdkRedirectToRoute;
    window.open(
      `${environment.cybridRemitUrl}?customer_guid=${guid}&bank=true&kyc=true&token=${token}&redirect=${environment.officialWebsiteUrl}${redirectToRoute}`,
      "_blank"
    );
  };

  return (
    <>
      <CroboContainer>
        <Container>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            paddingY={4}
          >
            <Button
              variant="contained"
              onClick={handleManageBankAccount}
              fullWidth
              size="large"
              color="brand"
              startIcon={<AccountBalanceIcon sx={{ color:"text.invert" }} />}
            >
              <Typography color="white">Manage bank account</Typography>
            </Button>
          </Box>
          <br />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Important information on bank account management
          </Typography>
          <br />

          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            1. We support one bank account for now:
          </Typography>

          <Typography>
            Currently, our system only supports linking one bank account at a
            time. Please ensure that the bank account you link is the one you
            intend to use for all transactions.
          </Typography>
          <br />
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            2. To add a new account, follow these steps:
          </Typography>

          <Typography>
            Step 1: Click on &quot;Manage bank account&quot;.
          </Typography>

          <Typography>Step 2: Select the attached bank.</Typography>

          <Typography>Step 3: Click on &quot;Disconnect&quot;.</Typography>

          <Typography>Step 4: Add new bank account details.</Typography>
          <br />
          <Typography>
            Note: Adding a new bank account may take up to 48 hours to verify.
          </Typography>
        </Container>
      </CroboContainer>
    </>
  );
};

export default ManageBankAccount;
