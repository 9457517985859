import { Box } from '@mui/material'
import React from 'react'
import Nav from './navV1'
import Footer from './footer'
import FooterV1 from './footerV1'

const FullPageContainer = ({children}) => {
  return (
     <Box sx={{ position: "relative" }}>
      <Nav />
      {children}
      <FooterV1 />
     </Box>
  )
}

export default FullPageContainer