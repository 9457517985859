import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CroboContainer from "../../components/croboContainer";
import { getReferralHistory } from "../../reducers/userReducer";
import ReferralHistoryCard from "../../components/referralHistoryCard";

const ReferralHistory = () => {
    const store = useSelector((state) => state.userAuth);
    // const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReferralHistory({
            page: 1,
            pageSize: 10,
            filter: 0
        }));
    }, [dispatch]);

    const statusMap = {
        1: 'complete',
        0: 'pending'
    }

    return (
        <CroboContainer>
            {store.referralHistory?.length >= 1 ? (
                <Box>
                    {store.loading ? (
                        <Box display={"flex"} justifyContent={"center"}>
                            <CircularProgress color="brand" />
                        </Box>
                    ) : (
                        <Container>
                            {store.referralHistory?.map((t) => (
                                <ReferralHistoryCard
                                    key={t.referee_id}
                                    userName={t.user_name}
                                    refereeId={t.referee_id}
                                    isSignedUp={statusMap[t.is_signed_up]}
                                    referralDate={t.referral_created}
                                    referralStatus={t.referral_status}
                                    transactionStatus={statusMap[t.transaction_status || 0]}
                                />
                            ))}
                        </Container>
                    )}
                </Box>
            ) : (
                <Box display={"flex"} justifyContent={"center"} alignItems={'center'} sx={{ height: "200px" }}>
                    <Typography alignItems={"center"} variant="h5">No referral history</Typography>
                </Box>
            )}
        </CroboContainer>
    );
};

export default ReferralHistory;
