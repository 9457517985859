import React, { useEffect ,useState} from "react";
import { Box } from "@mui/material";
import VerificationStepper from "./VerificationStepper";
import VerificationStatus from "./VerificationStatus";
import ActionButtons from "./ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import CroboContainer from "../../components/croboContainer";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import { Camera } from '@capacitor/camera';
import { analytics } from "../../helper/helper";
import Heading from "../../components/heading";

const VerifyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bankBypass, setBankBypass] = useState(false);
  const [cameraPermission, setCameraPermission] = useState(false);
  const {
    bank,
    bankVerification,
    kyc,
    guid,
    token,
    showDocumentsButton,
    customMessage,
    remitLoading,
    loading
  } = useSelector((state) => state.userAuth);

 

  useEffect(() => {
     const checkCameraPermission = async () => {
       try {
         const permission = await Camera.checkPermissions();
         if (permission.camera !== "granted") {
           const request = await Camera.requestPermissions();
           setCameraPermission(request.camera === "granted");
         } else {
           setCameraPermission(true);
         }
       } catch (error) {
         console.error("Camera permission error:", error);
         analytics && analytics.track("Camera permission error", { error });
         setCameraPermission(false);
         console.log(cameraPermission);
       }
     };
    checkCameraPermission();
  }, [cameraPermission]);

  useEffect(() => {
    if(remitLoading){
      if (bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5)) {
        navigate("/transaction");
      }
    }
  }, [dispatch, token, bankVerification, bank, kyc, navigate,remitLoading]);

  return (
    <CroboContainer>
      <Box padding={2}>
        {!remitLoading ? (
          <Loader />
        ) : (
          <Box>
            <Heading title="Verify Account" sx={{ mt: 2 }} />
            <VerificationStepper
              state={{
                bankBypass,
                setBankBypass,
                bank,
                bankVerification,
                kyc,
                guid,
                token,
                showDocumentsButton,
              }}
            />
            <Box mt={2}>
              <VerificationStatus
                state={{
                  bankBypass,
                  setBankBypass,
                  bank,
                  bankVerification,
                  kyc,
                  guid,
                  token,
                  showDocumentsButton,
                  remitLoading,
                  loading
                }}
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <ActionButtons
                state={{
                  bankBypass,
                  setBankBypass,
                  bank,
                  bankVerification,
                  kyc,
                  guid,
                  token,
                  showDocumentsButton,
                  customMessage,
                  loading
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </CroboContainer>
  );
};

export default VerifyAccount;
