import { Box, Container, Pagination, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../reducers/transactionReducer";
import CroboContainer from "../../components/croboContainer";
import TransactionCard from "../../components/transactionCard";
import { useNavigate } from "react-router-dom";
import Grow from '@mui/material/Grow';
import Heading from "../../components/heading";
import Skeleton from "@mui/material/Skeleton";

export const TransactionCardSkeleton = () => (
  <Paper
    elevation={0}
    sx={{
      p: 2,
      mb: 2,
      bgcolor: (theme) => `${theme.palette.custom.mint}15`,
      borderRadius: 2,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Box sx={{ flex: 1 }}>
        <Skeleton width="60%" height={24} sx={{ mb: 0.5 }} />
        <Skeleton width="40%" height={20} />
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <Skeleton width={80} height={24} sx={{ mb: 0.5 }} />
        <Skeleton width={60} height={20} />
      </Box>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
      <Skeleton width={120} height={20} />
    </Box>
  </Paper>
);

const TransactionHistory = () => {
  return (
    <CroboContainer>
      <Container>
        <Heading title="Transaction history" sx={{ my: 2 }} />
        <TransactionHistoryList />
      </Container>
    </CroboContainer>
  );
};

export const TransactionHistoryList = () => {
  const navigate = useNavigate();
  const { transactions, loading, totalPage } = useSelector(
    (state) => state.transaction
  );
  const [page, setPage] = React.useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTransactions({ page: page }));
  }, [dispatch, page]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Box>
      {loading ? (
        <Box py={3}>
          {[1, 2, 3, 4, 5].map((item) => (
            <TransactionCardSkeleton key={item} />
          ))}
          <Box display="flex" justifyContent="center" pt={2} mb={10}>
            <Skeleton width={300} height={40} />
          </Box>
        </Box>
      ) : transactions.length === 0 ? (
        <Heading title="No Transactions" sx={{ my: 2 }} />
      ) : (
        <>
          {transactions.map((t, index) => (
            <Grow in={true} timeout={500 + index * 100} key={t.id}>
              <div>
                <TransactionCard
                  id={t.id}
                  transMsg={t.trans_msg}
                  tranId={t.trans_id}
                  trans_id={t.trans_id}
                  onClick={() => {
                    navigate(`/transaction/details/${t.trans_id}`);
                  }}
                  receiveInBank={t.ReceiveInBank}
                  status={t.status}
                  exchangeRate={t.current_rate}
                  transactionalTime={t.transactional_time}
                  UtrNo={t.utr_no}
                  sourceAmount={t.base_sub_total}
                  receiverName={t.receiverName}
                  nickName={
                    t.receiverUnverifiedName
                      ? t.receiverUnverifiedName
                      : t.receiverName
                  }
                />
              </div>
            </Grow>
          ))}
          <Box display={"flex"} justifyContent={"center"} pt={2} mb={10}>
            <Pagination count={totalPage} page={page} onChange={handleChange} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TransactionHistory;
