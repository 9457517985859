import { Box, Divider, Typography } from '@mui/material';
import React from 'react'

const Heading = ({ title, sx, fontWeight }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <Divider sx={{ flex: 1 }} />
      <Typography sx={{ px: 2 }} variant="h5" fontWeight={fontWeight}>
        {title}
      </Typography>
      <Divider sx={{ flex: 1 }} />
    </Box>
  );
}

export default Heading
