import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Nav from '../components/nav';
import CroboContainer from '../components/croboContainer';

const PrivacyPolicy = () => {
  return (
    <CroboContainer>
      <Box>
        <Container maxWidth="md">
          <Box>
            <Typography variant="h4" align="center" gutterBottom>
              Crobo Global Privacy Notice
            </Typography>
            <Typography variant="body1" align="center" paragraph fontWeight={'medium'}>
             Version 2.0
              <br />
              Last Updated: June 2, 2024
            </Typography>
            <Typography variant="body1" paragraph>
              This privacy notice explains how GetBit Technologies Inc DBA Crobo
              or crobo.money and its affiliates, process information about you.
              This privacy notice applies when you use our websites, mobile
              applications, browser extension, payment processing services,
              application programming interfaces, and other online products and
              services that link to this privacy notice (collectively, our
              “Services”), contact our customer service team, engage with us on
              social media, or otherwise interact with us or our Services.
            </Typography>
            <Typography variant="body1" paragraph>
              We may change this privacy notice from time to time. If we make
              changes, we will notify you by revising the date at the top of
              this notice and, in some cases, we may provide you with additional
              notice (such as adding a statement to our website or sending you a
              notification). We encourage you to review this privacy notice
              regularly to stay informed about our information handling
              practices and the choices available to you.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Collection of Information - Information You Provide to Us
            </Typography>
            <Typography variant="body1" paragraph>
              We collect information you provide directly to us. For example,
              you can provide information directly to us when you create a Crobo
              account, use our Services to send and receive money or
              cryptocurrency or to buy and sell cryptocurrency, request access
              to or use certain features, request customer support, or otherwise
              communicate with us. The types of information we may collect about
              you include your name and contact information (such as email
              address, postal address, and phone number), date of birth,
              username, profile image, photograph, identity information you
              submit (such as government-issued identification numbers, photos
              of government-issued documents, and proof of address), passcode,
              payment and financial account information (including payment
              method information and bank account and routing numbers),
              transaction information (including information about other parties
              to the transaction), and any other information you choose to
              provide.
            </Typography>
            <Typography variant="body1" paragraph>
              To open a business account with Crobo, we may require you to
              provide additional information about you and the beneficial owners
              of the business. The types of additional information we may
              collect for business accounts include business name, physical
              address, mailing address, proof of address, tax ID, name of
              beneficial owner(s), proof of legal formation (for example, a
              Certificate of Incorporation), bank statement, bank account
              information, and name and contact information of employees
              authorized to act on behalf of the business.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Information We Collect Automatically When You Interact with Us
            </Typography>
            <Typography variant="body1" paragraph>
              When you use or interact with our Services, we may automatically
              collect certain information, including:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Transactional Information"
                  secondary="When you transfer, purchase, or sell cryptocurrency or make or receive a payment, we collect information about the transaction, such as the transaction amount, the date of the transaction, the transaction origination and destination (including any address information), and, in some cases, the identity and contact information of the other party to your transaction."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Device Information"
                  secondary="We collect information about your device when you interact with our Services, including hardware model, operating system version, mobile network, IP address, device identifier, browser type, and app version."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Location Information"
                  secondary="We infer your approximate geographic location (e.g. country or city-level location) based upon your IP address."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Information Collected by Cookies and Similar Tracking Technologies"
                  secondary="We (and our service providers) use tracking technologies, such as cookies and web beacons, to collect information about you. Cookies are small data files stored on your hard drive or in device memory that help us provide and improve our Services and your experience, such as by allowing us to see which areas and features of our Services are popular, store preferences, and help protect our systems and users. Web beacons (also known as “pixel tags” or “clear GIFs”) are electronic images that we use on our Services and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness."
                />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              Information We Collect from Other Sources
            </Typography>
            <Typography variant="body1" paragraph>
              We collect information about you (and the other party to a
              transaction) from third-party sources. For example, we may collect
              this information from identity verification and know-your-customer
              service providers, financial services providers, financial
              institutions, including information such as name, username, phone
              number, postal address, transaction confirmations, age,
              government-issued identification numbers (such as Social Security
              number), and financial account information (including account
              balances and transaction history). In addition, we receive
              information about you and your use and interactions with our
              Services, our marketing campaigns, and other third-party websites
              and services from third-party partners, such as advertising
              partners who provide us with information about your interest in
              and engagement with our Services and online advertisements. We may
              also receive information about you from other users, such as when
              they engage in transactions with you through our Services or
              invite you to use our Services. This information may include
              transaction descriptions that relate to you or your name or
              username.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Information We Derive
            </Typography>
            <Typography variant="body1" paragraph>
              Finally, we may also derive information or draw inferences about
              you based on the information we collect. For example, as noted
              above, we may make inferences about your location based on your IP
              address, we may infer that certain products or services may be of
              interest to you based on your transaction history, and we may draw
              inferences about potential fraudulent activity.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Use of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use the information we collect to provide, maintain, and
              improve our Services, including to process transactions, identify
              and repair errors in our Services, and analyze usage over time. We
              or our service providers also use the information we collect to:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="Authenticate and help verify your identity;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Send you transactional or other account-related information, including confirmations, receipts, customer experience surveys, security alerts, transaction updates, and other customer support and administrative messages;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Notify you about changes to our Services;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Send you marketing communications that we think will interest you;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Measure or understand the effectiveness of advertising we serve and deliver relevant advertising to you;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Detect, investigate, and help prevent security incidents and other malicious, fraudulent, or illegal activity and help protect the rights and property of Crobo and others. Some of this preventative action may prevent you from registering for an account, set deposit or withdrawal limits, or reject requested transactions on an automated basis. If you have questions about the validity of any automated rejection or limitation, please contact us;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Carry out our obligations and enforce the terms of our Services;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="De-identify, anonymize, or aggregate your information;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Comply with our legal and financial obligations;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Carry out any other purpose described to you at the time the information was collected." />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              Sharing of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We share information about you in the following circumstances or
              as otherwise described in this notice:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="With companies, professional advisors, and contractors that access your information to perform services for us, such as companies that assist us with web hosting, data storage, identity verification, fraud prevention, payment processing, tax reporting, financial services, customer service and support, legal and consulting services, customer communications, analytics, and marketing and advertising;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Depending on your location, we, or our financial institution partners may provide your personal information to vendors to help verify your identity and prevent fraud, including by determining whether a selfie you take matches the photo in your government-issued identity document." />
              </ListItem>
              <ListItem>
                <ListItemText primary="With financial institutions that we partner with to receive and process payments and transactions you have authorized via the Services. Our financial institution partners may vary depending on your geographic location. Please review our Terms of service for additional information regarding our financial institution partners’ services;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="With business partners that provide products or services you request or that jointly offer products and services with us;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="With other parties to transactions, including other people you are sending payments to or receive payment from, merchants when you use the Services to pay for goods and services, and their service providers;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="With advertisers and advertising networks to facilitate more relevant and targeted advertising;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="If we believe that disclosure is in accordance with, or required by, any applicable law or legal process. For example, we may disclose information about users or transactions in connection with tax filings, regulatory requests, court orders, or law enforcement requests;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="If we believe that your actions are inconsistent with our user agreements or policies, that you have violated the law, or that it is necessary to protect the rights, property, and safety of Crobo, our users, the public, or others;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="In connection with, or during negotiations concerning, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Between and among Crobo and its affiliates for use in connection with the purposes outlined above; and" />
              </ListItem>
              <ListItem>
                <ListItemText primary="With your consent or at your direction." />
              </ListItem>
            </List>
            <Typography variant="body1" paragraph>
              We may also share aggregated or de-identified information that
              cannot reasonably be used to identify you.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Analytics
            </Typography>
            <Typography variant="body1" paragraph>
              We allow others to provide analytics services in connection with
              our Services. These entities may use cookies, web beacons, device
              identifiers, and other technologies to collect information about
              your use of our Services and across other websites and online
              services, including your IP address, web browser, mobile network
              information, pages viewed, time spent on pages or in mobile apps,
              and links clicked. This information may be used to, among other
              things, analyze and track data, determine the popularity of
              certain content, and better understand your online activity.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Your Choices
            </Typography>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            <Typography variant="body1" paragraph>
              You may update and correct certain account information by logging
              into your account. If you wish to close your account, please use
              the tools made available via the settings feature to deactivate
              your account. Note that we may retain certain information as
              required by law or for our legitimate business purposes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Cookies
            </Typography>
            <Typography variant="body1" paragraph>
              Most web browsers are set to accept cookies by default. If you
              prefer, you can usually adjust your browser settings to remove or
              reject browser cookies. Please note that removing or rejecting
              cookies could affect the availability and functionality of our
              Services.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Communications Preferences
            </Typography>
            <Typography variant="body1" paragraph>
              You may opt out of receiving marketing emails from Crobo by
              following the instructions in those communications. If you opt
              out, we may still send you other non-marketing emails, such as
              those about your account and transactions or our ongoing business
              relations.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Mobile Push Notifications
            </Typography>
            <Typography variant="body1" paragraph>
              With your consent, we may send push notifications to your mobile
              device. You can deactivate these messages at any time by changing
              the notification settings on your mobile device.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Protection of Information
            </Typography>
            <Typography variant="body1" paragraph>
              Crobo implements security practices and policies designed to
              protect the confidentiality and security of your nonpublic
              personal information, which includes the information you provide
              to register for a consumer account (such as Social Security number
              and other identifying information) and other nonpublic personal
              information we collect about you. Crobo implements measures
              designed to limit access to this information to personnel that
              have a business reason to know it and prohibits its personnel from
              unlawfully disclosing this information.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Children
            </Typography>
            <Typography variant="body1" paragraph>
              To open a Crobo Account and use the Services, you must be 18 years
              of age or older. We do not knowingly collect or solicit any
              information from any person under the age of 13 on our Services.
              If we have inadvertently collected personal information from
              anyone under age 13, we will make reasonable efforts to delete the
              information. If you believe that we may have collected any such
              information, please contact us at care@crobo.money.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Legal Basis for Processing
            </Typography>
            <Typography variant="body1" paragraph>
              When we process your personal data, we will do so in reliance on
              the following lawful bases:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="To perform our responsibilities under our contract with you (e.g., providing the Services you requested)." />
              </ListItem>
              <ListItem>
                <ListItemText primary="When we have a legitimate interest in processing your personal data to operate our business or protect our interests (e.g., to provide, maintain, and improve our Services, conduct data analytics, and communicate with you)." />
              </ListItem>
              <ListItem>
                <ListItemText primary="To comply with our legal obligations (e.g., to maintain a record of any consents and track those who have opted out of marketing communications)." />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              Data Subject Requests
            </Typography>
            <Typography variant="body1" paragraph>
              Subject to certain limitations, and depending on your
              jurisdiction, you have the right to request access to the personal
              data we hold about you and to receive your data in a portable
              format, the right to ask that your personal data be corrected or
              erased, and the right to object to, or request that we restrict,
              certain processing.
            </Typography>
            <Typography variant="body1" paragraph>
              If you would like to exercise any of these rights, please email us
              at care@crobo.money. When we receive a request, we may ask you for
              additional information in order to verify your identity and as a
              security measure to protect your personal information. In some
              situations, we may refuse to act or may impose limitations on your
              rights, as permitted by applicable law.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions about this privacy notice, please
              contact us at:
            </Typography>
            <Typography variant="body2" paragraph>
              113 Cherry St PMB 164652, Seattle, Washington 98104, USA
            </Typography>
            <Typography variant="body2" paragraph>
              care@crobo.money
            </Typography>
          </Box>
        </Container>
      </Box>
    </CroboContainer>
  );
};

export default PrivacyPolicy;
