import React from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

export const TransactionLimitLine = ({
  transactionLimit,
  showButton = true,
  daily=true,
  weekly=true,
  instant=true,
  max=true,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const getProgressColor = (percentage) => {
    if (percentage < 33) return theme.palette.custom.teal;
    if (percentage < 66) return theme.palette.custom.blue;
    return theme.palette.custom.mint;
  };

  const maxTooltip = `Crobo max offers the best exchange rates. The maximum transfer limit for Crobo max is $${transactionLimit?.Max_daily || 0} per transaction.`;
  const instantTooltip = `Crobo instant delivers money within seconds. The maximum transfer limit for Crobo instant is $${transactionLimit?.Max_weekly || 0} per transaction.`;

  return (
    <Box sx={{ py: 1 }}>
      {!transactionLimit ? (
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box sx={{ flex: 1 }}>
              <TransactionLimitSkeleton />
            </Box>

            <Box sx={{ flex: 1 }}>
              <TransactionLimitSkeleton />
            </Box>

            {showButton && (
              <Skeleton
                variant="rectangular"
                width={100}
                height={50}
                sx={{ borderRadius: 1 }}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box sx={{ flex: 1, pr: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  mb: 0.5,
                }}
              >
                <Tooltip title={maxTooltip} arrow placement="top">
                  <Typography variant="body2">Daily limit</Typography>
                </Tooltip>
                {(() => {
                  const dailyPercentage =
                    (transactionLimit.Daily / transactionLimit.Max_daily) * 100;
                  const remainingPercentage = 100 - dailyPercentage;
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <LinearProgress
                        variant="determinate"
                        value={100}
                        sx={{
                          height: 8,
                          borderRadius: 4,
                          bgcolor: "transparent",
                          border: '1px solid rgba(0,0,0,0.4)',
                          "& .MuiLinearProgress-bar": {
                            bgcolor: "transparent",
                            borderRadius: 4,
                          },
                        }}
                      />
                      <LinearProgress
                        variant="determinate"
                        value={100-remainingPercentage}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: 8,
                          borderRadius: 4,
                          bgcolor: "white",
                          border: '1px solid rgba(0,0,0,0.4)',
                          "& .MuiLinearProgress-bar": {
                            bgcolor: theme.palette.custom.teal,
                            borderRadius: 4,
                          },
                        }}
                      />
                    </Box>
                  );
                })()}
                <Typography variant="body2">
                  ${transactionLimit.Daily}/${transactionLimit.Max_daily}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ flex: 1, pr: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  mb: 0.5,
                }}
              >
                <Tooltip title={instantTooltip} arrow placement="top">
                  <Typography variant="body2">Weekly limit</Typography>
                </Tooltip>
                {(() => {
                  const weeklyPercentage =
                    (transactionLimit.Weekly / transactionLimit.Max_weekly) * 100;
                  const remainingPercentage = 100 - weeklyPercentage;
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <LinearProgress
                        variant="determinate"
                        value={100}
                        sx={{
                          height: 8,
                          borderRadius: 4,
                          bgcolor: "transparent",
                          border: '1px solid rgba(0,0,0,0.4)',
                          "& .MuiLinearProgress-bar": {
                            bgcolor: "transparent",
                            borderRadius: 4,
                          },
                        }}
                      />
                      <LinearProgress
                        variant="determinate"
                        value={100-remainingPercentage}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: 8,
                          borderRadius: 4,
                          bgcolor: "transparent",
                          border: '1px solid rgba(0,0,0,0.4)',
                          "& .MuiLinearProgress-bar": {
                            bgcolor: theme.palette.custom.teal,
                            borderRadius: 4,
                          },
                        }}
                      />
                    </Box>
                  );
                })()}
                <Typography variant="body2">
                  ${transactionLimit.Weekly}/${transactionLimit.Max_weekly}
                </Typography>
              </Box>
            </Box>
            {showButton && (
              <Button
                elevation={0}
                variant="contained"
                sx={{
                  textAlign: "center",
                  bgcolor: (theme) => theme.palette.custom.blue,
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  width: "80px",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/profile")}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: "initial" }}
                >
                  Increase limits
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const TransactionLimitSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <Skeleton width="80px" height={18} />
      <Skeleton width="120px" height={12} />
      <Skeleton width="80px" height={18} />
    </Box>
  );
};
