import React from 'react'
import { Box, Typography, Skeleton } from '@mui/material';

const UserProfile = ({userName, phone, message = "Welcome back"}) => {
  return (
    <Box
      sx={{
        my: 2,
        position: "relative",
      }}
    >
      {userName ? (
        <>
          {message && (
            <Typography
              variant="body1"
              sx={{
                color: (theme) => theme.palette.custom.teal,
                fontWeight: 500,
              }}
            >
              {message}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  background: (theme) => theme.palette.custom.oxfordBlue,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {userName}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="body1"
            sx={{
              background: (theme) => theme.palette.custom.oxfordBlue,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {phone}
          </Typography>
        </>
      ) : (
        <>
          {message && <Skeleton width={100} height={24} sx={{ mb: 1 }} />}
          <Skeleton width={200} height={35} />
          {phone && <Skeleton width={100} height={24} sx={{ mb: 1 }} />}
        </>
      )}
    </Box>
  );
}

export default UserProfile;