import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../helper/helper";
import { backend } from "./userReducer";

export const apiRoutes = {
  campaigns: `${backend}/campaigns/list`,
};

// Async thunk for fetching campaigns
export const fetchCampaigns = createAsyncThunk(
  "campaigns/fetchCampaigns",
  async (_, { rejectWithValue, getState }) => {
    try {
      const token = getState().userAuth.token;
      const profile = localStorage.getItem("profile");
      const userId = profile ? JSON.parse(profile).user_id : undefined;

      const response = await axios.get(apiRoutes.campaigns, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          userId
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: {
    campaigns: [],
    loading: false,
    error: null,
    status: "idle",
  },
  reducers: {
    clearCampaigns: (state) => {
      state.campaigns = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.campaigns = action.payload.data;
        state.error = null;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCampaigns } = campaignsSlice.actions;
export default campaignsSlice.reducer; 